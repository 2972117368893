import React, { useState } from "react";
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material";
import firebaseApp from "../../auth/config"; // Import your Firebase configuration
import Swal from "sweetalert2";

const ResetPasswordModal = ({ show, setShow }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState(null);

    const handleResetPassword = async (e) => {
        e.preventDefault();

        try {
            const auth = getAuth(firebaseApp); // Get the Firebase Authentication instance
            const user = auth.currentUser;

            const credentials = EmailAuthProvider.credential(
                user.email,
                currentPassword
            );

            // Reauthenticate the user
            await reauthenticateWithCredential(user, credentials);

            // Validate the new password
            if (newPassword !== confirmNewPassword) {
                setError('Passwords do not match');
                return;
            }

            // Update the password
            await updatePassword(user, newPassword);

            // Password updated successfully
            Swal.fire({
                title: 'Success',
                text: 'Password Updated',
                icon: 'success',
            });
            setShow(false)
        } catch (err) {
            setError(err.message);
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
            });
        }
    };

    return (
        <Dialog
            open={show}
            onClose={() => { setShow(false) }}
            PaperProps={{
                style: {
                    width: "50vw",
                    height: "50vh",
                    overflowY: "hidden"
                },
            }}
        >
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <form onSubmit={handleResetPassword}>                    
                    <TextField
                        autoFocus
                        margin="dense"
                        type="password"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        fullWidth
                    />
                    {error && <p>{error}</p>}
                    <DialogActions>
                        <Button onClick={() => { setShow(false) }}>Cancel</Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
};

export default ResetPasswordModal;
