import React, { useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    FormControl,
    Select,
    Grid,
    List,
    ListItem,
    ListItemText,
    InputLabel,
    MenuItem
} from '@mui/material';

import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { ListItemIcon } from '@mui/material';
import Loader from '../loader/loader';
import { useAppContext } from '../../libs/contextLib';
import axios from 'axios';
import settings from '../../appsettings'

const getStripePlan = async (apiUrl, coachId) => {
    let resp = await axios.get(`${apiUrl}/api/getStripePlan/${coachId}`)
    return resp
}


const PlanSettings = ({ title }) => {
    const { apiUrl, coachId } = useAppContext()
    const [loading, setLoading] = useState(true)
    const [trigger, setTrigger] = useState(true)
    const [plan, setPlan] = useState({ clients: '50', price: 95 });

    React.useEffect(() => {
        async function getData() {
            let resp = await getStripePlan(apiUrl, coachId)
            console.log(resp.data);
            setPlan(resp.data);
            setLoading(false)
        }
        if (apiUrl !== null && coachId > 0) {
            getData();
        }
    }, [apiUrl, coachId, trigger]);


    const handlePlanChange = async (event) => {
        const selectedPlan = event.target.value;
        // post this priceId to set the new plan...
        setLoading(true)

        try {
            let resp = await axios.post(`${apiUrl}/api/ChangeSubscription/`, {
                PriceId: selectedPlan,
                CoachId: coachId
            });
            if (resp.status === 200) {
                console.log(resp.data)
                setTrigger(!trigger)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }

    };

    if (loading) {
        return (<Loader />)
    }

    return (
        <>
            <Typography variant="h3">{title} - {plan.status}</Typography>

            <Card style={{ marginTop: '20px', padding: '20px' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6">You are on the {plan.nickname} plan</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        {plan.clientCount <= plan.maxClients
                                            ?
                                            <CheckCircleOutlineIcon color="primary" />
                                            :
                                            <CancelIcon color="error" />
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={`You have ${plan.clientCount} active clients out of ${plan.maxClients}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={`Billed $${plan.price}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={`Next Payment on ${new Date(plan.periodEnd + "Z").toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card style={{ marginTop: '20px', padding: '20px' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6">You are on the {plan.nickname} plan</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Stridist Coaching Portal" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Stridist Marketing Suite" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Stridist Business Incubator" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={`${plan.maxClients} clients`} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">Change your plan to</Typography>
                                    <Typography variant="subtitle1">
                                        Change the plan to the one that suits you best
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        If you decide this plan isn't right for you, it's easy to cancel.
                                    </Typography>
                                    <FormControl fullWidth style={{ margin: '8px 0' }}>
                                        <InputLabel style={{ background: 'white', padding: '3px' }} id="plan-label">Choose Your Plan</InputLabel>
                                        <Select
                                            labelId="plan-label"
                                            value={plan.clients}
                                            onChange={handlePlanChange}
                                        >
                                            {plan.clientCount < 51 ?
                                                <MenuItem value={settings.stripeCheckoutLinks.upto50}>Up to 50 clients ($95)</MenuItem> : null}

                                            {plan.clientCount < 101 ?
                                                <MenuItem value={settings.stripeCheckoutLinks.upto100}>Up to 100 Clients ($180)</MenuItem> : null}
                                            {/* 
                                            {plan.clientCount < 101 ?
                                                <MenuItem value="200">Up to 200 clients ($250)</MenuItem> : null} */}

                                            <MenuItem value={settings.stripeCheckoutLinks.uptoUnlim}>Unlimited clients ($310)</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <Typography variant="h6">${plan.price}/month</Typography>
                                    <br />
                                    <Box display="flex" alignItems="center">
                                        <Button variant="contained" color="primary" style={{ marginRight: '8px' }}>
                                            Continue
                                        </Button>
                                        <Button variant="outlined" style={{ marginLeft: '8px' }}>
                                            Change Card Details
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default PlanSettings