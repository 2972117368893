import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  TextField,
  Box,
  Select,
  MenuItem,
  Menu,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import { Add, CalendarMonth, MoreHoriz } from "@mui/icons-material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";
import theme from "../../components/theme/theme";
import { formatDistanceToNow } from "date-fns";
import ProgramCardMenu from "../../components/programs/ProgramCardMenu";
import ProgramStatusChip from "../../components/programs/ProgramStatusChip";
import AddProgramButton from "../../components/programs/AddProgramButton";

const ProgramPage = () => {
  const [view, setView] = useState(localStorage.getItem("view") || "grid");
  const [sort, setSort] = useState("asc");
  const [tab, setTab] = useState(
    localStorage.getItem("program.tab") || "PUBLISHED"
  );
  const navigate = useNavigate();

  const [programs, setPrograms] = React.useState([]);
  const { dbUser, coachId, apiUrl } = useAppContext();
  const [filter, setFilter] = React.useState("");
  const [reloadTrigger, setReloadTrigger] = React.useState(false);

  React.useEffect(() => {
    async function getData() {
      let resp = await axios.get(`${apiUrl}/api/pages/programs`);
      console.log(resp.data);
      setPrograms(resp.data.programs);
    }
    if (apiUrl !== null) {
      getData();
    }
  }, [apiUrl, reloadTrigger]);

  const handleEditClick = (programId) => {
    navigate(`/program/${programId}`);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      localStorage.setItem("view", newView);
      setView(newView);
    }
  };
  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      localStorage.setItem("program.tab", newTab);
      setTab(newTab);
    }
  };

  const handleReloadTrigger = () => {
    setReloadTrigger(!reloadTrigger);
  };

  const filteredPrograms = programs.filter((p) => {
    if (tab === "ALL") return true;
    return p.status === tab;
  });

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
        <AddProgramButton />

        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="ALL" style={{ flex: 1 }}>
              All
            </ToggleButton>
            <ToggleButton value="PUBLISHED" style={{ flex: 1 }}>
              Published
            </ToggleButton>
            <ToggleButton value="DRAFT" style={{ flex: 1 }}>
              Drafts
            </ToggleButton>
            <ToggleButton value="ARCHIVED" style={{ flex: 1 }}>
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      <div style={{ padding: "20px" }}>
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <TextField
                label="Search programs"
                variant="outlined"
                style={{ background: "white" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography>Sort By:</Typography>
              <Select
                value={sort}
                onChange={handleSortChange}
                variant="outlined"
                style={{ background: "white" }}
              >
                <MenuItem value={"asc"}>Ascending</MenuItem>
                <MenuItem value={"desc"}>Descending</MenuItem>
              </Select>
              <ToggleButtonGroup
                color="primary"
                value={view}
                exclusive
                onChange={handleViewChange}
              >
                <ToggleButton value="grid">
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list">
                  <ViewListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <Typography sx={{ margin: "10px 0" }}>
            Showing {filteredPrograms.length} Programs
          </Typography>
        </Box>
        {/* Grid or List of Program Cards */}
        <Grid container spacing={2}>
          {filteredPrograms
            .filter((item) =>
              item.name.toLowerCase().includes(filter.toLowerCase())
            )
            .map((p, index) => (
              <Grid
                key={p.id || index}
                item
                xs={12}
                sm={view === "grid" ? 6 : 12}
                md={view === "grid" ? 3 : 12}
                lg={view === "grid" ? 3 : 12}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: view === "grid" ? "column" : "row",
                    alignItems: view === "grid" ? "" : "center",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      onClick={() => {
                        handleEditClick(p.programId);
                      }}
                      component="img"
                      height="190"
                      image={p.coverImg}
                      alt="program image"
                    />

                    <ProgramCardMenu
                      programId={p.programId}
                      status={p.status}
                      apiUrl={apiUrl}
                      reloadTrigger={handleReloadTrigger}
                    />
                  </div>
                  <CardContent
                    onClick={() => {handleEditClick(p.programId)}}
                    sx={{ textAlign: "left" }}
                  >
                    <ProgramStatusChip status={p.status} />

                    <Typography
                      variant="h5"
                      component="div"
                      align="left"
                      paddingTop={1}
                      paddingBottom={1}
                    >
                      {p.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="left"
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <PeopleIcon />

                        {`${p.users.length}`}
                      </Typography>{" "}
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="left"
                      ></Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="left"
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <CalendarMonth />
                        {`${p.duration} week`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        align="left"
                      >
                        created{" "}
                        {formatDistanceToNow(new Date(p.createdAt + "Z"))} ago
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default ProgramPage;
