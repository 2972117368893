import React from 'react';
import { Dialog, Box, Avatar, Typography, Link } from '@mui/material';

export const NotificationsDialog = ({ open, onClose, notifications }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <Box sx={{padding: 2}}>
        <Typography variant="h5" sx={{ position: 'sticky', top: 0, padding: 2, backgroundColor: '#fff', zIndex: 1 }}>Notifications</Typography>
        <Box sx={{ maxHeight: '90%', overflow: 'auto'}}>
      {notifications.map(notification => (
        <Link href={notification.link} key={notification.id} underline="none">
            <Box sx={{borderBottom: '1px solid grey', paddingTop: 2, paddingBottom: 2, margin: 1}}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2}}>
            <Avatar src={notification.user.avatarUrl} />
            <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
              <Typography variant="h6">{notification.user.name}</Typography>
            </Box>
            <Typography variant="subtitle2" align="right">{notification.time}</Typography>
          </Box>
          <Typography variant="body1" color="text.primary">{notification.description}</Typography>
          </Box>
        </Link>
      ))}
      </Box>
      </Box>
    </Dialog>
  );
};