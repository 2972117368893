import React, { useState } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    Box,
    MenuItem,
    Menu,
    Avatar,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";


const removeFromGroup = async (apiUrl, groupId, userId) => {
    let resp = await axios.post(`${apiUrl}/api/RemoveFromGroup/`, { GroupId: groupId, UserId: userId })
    return resp
}

const GroupMembers = ({ clients, view, groupId, apiUrl, trigger }) => {

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event, id) => {
        setSelectedUserId(id);
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleUnenroll = (event, id) => {
        setSelectedUserId(id)

        Swal.fire({
            title: 'Remove From Group',
            text: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let resp = await removeFromGroup(apiUrl, groupId, id);
                if (resp.status === 200) {
                    Swal.fire('Removed!', 'The user has been removed.', 'success');
                    if (trigger) { trigger() }
                }
                else {
                    Swal.fire('Error!', 'Something went wrong.', 'error');
                }
            } else {
                // User clicked cancel or closed the dialog
            }
        });


        handleMenuClose();
    }

    return (
        <Grid container spacing={2}>
            {clients.map((c, index) => (
                <Grid
                    item
                    xs={12}
                    sm={view === "grid" ? 6 : 12}
                    md={view === "grid" ? 3 : 12}
                    lg={view === "grid" ? 3 : 12}
                >
                    <Card style={{ height: "100%" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: view === "grid" ? "column" : "row",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <Avatar
                                src={c.photoUrl}
                                alt="program image"
                                sx={{
                                    width: view === "grid" ? 120 : 60,
                                    height: view === "grid" ? 120 : 60,
                                    marginBottom: view === "grid" ? 0 : 0,
                                    marginRight: view === "grid" ? 0 : 5,
                                    marginLeft: view === "grid" ? 0 : 5,
                                    marginTop: view === "grid" ? 5 : 0,
                                }}
                            />
                            <IconButton
                                aria-label="settings"
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={(event) => handleMenuOpen(event, index)}
                            >
                                <MoreHoriz />
                            </IconButton>
                            <Menu
                                id="groupmember-card-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={(event) => handleUnenroll(event, c.userId)}>Remove From Group</MenuItem>
                            </Menu>
                            <CardContent
                                sx={{
                                    textAlign: view === "grid" ? "center" : "left",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: view === "grid" ? "center" : "flex-start",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    component="div"
                                    align={view === "grid" ? "center" : "left"}
                                >
                                    {c.displayName}
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export default GroupMembers