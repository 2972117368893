import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Add, MoreHoriz, CalendarMonth } from "@mui/icons-material";
import { useAppContext } from "../../libs/contextLib";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const createProgram = async (apiUrl, coachId, progName) => {
  let resp = await axios.post(`${apiUrl}/api/program/`, {
    Name: progName,
    AuthorId: coachId,
  });
  return resp;
};

const AddProgramTemplateButton = () => {
  const navigate = useNavigate();

  const { apiUrl, coachId } = useAppContext();
  const [openAddProgramDialog, setOpenAddProgramDialog] = React.useState(false);
  const [openTemplateDialog, setTemplateDialog] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [programName, setProgramName] = React.useState("");
  const [message, setMessage] = React.useState(
    "Hello!\n\nI’d like to invite you to be my client on Stridist. It’s a coaching platform that will help us stay organized, chat with each other, and track your progress.\n\nIt takes less than a minute to join – see you there!"
  );

  const handleCreateProgram = async () => {
    try {
      let resp = await createProgram(apiUrl, coachId, programName, coachId);
      console.log("response", resp);
      // close the modal
      setOpenAddProgramDialog(false);
      // take user to the program...
      navigate(`/program/${resp.data}`);
    } catch (error) {
      console.log("error", error);
      if (error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "white",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="div" align="left">
          Programs
        </Typography>
        <Box sx={{display: "flex", gap: 1}}>
        <IconButton
          sx={{
            background: "#EB5757",
            fontSize: "12px",
            color: "white",
            borderRadius: 2,
          }}
          onClick={() => {
            setOpenAddProgramDialog(true);
          }}
        >
          <Add />
          New Program
        </IconButton>
        <IconButton
          sx={{
            background: "#EB5757",
            fontSize: "12px",
            color: "white",
            borderRadius: 2,
          }}
          onClick={() => {
            setTemplateDialog(true);
          }}
        >
          <Add />
          New Program From Template
        </IconButton>
        </Box>
      </Box>

      {/* Dialog shows/hides based on trigger button above */}
      <Dialog
        open={openAddProgramDialog}
        onClose={() => {
          setOpenAddProgramDialog(false);
        }}
        PaperProps={{
          style: {
            width: "50vw",
            overflowY: "hidden",
          },
        }}
      >
        <DialogTitle>Create New Program</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Program Name"
            fullWidth
            value={programName}
            onChange={(event) => setProgramName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddProgramDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleCreateProgram}>Create</Button>
        </DialogActions>
      </Dialog>

       {/* Dialog shows/hides based on trigger button above */}
       <Dialog
        open={openTemplateDialog}
        onClose={() => {
          setTemplateDialog(false);
        }}
        maxWidth="lg"
        PaperProps={{
          style: {
            width: "90vw",
            overflowY: "hidden",
          },
        }}
      >
        <DialogTitle>Create New Program from Template</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
  {[
    { id: 1, name: "12 week fitness", duration: 12, coverImg: "https://source.unsplash.com/random/?fitness" },
    { id: 2, name: "6 month weight loss", duration: 24, coverImg: "https://source.unsplash.com/random/?weight,loss" },
    { id: 3, name: "Off season bulk", duration: 16, coverImg: "https://source.unsplash.com/random/?bulking, weight gain" },
    { id: 4, name: "12 week fitness", duration: 12, coverImg: "https://source.unsplash.com/random/?fitness" },
    { id: 5, name: "6 month weight loss", duration: 24, coverImg: "https://source.unsplash.com/random/?weight,loss" },
    { id: 6, name: "Off season bulk", duration: 16, coverImg: "https://source.unsplash.com/random/?bulking, weight gain" },
    { id: 7, name: "12 week fitness", duration: 12, coverImg: "https://source.unsplash.com/random/?fitness" },
    { id: 8, name: "6 month weight loss", duration: 24, coverImg: "https://source.unsplash.com/random/?weight,loss" },
    { id: 9, name: "Off season bulk", duration: 16, coverImg: "https://source.unsplash.com/random/?bulking, weight gain" },
    { id: 10, name: "12 week fitness", duration: 12, coverImg: "https://source.unsplash.com/random/?fitness" },
    { id: 11, name: "6 month weight loss", duration: 24, coverImg: "https://source.unsplash.com/random/?weight,loss" },
    { id: 12, name: "Off season bulk", duration: 16, coverImg: "https://source.unsplash.com/random/?bulking, weight gain" },
  ].map((p, index) => (
    <Grid
    key={p.id || index}
    item
    xs={12}
    sm={6}
    md={4} // Adjust the grid item size
    lg={4} // Adjust the grid item size
  >
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="190"
          image={p.coverImg}
          alt="program image"
        />
      </div>
      <CardContent
        sx={{ textAlign: "left", flex: '1 0 auto' }}
      >
        <Typography
          variant="h5"
          component="div"
          align="left"
          paddingTop={1}
          paddingBottom={1}
        >
          {p.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            align="left"
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CalendarMonth />
            {`${p.duration} week`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  </Grid>
  ))}
</Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTemplateDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProgramTemplateButton;
