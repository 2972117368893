import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Avatar, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Add } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import MicIcon from "@mui/icons-material/Mic";
import GifIcon from "@mui/icons-material/Gif";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";

const MessagesPage = () => {
  const { apiUrl } = useAppContext()
  const [allChats, setAllChats] = React.useState([]);
  const [selectedChatMessages, setSelectedChatMessages] = React.useState([]);

  React.useEffect(() => {
    async function getData() {
      let resp = await axios.get(`${apiUrl}/api/pages/messages`);
      console.log(resp.data);
      setAllChats(resp.data.chats);
    }
    if(apiUrl !== null){
      getData();
    }
  }, []);

  const selectChat = (chat, index) => {
    setSelectedChat(chat);
    setSelectedChatMessages([chat]);
    handleClick(index);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);

  const handleClick = (index) => {
    setSelectedConversation(index);
  };

  const handleBack = () => {
    setSelectedConversation(null);
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const [tab, setTab] = useState("all");

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
          position: "sticky",
          top: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "white",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="div" align="left">
            Messages
          </Typography>
          <IconButton
            sx={{
              background: "#EB5757",
              fontSize: "12px",
              color: "white",
              borderRadius: 2,
            }}
          >
            <Add />
            New Message
          </IconButton>
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          {/* <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="drafts">Drafts</ToggleButton>
            <ToggleButton value="archived">Archived</ToggleButton>
          </ToggleButtonGroup> */}
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100%",
        }}
      >
        {/* Left bar */}
        {(isMobile && selectedConversation === null) || !isMobile ? (
     <Box
     sx={{
       width: isMobile ? "100%" : "25%",
       borderRight: "1px solid #ddd",
       overflowY: "auto",
     }}
   >
            <TextField
              placeholder="Search"
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: 0,
              }}
            />
            <Box
              sx={{
            
                maxHeight: "calc(100vh - 100px)",
              }}
            >
              {allChats.map((chat, index) => (
                <Box
                  key={chat.messageId}
                  sx={{
                    display: "flex",
                    padding: "15px",
                    alignItems: "center",
                  }}
                  onClick={() => selectChat(chat, index)}
                >
                  <Avatar src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fHx8fA%3D%3D&auto=format&fit=crop&w=1061&q=80" />
                  <Box sx={{ marginLeft: "15px", flexGrow: 1 }}>
                    <h4 style={{ margin: 0, padding: 0 }}>User Name</h4>
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>
                      {chat.message}
                    </p>
                  </Box>
                  {/* <p>{moment(msg.timestamp).format("HH:mm")}</p> */}
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}

        {!isMobile || (isMobile && selectedConversation !== null) ? (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              maxHeight: "calc(100vh - 80px)",
            }}
          >
            {isMobile && (
              <IconButton onClick={handleBack} sx={{ alignSelf: "flex-end" }}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
            {selectedChat && (
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                mt={0}
                position="sticky"
                top={0}
                bgcolor="#fff"
                zIndex={1}
                p={2}
                borderBottom="2px solid rgb(234, 233, 233)"
              >
                <Avatar src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1061&q=80" />
                <Box ml={2}>
                  <Typography variant="h5">
                    {selectedChat.displayName}
                  </Typography>
                  <Typography variant="body2">
                    Last active: 10 mins ago
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                mb: 2,
                overflowY: "auto",
                height: "calc(100vh - 200px)", // adjust this value as needed
              }}
            >
              {selectedChatMessages.map((msg, index) => (
                <Box
                  key={msg.messageId}
                  sx={{
                    display: "flex",
                    flexDirection:
                      msg.type === "received" ? "row" : "row-reverse",
                    margin: "10px",
                  }}
                >
                  {msg.type === "received" && (
                    <Avatar src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fHx8fA%3D%3D&auto=format&fit=crop&w=1061&q=80" />
                  )}
                  <Box
                    sx={{
                      backgroundColor:
                        msg.type === "received" ? "#EDF1F3" : "#EB5757",
                      color: msg.type === "received" ? "#000" : "#fff",
                      borderRadius:
                        msg.type === "received"
                          ? "20px 20px 20px 0px"
                          : "20px 20px 0 20px",
                      padding: "10px",
                      margin: "5px",
                      maxWidth: "70%",
                      wordWrap: "break-word",
                    }}
                  >
                    {msg.message}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
    sx={{
      position: "sticky",
      bottom: 0,
      backgroundColor: "#fff",
      boxShadow: "0px -1px 5px rgba(0,0,0,0.1)",
      zIndex: 100,
      padding: 1,
      display: "flex",
      alignItems: "center",
    }}
  >
              <IconButton>
                <GifIcon />
              </IconButton>
              <IconButton>
                <AttachFileIcon />
              </IconButton>
              <IconButton>
                <EmojiEmotionsIcon />
              </IconButton>
              <TextField
                fullWidth
                placeholder="Type a message..."
                variant="outlined"
                style={{ flexGrow: 1, marginLeft: 15, marginRight: 15 }}
              />
              <IconButton>
                <MicIcon />
              </IconButton>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default MessagesPage;
