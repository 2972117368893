import React, { useState } from "react";
import firebaseConfig from "../../auth/config.js";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Logo from "../../images/logofull.svg";
import appleLogo from "../../images/appleLogo.svg";
import googleLogo from "../../images/googleLogo.svg";
import { useTheme } from "@mui/material/styles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginSubmit = (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    try {
      firebaseConfig
        .auth()
        .signInWithEmailAndPassword(email.value, password.value);
    } catch (error) {
      alert(error);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const inputWidth = "50%";
  const inputHeight = "50px";
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          backgroundColor: theme.palette.primary.background,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <img src={Logo} alt="Logo" style={{ marginBottom: "2rem" }} />
        <Button
          variant="outlined"
          style={{
            marginBottom: "1rem",
            width: inputWidth,
            height: inputHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            border: "0px solid white",
            backgroundColor: "white",
            fontWeight: "bold",
            textTransform: "none",
          }}
        >
          <img
            src={appleLogo}
            alt="Apple Logo"
            style={{ marginRight: "10px", width: "20px", height: "20px" }}
          />
          Login with Apple
        </Button>
        <Button
          variant="outlined"
          style={{
            marginBottom: "2rem",
            width: inputWidth,
            height: inputHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            border: "0px solid white",
            fontWeight: "bold",
            backgroundColor: "white",
            textTransform: "none",
          }}
        >
          <img
            src={googleLogo}
            alt="Google Logo"
            style={{ marginRight: "10px", width: "20px", height: "20px" }}
          />
          Login with Google
        </Button>
        <Typography variant="h6" style={{ marginBottom: "2rem" }}>
          OR
        </Typography>
        <form onSubmit={loginSubmit} style={{ width: inputWidth }}>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            fullWidth
            required
            style={{
              marginBottom: "1rem",
              height: inputHeight,
              background: "white",
            }}
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            fullWidth
            required
            style={{
              marginBottom: "1rem",
              height: inputHeight,
              background: "white",
            }}
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!email || !password}
            fullWidth
            style={{
              height: inputHeight,
              fontWeight: "bold",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            Login
          </Button>
        </form>
        <Typography
          variant="body2"
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          <Link to="/forgot">Forgot Password?</Link>
          <br />
        
         
      Don’t have an account? <Link to="/signup">Sign Up as Coach
        </Link> 
        </Typography>
      </div>
    </>
  );
};

export default Login;
