import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import firebaseConfig from "../../auth/config";
import { useAppContext } from "../../libs/contextLib";

const RegisterAccount = ({ nextStep }) => {
    const {dbUser} = useAppContext()

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        password2: "",
    })
    const [error, setError] = useState('')

    useEffect(()=>{
        if(dbUser.id && nextStep){
            nextStep()
        }
    }, [dbUser])

    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const registerUser = async (e) => {
        e.preventDefault();
        setError('');

        if (formData.password !== formData.password2) {
            setError('Passwords do not match');
            return;
        }

        try {
            const userCredential = await firebaseConfig.auth().createUserWithEmailAndPassword(formData.email, formData.password);
            // Registration was successful, you can access user information from userCredential.user
            console.log('User registered successfully!');

            // our firebase logic takes over next, logging the user in and rerendering onto step 2 by magic.

        } catch (error) {
            // Handle Firebase authentication errors here
            if (error.code === 'auth/invalid-email') {
                setError('Invalid email address format');
            }
            else {
                setError(error.message.replace('Firebase:', ''))
            }
        }
    }


    return (
        <>
            <Typography variant="h3">You</Typography>
            <br />
            <form
                onSubmit={registerUser}
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
                <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormChange}
                    style={{ width: "100%" }}
                />
                <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleFormChange}
                    style={{ width: "100%" }}
                />
                <TextField
                    label="Password Confirmation"
                    name="password2"
                    type="password"
                    value={formData.password2}
                    onChange={handleFormChange}
                    style={{ width: "100%" }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        background:
                            "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                    }}
                >
                    Next
                </Button>
                {error}
            </form>
        </>
    )
}

export default RegisterAccount