import React, { useEffect, useState } from "react"
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    CardHeader,
    TableContainer,
    Table,
    Paper, TableBody, TableRow, TableCell, TableHead
} from "@mui/material";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
    ResponsiveContainer,

} from "recharts";
import { useAppContext } from "../../libs/contextLib";
import axios from "axios";

const ExerciseHistory = ({ userId }) => {

    const { apiUrl } = useAppContext()
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [exercises, setExercises] = useState([])

    React.useEffect(() => {
        async function getData() {
            let resp = await axios.get(`${apiUrl}/api/ExerciseHistory/${userId}`);
            console.log(resp.data);
            setExercises(resp.data);
        }
        if (apiUrl !== null) {
            getData();
        }
    }, [apiUrl]);

    const handleExerciseSelect = (exercise) => {
        setSelectedExercise(exercise);
    };
    
    return (

        <Grid item xs={12}>
            <Card>
                <CardContent>
                    {selectedExercise ? (
                        <div>
                            <Button variant="contained" color="primary" onClick={() => setSelectedExercise(null)}>Back</Button>
                            <br />          <br />
                            <Typography variant="h3">{selectedExercise.name}</Typography>
                            <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
                                <Grid item>
                                    <Card>
                                        <CardHeader title={<Typography variant="subtitle2" color="textSecondary">Min Weight</Typography>} />
                                        <CardContent>
                                            <Typography variant="h3" color="primary">{Math.min(...selectedExercise.history.map(exercise => exercise.weight))}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={2}>
                                    <Card>
                                        <CardHeader title={<Typography variant="subtitle2" color="textSecondary">Max Weight</Typography>} />
                                        <CardContent>
                                            <Typography variant="h3" color="primary">{Math.max(...selectedExercise.history.map(exercise => exercise.weight))}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={2}>
                                    <Card>
                                        <CardHeader title={<Typography variant="subtitle2" color="textSecondary">Min Reps</Typography>} />
                                        <CardContent>
                                            <Typography variant="h3" color="primary">{Math.min(...selectedExercise.history.map(exercise => exercise.reps))}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={2}>
                                    <Card>
                                        <CardHeader title={<Typography variant="subtitle2" color="textSecondary">Max Reps</Typography>} />
                                        <CardContent>
                                            <Typography variant="h3" color="primary">{Math.max(...selectedExercise.history.map(exercise => exercise.reps))}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={2}>
                                    <Card>
                                        <CardHeader title={<Typography variant="subtitle2" color="textSecondary">1RPM</Typography>} />
                                        <CardContent>
                                            <Typography variant="h3" color="primary">{selectedExercise.OneRP}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h6">Weight History</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={selectedExercise.history}>
                                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="weight" stroke="#8884d8" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <Typography variant="h6">Exercise History:</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="right">Weight</TableCell>
                                            <TableCell align="right">Reps</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedExercise.history.map((exercise, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">{exercise.date}</TableCell>
                                                <TableCell align="right">{exercise.weight}</TableCell>
                                                <TableCell align="right">{exercise.reps}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h4">Exercise History</Typography>
                            <br />
                            <Grid container spacing={2}>
                                {exercises.map((exercise) => (
                                    <Grid item key={exercise.id}>
                                        <Button variant="contained" color="primary" onClick={() => handleExerciseSelect(exercise)}>
                                            {exercise.name}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                </CardContent>
            </Card>
        </Grid>

    )
}

export default ExerciseHistory