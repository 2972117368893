import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GifIcon from "@mui/icons-material/Gif";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import MicIcon from "@mui/icons-material/Mic";
import { useParams } from "react-router";
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";
import Loader from "../../components/loader/loader";
import GroupPost from "../../components/groups/GroupPost";
import GroupMembers from "../../components/groups/GroupMembers";
import AddMemberToGroup from "../../components/groups/AddMemberToGroup";
import GroupSettings from "../../components/groups/GroupSettings";


const getGroup = async (apiUrl, groupId) => {
  let resp = await axios.get(`${apiUrl}/api/pages/groups/${groupId}`)
  return resp
}

const GroupPage = () => {
  const { id } = useParams();
  const { apiUrl } = useAppContext()
  const [user, setUser] = useState({
    name: "User",
    avatar: "https://example.com/avatar.jpg",
  });
  const [tab, setTab] = useState(localStorage.getItem('group.tab') || 'posts');
  const [sort, setSort] = useState("desc");
  const [view, setView] = useState(localStorage.getItem('view') || 'grid');
  const [tabContents, setTabContents] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [trigger, setTrigger] = useState(false);

  const [newPostTitle, setNewPostTitle] = useState("");
  const [newPostContent, setNewPostContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);


  useEffect(() => {
    async function getData() {
      let resp = await getGroup(apiUrl, id)
      console.log(resp.data);
      setPageData(resp.data);
    }
    if (apiUrl !== null) {
      getData();
    }
  }, [apiUrl, trigger]);

  useEffect(() => {
    function renderTab() {
      if (tab === "posts") {
        setTabContents(
          <GroupPost
            posts={pageData.posts}
            apiUrl={apiUrl}
            trigger={handleTrigger}
          />
        );
      } else if (tab === "members") {
        setTabContents(
          <GroupMembers
            clients={pageData.members}
            apiUrl={apiUrl}
            groupId={id}
            trigger={handleTrigger}
          />
        )

      } else if (tab === "settings") {
        setTabContents(
          <GroupSettings
            apiUrl={apiUrl}
            groupId={id}
          />
        )
      }

    }
    if (pageData !== null) renderTab()
  }, [tab, pageData]);

  const handleTrigger = () => {
    setTrigger(!trigger)
  }

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      localStorage.setItem('group.tab', newTab);
      setTab(newTab);
    }
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleViewChange = (event, newView) => {
    localStorage.setItem('view', newView);
    setView(newView);
  };

  const groupName = "Awesome Group";
  const memberCount = 123;

  const handleAddPost = () => {
    // setPosts((prevPosts) => [
    //   ...prevPosts,
    //   {
    //     title: newPostTitle,
    //     content: newPostContent,
    //     user,
    //     timestamp: new Date(),
    //     replies: [],
    //   },
    // ]);
    // setNewPostTitle("");
    // setNewPostContent("");
    // setOpenDialog(false);
  };



  if (pageData === null) {
    return <Loader />
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4" component="div" align="left">
              {pageData.title}
            </Typography>
            <Typography variant="subtitle1">{pageData.members.length} members</Typography>
          </Box>


          <AddMemberToGroup groupId={id} parentTrigger={handleTrigger} />


          <IconButton
            sx={{
              background: "#EB5757",
              fontSize: "12px",
              color: "white",
              borderRadius: 2,
            }}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <AddIcon />
            Add a Post
          </IconButton>
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="posts" style={{ flex: 1 }}>
              Posts
            </ToggleButton>
            <ToggleButton value="members" style={{ flex: 1 }}>
              Members
            </ToggleButton>
            <ToggleButton value="settings" style={{ flex: 1 }}>
              Settings
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      <div style={{ padding: "20px" }}>

        {tabContents}


        {/* New Post Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>New Post</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              value={newPostTitle}
              onChange={(e) => setNewPostTitle(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Content"
              type="text"
              multiline
              rows={4}
              fullWidth
              value={newPostContent}
              onChange={(e) => setNewPostContent(e.target.value)}
            />
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton>
                  <EmojiEmotionsIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton>
                  <GifIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton>
                  <MicIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleAddPost} color="primary">
              Post
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default GroupPage;
