import React, { useState, useMemo } from "react";
import ClientNotes from "./clientNotes";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Avatar,
  Button,
  Chip,
  IconButton,
  CardHeader,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
 List,
 ListItem,
TableContainer,
Table,
Paper, TableBody, TableRow, TableCell, TableHead
} from "@mui/material";
import { DndProvider } from 'react-dnd'
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { HTML5Backend } from 'react-dnd-html5-backend'

import {
  Add,
  BarChart as BarChartIcon,
} from "@mui/icons-material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,

} from "recharts";


import StridistCalendar from "../../components/calendar/calendar";
import { useParams } from "react-router-dom";
import settings from "../../appsettings"
import axios from "axios"
import Loader from "../../components/loader/loader";
import { useAppContext } from "../../libs/contextLib";
import { formatDistanceToNow } from "date-fns";
import ExerciseHistory from "../../components/clients/ExerciseHistory";

const generateHistory = (currentValue, months = 6) => {
  const history = [];
  for (let i = months; i > 0; i--) {
    const month = new Date().getMonth() - i + 2;
    const year = new Date().getFullYear();
    const variance = Math.floor(Math.random() * 5 - 2);
    history.push({
      date: `${month}-${year}`,
      value: currentValue + variance,
    });
  }
  return history;
};
const calculateDaysLeft = (endDate) => {
  const today = new Date();
  const end = new Date(endDate);
  const differenceInTime = end.getTime() - today.getTime();
  return Math.ceil(differenceInTime / (1000 * 3600 * 24));
};


/* Static Data */
const initialMeasurements = {
  chest: 90,
  waist: 70,
  upperArm: 30,
  hips: 90,
  thighs: 50,
  calfs: 30,
  shoulder: 120,
  bf: 15,
};



const measurementHistory = Object.keys(initialMeasurements).reduce(
  (acc, key) => {
    acc[key] = generateHistory(initialMeasurements[key]);
    return acc;
  },
  {}
);

const events = [
  {
    id: Date.now(),
    type: 'Workout',
    content: 'Run 5km, Do 50 push-ups',
    date: '2023-09-03', 
  },
  {
    id: Date.now(),
    type: 'Habit',
    content: 'Drink Water',
    date: '2023-09-03', 
  },
  {
    id: Date.now(),
    type: 'Message',
    content: 'Hello, Dave',
    date: '2023-09-03', 
  },
];

const measurementsLabels = {
  bf: "BF (%)",
  shoulder: "Shoulder",
  chest: "Chest",
  waist: "Waist",
  hips: "Hips",
  thighs: "Thighs",
  upperArm: "Upper Arm",
  calfs: "Calfs",
};
/* END Static */

const ClientPage = () => {
  const { apiUrl } = useAppContext()
  const { id } = useParams();
  const [loading, setLoading] = useState(true)
  const [notes, setNotes] = useState([]);
  const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);  
  const [currentNote, setCurrentNote] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]);

  const [tab, setTab] = useState("overview");

  const [userData, setUserData] = useState("");
  
  const [chartData, setChartData] = useState(null);  
  const [isAddGoalOpen, setIsAddGoalOpen] = useState(false);
  const [newGoal, setNewGoal] = useState("");
  const [openedChartKey, setOpenedChartKey] = useState(null);
  const [expanded, setExpanded] = useState({
    profile: false,
    steps: false,
    nutrition: false,
    goals: false,
    weight: false,
    measurements: false,
    photos: false,
  });

  React.useEffect(() => {
    async function getData() {
      let resp = await axios.get(`${apiUrl}/api/pages/clients/${id}`);
      console.log(resp.data);
      
      setUserData({
        userId: resp.data.userId,
        name: resp.data.displayName || "N/A",
        location: resp.data.location || "N/A",
        email: resp.data.email || "N/A",
        age: resp.data.age || 0,
        weight: resp.data.weightKg || 0,
        height: resp.data.heightCm || 0,
        photoUrl: resp.data.photoUrl || null,
        steps: resp.data.steps ? [
          resp.data.steps.today,
          resp.data.steps.yesterday,
          resp.data.steps.prior1,
          resp.data.steps.prior2,
          resp.data.steps.prior3,
          resp.data.steps.prior4,
          resp.data.steps.prior5,
        ].reverse().map((step, index) => ({
          name: step.day,
          steps: step.steps,
        })) : [],

        
    weights: [
      { date: "Jan", weight: 150 },
      { date: "Feb", weight: 130 },
      { date: "Mar", weight: 135 },
      { date: "Apr", weight: 129 },
      { date: "May", weight: 110 },
      { date: "Jun", weight: 108 },
      { date: "Jul", weight: 107 },
    ],
    nutritionData: [
      { name: "Protein", value: 100 },
      { name: "Carbs", value: 200 },
      { name: "Fat", value: 70 },
      { name: "Kcal", value: 1500 },
    ],

    calorieTarget: 2000,
    calorieIntake: 1500,

    goals: [
      {
        title: "Lose weight",
        duration: 30,
        endDate: "2023-09-30",
        completion: 0,
      },
      {
        title: "Build muscle",
        duration: 60,
        endDate: "2023-11-01",
        completion: 0,
      },
    ],

    measurements: {
      chest: 90,
      waist: 70,
      upperArm: 30,
      hips: 90,
      thighs: 50,
      calfs: 30,
      shoulder: 120,
      bf: 15,
    },
    measurementHistory: measurementHistory,
    progressPhotos: resp.data.progressPhotos,
      });
  
      
      // finally
      setLoading(false);
    }
    if(apiUrl !== null){
      getData();
    }
  }, [apiUrl]);
  



  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
    setTab(newTab);
    }
  };

  const handleChartOpen = (key) => {
    if (openedChartKey === key) {
      setOpenedChartKey(null);
    } else {
      setOpenedChartKey(key);
    }
  };

  const updateCompletion = (index, increment) => {
    const newGoals = [...userData.goals];
    newGoals[index].completion = Math.min(
      newGoals[index].completion + increment,
      100
    );
    setUserData({ ...userData, goals: newGoals });
  };  

  const handleExpandClick = (cardKey) => {
    setExpanded({ ...expanded, [cardKey]: !expanded[cardKey] });
  };

  if (loading) {
    return <Loader/>;
  }

 

  const exercises = userData.exercises;

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="div" align="left">
            Clients
          </Typography>
          <IconButton
            sx={{
              background: "#EB5757",
              fontSize: "12px",
              color: "white",
              borderRadius: 2,
            }}
          >
            <Add />
           Message
          </IconButton>
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="overview" style={{ flex: 1 }}>
              Overview
            </ToggleButton>
            <ToggleButton value="calendar" style={{ flex: 1 }}>
              Calendar
            </ToggleButton>
            <ToggleButton value="nutrition" style={{ flex: 1 }}>
              Nutrition
            </ToggleButton>
            <ToggleButton value="responses" style={{ flex: 1 }}>
              Responses
            </ToggleButton>
            <ToggleButton value="notes" style={{ flex: 1 }}>
              Notes
            </ToggleButton>
         
          </ToggleButtonGroup>
        </Box>
      </div>
      <Box paddingTop={2} paddingBottom={2} paddingRight={10} paddingLeft={10}>
      {tab === "overview" && (
         <Grid container spacing={3}>
         <Grid item xs={12}>
           <Card>
             <CardContent>
               <Grid container spacing={3} alignItems="center">
                 <Grid item xs={12} md={4} alignItems="center">
                   <Grid container spacing={2} alignItems="center">
                     <Grid item xs="auto">
                       <Avatar
                         src={userData.photoUrl}
                         style={{
                           width: "100px",
                           height: "100px",
                         }}
                       />
                     </Grid>
                     <Grid item xs>
                       <Typography
                         variant="h6"
                         gutterBottom
                         sx={{ fontWeight: "bold" }}
                       >
                         {userData.name}
                       </Typography>
                       <Typography variant="body1">
                         {userData.location}
                       </Typography>
                       <Typography variant="body1">
                         {userData.email}
                       </Typography>
                     </Grid>
                   </Grid>
                 </Grid>
                 <Grid
                   item
                   container
                   xs={12}
                   md={8}
                   justify="flex-end"
                   spacing={3}
                 >
                   <Grid item xs={4}>
                     <Typography variant="h4">{userData.age === 0 ? `N/A` : userData.age}</Typography>
                     <Typography variant="body2">age</Typography>
                   </Grid>
                   <Grid item xs={4}>
                     <Typography variant="h4">{userData.weight}</Typography>
                     <Typography variant="body2">kg weight</Typography>
                   </Grid>
                   <Grid item xs={4}>
                     <Typography variant="h4">{userData.height}</Typography>
                     <Typography variant="body2">cm height</Typography>
                   </Grid>
                 </Grid>
               </Grid>
             </CardContent>
           </Card>
         </Grid>

         <Grid item xs={12}>
           <Card>
             <CardHeader
               sx={{ marginBottom: 0, paddingBottom: 0, color: "grey" }}
               title={<Typography variant="subtitle1">Today</Typography>}
             />

             <CardHeader
               sx={{ marginTop: 0, paddingTop: 0 }}
               title={
                 <>
                   <Typography
                     variant="h3"
                     color="primary"
                     component="span"
                     sx={{ paddingRight: 1 }}
                   >
                     {userData.steps[userData.steps.length - 1].steps}
                   </Typography>

                   <Typography variant="h5" component="span">
                     Steps
                   </Typography>
                 </>
               }
               action={
                 <Button onClick={() => handleExpandClick("steps")}>
                   {expanded.steps ? "Hide" : "Show"}
                 </Button>
               }
             />
             <Collapse in={expanded.steps} timeout="auto" unmountOnExit>
               <CardContent>
                 <ResponsiveContainer width="100%" height={300}>
                   <BarChart width={500} height={300} data={userData.steps}>
                     <CartesianGrid stroke="#f5f5f5" vertical={false} />
                     <XAxis dataKey="name" />
                     <YAxis />
                     <Tooltip />
                     <Legend />
                     <Bar
                       dataKey="steps"
                       fill="#EB5757"
                       radius={[10, 10, 0, 0]}
                     />
                   </BarChart>
                 </ResponsiveContainer>
               </CardContent>
             </Collapse>
           </Card>
         </Grid>
         <Grid item xs={12}>
           <Card>
             <CardHeader
               sx={{ marginBottom: 0, paddingBottom: 0, color: "grey" }}
               title={<Typography variant="subtitle1">Today</Typography>}
             />

             <CardHeader
               sx={{ marginTop: 0, paddingTop: 0 }}
               title={
                 <>
                   <Typography
                     variant="h3"
                     color="primary"
                     component="span"
                     sx={{ paddingRight: 1 }}
                   >
                     40%
                   </Typography>

                   <Typography variant="h5" component="span">
                     nutrition
                   </Typography>
                 </>
               }
               action={
                 <Button onClick={() => handleExpandClick("nutrition")}>
                   {expanded.nutrition ? "Hide" : "Show"}
                 </Button>
               }
             />
             <Collapse in={expanded.nutrition} timeout="auto" unmountOnExit>
               <CardContent></CardContent>
             </Collapse>
           </Card>
         </Grid>
         <Grid item xs={12}>
           <Card>
             <CardHeader
               sx={{ marginBottom: 0, paddingBottom: 0, color: "grey" }}
               title={<Typography variant="subtitle1">Today</Typography>}
             />

             <CardHeader
               sx={{ marginTop: 0, paddingTop: 0 }}
               title={
                 <>
                   <Typography
                     variant="h3"
                     color="primary"
                     component="span"
                     sx={{ paddingRight: 1 }}
                   >
                     89%
                   </Typography>

                   <Typography variant="h5" component="span">
                     Goals
                   </Typography>
                 </>
               }
               action={
                 <Button onClick={() => handleExpandClick("goals")}>
                   {expanded.goals ? "Hide" : "Show"}
                 </Button>
               }
             />
             <Collapse in={expanded.goals} timeout="auto" unmountOnExit>
               <CardContent>
                 {userData.goals.map((goal, index) => (
                   <Box key={index} mb={2}>
                     <Box
                       display="flex"
                       alignItems="center"
                       justifyContent="space-between"
                     >
                       <Typography variant="h6">{goal.title}</Typography>
                       <Button
                         onClick={() => updateCompletion(index, 10)}
                         size="small"
                         style={{ marginLeft: "8px" }} // Add some spacing between the title and the button
                       >
                         add
                       </Button>
                     </Box>
                     <Typography variant="body2">
                       {calculateDaysLeft(goal.endDate)} days left
                     </Typography>
                     <LinearProgress
                       variant="determinate"
                       value={goal.completion}
                     />
                   </Box>
                 ))}
                 <Button
                   variant="contained"
                   color="primary"
                   style={{ borderRadius: "15px", marginTop: "10px" }}
                 >
                   Add Goal
                 </Button>
               </CardContent>
             </Collapse>
           </Card>
         </Grid>

         <Grid item xs={12}>
           <Card>
             <CardHeader
               sx={{ marginBottom: 0, paddingBottom: 0, color: "grey" }}
               title={
                 <Typography variant="subtitle1">
                   Last Recorded: 4 days ago
                 </Typography>
               }
             />

             <CardHeader
               sx={{ marginTop: 0, paddingTop: 0 }}
               title={
                 <>
                   <Typography
                     variant="h3"
                     color="primary"
                     component="span"
                     sx={{ paddingRight: 1 }}
                   >
                     78kgs
                   </Typography>

                   <Typography variant="h5" component="span">
                     Weight
                   </Typography>
                 </>
               }
               action={
                 <Button onClick={() => handleExpandClick("weight")}>
                   {expanded.weight ? "Hide" : "Show"}
                 </Button>
               }
             />
             <Collapse in={expanded.weight} timeout="auto" unmountOnExit>
               <CardContent>
                 <ResponsiveContainer width="100%" height={300}>
                   <LineChart
                     data={userData.weights}
                     margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                   >
                     <XAxis dataKey="date" />
                     <YAxis />
                     <CartesianGrid stroke="#f5f5f5" vertical={false} />
                     <Tooltip />

                     <Line
                       type="monotone"
                       dataKey="weight"
                       stroke="#EB5757"
                       strokeWidth={2}
                       yAxisId={0}
                       dot={{ fill: "#EB5757", r: 4 }}
                     />
                   </LineChart>
                 </ResponsiveContainer>
               </CardContent>
             </Collapse>
           </Card>
         </Grid>
         <Grid item xs={12}>
           <Card>
             <CardHeader
               sx={{ marginBottom: 0, paddingBottom: 0, color: "grey" }}
               title={
                 <Typography variant="subtitle1">
                   Last Recorded: 4th July 2023
                 </Typography>
               }
             />

             <CardHeader
               sx={{ marginTop: 0, paddingTop: 0 }}
               title={
                 <>
                   <Typography
                     variant="h3"
                     color="primary"
                     component="span"
                     sx={{ paddingRight: 1 }}
                   >
                     8
                   </Typography>

                   <Typography variant="h5" component="span">
                     Measurements
                   </Typography>
                 </>
               }
               action={
                 <Button onClick={() => handleExpandClick("measurements")}>
                   {expanded.measurements ? "Hide" : "Show"}
                 </Button>
               }
             />
             <Collapse in={expanded.measurements} timeout="auto" unmountOnExit>
               <CardContent>
                 <Box display="flex" justifyContent="flex-end" mb={2}>
                   <Button
                     variant="contained"
                     color="primary"
                     style={{ borderRadius: "15px" }}
                   >
                     Add New Check In
                   </Button>
                 </Box>

                 {Object.entries(userData.measurements).map(([key, value]) => (
                   <Box key={key} mb={3}>
                     <Box display="flex" alignItems="flex-start" mb={2}>
                       <Box flexGrow={1}>
                         <Typography variant="subtitle2" color="textSecondary">
                           {measurementsLabels[key]}
                         </Typography>
                         <Typography variant="h5">{value} cm</Typography>
                       </Box>

                       <IconButton onClick={() => handleChartOpen(key)}>
                         <BarChartIcon />
                       </IconButton>
                     </Box>

                     {openedChartKey === key && (
                       <ResponsiveContainer width="100%" height={300}>
                         <LineChart
                           data={userData.measurementHistory[key]}
                           margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                         >
                           <CartesianGrid stroke="#f5f5f5" vertical={false} />
                           <XAxis dataKey="date" />
                           <YAxis />
                           <Tooltip />
                           <Line
                             type="monotone"
                             dataKey="value"
                             stroke="#EB5757"
                             activeDot={{ r: 8 }}
                           />
                         </LineChart>
                       </ResponsiveContainer>
                     )}
                   </Box>
                 ))}
               </CardContent>
             </Collapse>
           </Card>
         </Grid>
         <Grid item xs={12}>
           <Card>
             <CardHeader
               sx={{ marginBottom: 0, paddingBottom: 0, color: "grey" }}
               title={
                 <Typography variant="subtitle1">
                   Last Added: {userData.progressPhotos.length === 0 ? "No photos added yet" : formatDistanceToNow(new Date(userData.progressPhotos[0].takenOn+"Z")) + " ago" }
                 </Typography>
               }
             />

             <CardHeader
               sx={{ marginTop: 0, paddingTop: 0 }}
               title={
                 <>
                   <Typography
                     variant="h3"
                     color="primary"
                     component="span"
                     sx={{ paddingRight: 1 }}
                   >
                     {userData.progressPhotos.length}
                   </Typography>

                   <Typography variant="h5" component="span">
                     Progress Photos
                   </Typography>
                 </>
               }
               action={
                 <Button onClick={() => handleExpandClick("progress")}>
                   {expanded.progress ? "Hide" : "Show"}
                 </Button>
               }
             />
             <Collapse in={expanded.progress} timeout="auto" unmountOnExit>
               <CardContent>
                   {userData.progressPhotos.map((photo, index) => (
                 <Grid container spacing={2}>
                    {/* Back */}
                     <Grid item xs={4} sm={4} md={4} key={index}>
                       <Box position="relative">
                         <Avatar
                           variant="square"
                           src={photo.back}
                           style={{
                             width: "100%", // or any desired width
                             height: "200px", // or any desired height
                             borderRadius: "15px",
                             objectFit: "cover",
                           }}
                         />
                         <Chip
                           label={new Date(photo.takenOn).toLocaleDateString()}
                           size="small"
                           style={{
                             position: "absolute",
                             bottom: "10px",
                             left: "10px",
                             backgroundColor: "rgba(0, 0, 0, 0.5)",
                             color: "#fff",
                           }}
                         />
                       </Box>
                     </Grid>
                    {/* front */}
                     <Grid item xs={4} sm={4} md={4} key={index}>
                       <Box position="relative">
                         <Avatar
                           variant="square"
                           src={photo.front}
                           style={{
                             width: "100%", // or any desired width
                             height: "200px", // or any desired height
                             borderRadius: "15px",
                             objectFit: "cover",
                           }}
                         />
                         <Chip
                           label={new Date(photo.takenOn).toLocaleDateString()}
                           size="small"
                           style={{
                             position: "absolute",
                             bottom: "10px",
                             left: "10px",
                             backgroundColor: "rgba(0, 0, 0, 0.5)",
                             color: "#fff",
                           }}
                         />
                       </Box>
                     </Grid>
                    {/* side */}
                     <Grid item xs={4} sm={4} md={4} key={index}>
                       <Box position="relative">
                         <Avatar
                           variant="square"
                           src={photo.side}
                           style={{
                             width: "100%", // or any desired width
                             height: "200px", // or any desired height
                             borderRadius: "15px",
                             objectFit: "cover",
                           }}
                         />
                         <Chip
                           label={new Date(photo.takenOn).toLocaleDateString()}
                           size="small"
                           style={{
                             position: "absolute",
                             bottom: "10px",
                             left: "10px",
                             backgroundColor: "rgba(0, 0, 0, 0.5)",
                             color: "#fff",
                           }}
                         />
                       </Box>
                     </Grid>


                 </Grid>
                   ))}
                 <Box mt={2} display="flex" justifyContent="center">
                   <Button
                     variant="contained"
                     color="primary"
                     style={{ borderRadius: "15px" }}
                   >
                     Add Photo
                   </Button>
                 </Box>
               </CardContent>
             </Collapse>
           </Card>
         </Grid>


         {/* Exercise History */}
         <ExerciseHistory userId={userData.userId} />
         

       </Grid>
        )}
        {tab === "calendar" && (
      <DndProvider backend={HTML5Backend}>
      <StridistCalendar events={events} />
    </DndProvider>
        )}
        {tab === "responses" && (
          <div>Responses Page</div>
        )}
{tab === "notes" && (
  <>
     <Button onClick={() => setIsAddNoteOpen(true)}>Add Notes</Button>

     <Dialog open={isAddNoteOpen} onClose={() => setIsAddNoteOpen(false)}>
       <DialogTitle>Add New Note</DialogTitle>
       <DialogContent>
         <ClientNotes />
       </DialogContent>
     </Dialog>
     </>

)}
        {tab === "nutrition" && (
          <div>Nutrition</div>
        )}


        <Dialog open={isAddGoalOpen} onClose={() => setIsAddGoalOpen(false)}>
          <DialogTitle>Add New Goal</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              variant="outlined"
              label="Goal"
              value={newGoal}
              onChange={(e) => setNewGoal(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAddGoalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setUserData((prevData) => ({
                  ...prevData,
                  goals: [...prevData.goals, { name: newGoal, progress: 0 }],
                }));
                setNewGoal("");
                setIsAddGoalOpen(false);
              }}
              color="primary"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ClientPage;

