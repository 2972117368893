import React from "react";
import { useNavigate } from "react-router-dom";


const ArchivedClients = ({
  sort,
  handleSortChange,
  view,
  handleViewChange,
  clients,
  handleMenuClose,
  handleMenuOpen,
  anchorEl,
}) => {
  const navigate = useNavigate();
  console.log(clients);

  const handleClientClick = () => {
    navigate(`/client/`);
    //    navigate(`/client/${clientId}`);
  };

  return <div>{/* <h1>ARCHIVED TODO</h1> */}</div>;
};

export default ArchivedClients;
