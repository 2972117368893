import React, { useState } from "react";
import {
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ActiveClients from "../../components/clients/ActiveClients";
import PendingClients from "../../components/clients/PendingClients";
import ArchivedClients from "../../components/clients/ArchivedClients";
import ClientForms from "../../components/clients/ClientForms";
import { useAppContext } from "../../libs/contextLib";
import AddNewClientModal from "../../components/clients/AddNewClientModal";

const AllClients = () => {
  const { apiUrl } = useAppContext()
  const [view, setView] = useState(localStorage.getItem('view') || 'grid');
  const [sort, setSort] = useState("asc");
  const [tab, setTab] = useState("active");
  const [clients, setClients] = React.useState([]);
  const [pending, setPending] = React.useState([]);
  const [tabContents, setTabContents] = React.useState(null);
  const [reloadTrigger, setReloadTrigger] = React.useState(false);

  React.useEffect(() => {
    async function getData() {
      let resp = await axios.get(`${apiUrl}/api/pages/clients`);
      console.log(resp.data);
      if (resp.data.clients) {
        setClients(resp.data.clients);
      }
      if (resp.data.pending) {
        setPending(resp.data.pending);
      }
    }
    if (apiUrl !== null) {
      getData();
    }
  }, [apiUrl, reloadTrigger]);

  React.useEffect(() => {
    function renderTab() {
      if (tab === "active") {
        setTabContents(
          <ActiveClients
            sort={sort}
            handleSortChange={handleSortChange}
            view={view}
            handleViewChange={handleViewChange}
            clients={clients}
          />
        );
      } else if (tab === "pending") {
        setTabContents(
          <PendingClients
            sort={sort}
            handleSortChange={handleSortChange}
            view={view}
            handleViewChange={handleViewChange}
            clients={pending}
            reloadTrigger={handleReload}
          />
        );
      } else if (tab === "archived") {
        setTabContents(
          <ArchivedClients
            sort={sort}
            handleSortChange={handleSortChange}
            view={view}
            handleViewChange={handleViewChange}
            clients={pending}
          />
        );
      } else if (tab === "forms") {
        setTabContents(<ClientForms />);
      }
    }

    if (apiUrl !== null) {
      renderTab();
    }
  }, [tab, clients, pending, apiUrl, view]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      localStorage.setItem('view', newView);
      setView(newView);
    }
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      setTab(newTab);
    }
  };

  const handleNewInvitesSent = () => {
    setTab("pending");
    setReloadTrigger(!reloadTrigger)
  }
  const handleReload = () => {
    setReloadTrigger(!reloadTrigger)
  }


  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="div" align="left">
            Clients
          </Typography>

          {/* Add Client */}
          <AddNewClientModal handleNewInvitesSent={handleNewInvitesSent} />
          {/* END Add Client */}

        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="active" style={{ flex: 1 }}>
              Active
            </ToggleButton>
            <ToggleButton value="pending" style={{ flex: 1 }}>
              Pending
            </ToggleButton>
            <ToggleButton value="archived" style={{ flex: 1 }}>
              Archived
            </ToggleButton>
            <ToggleButton value="forms" style={{ flex: 1 }}>
              Forms
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      {tabContents}
    </>
  );
};

export default AllClients;
