import React, { useEffect, useState } from "react";
import { Dialog, Box, AvatarGroup, DialogContent, TextField, List, ListItem, ListItemText, ListItemAvatar, Avatar, Button, Checkbox, Typography, IconButton } from '@mui/material'
import GroupIcon from "@mui/icons-material/Group";
import axios from "axios";
import Swal from "sweetalert2";
import { useAppContext } from "../../libs/contextLib";


const getGroupMembersAndAvailableMembers = async (apiUrl, groupId) => {
    let resp = await axios.get(`${apiUrl}/api/GroupMembersAndAvailableMembers/${groupId}`)
    return resp
}

const addClientsToGroup = async (apiUrl, groupId, clientIds) => {
    let resp = await axios.post(`${apiUrl}/api/AddClientToGroup/`, {
        GroupId: groupId,
        ClientIds: clientIds
    })
    return resp
}

const AddMemberToGroup = ({ groupId, parentTrigger }) => {
    const { apiUrl } = useAppContext()
    const [enrollDialogOpen, setEnrollDialogOpen] = useState(false);
    const [clients, setClients] = useState([])
    const [trigger, setTrigger] = useState(true);
    const [selectedClients, setSelectedClients] = useState([])

    useEffect(() => {
        async function getData() {
            let resp = await getGroupMembersAndAvailableMembers(apiUrl, groupId)
            setClients(resp.data)
            console.log(resp.data)
        }
        if (apiUrl !== null) {
            getData()
        }
    }, [apiUrl, trigger])


    const handleReloadTrigger = () => {
        setTrigger(!trigger)
    }

    const onClose = () => {
        setEnrollDialogOpen(false)
    }

    const handleSelect = (clientId) => {
        setSelectedClients(prev =>
            prev.includes(clientId) ? prev.filter(id => id !== clientId) : [...prev, clientId]
        )
    }

    const handleEnroll = async () => {
        const response = await addClientsToGroup(apiUrl, groupId, selectedClients)
        if (response.status === 200) {
            // Success message using SweetAlert
            Swal.fire({
                title: 'Success',
                text: 'Clients Added!',
                icon: 'success',
            }).then((result) => {
                setSelectedClients([])
                handleReloadTrigger()
                if (parentTrigger) {
                    parentTrigger()
                }
            });

            onClose()
        } else {
            // Error message using SweetAlert
            Swal.fire({
                title: 'Error',
                text: 'An error occurred ',
                icon: 'error',
            });
        }
    }

    return (

        <Box>
            <AvatarGroup max={4}>

                {clients.filter(i => i.enrolled).map((c, index) => (
                    <Avatar
                        alt={c.displayName}
                        src={c.photoUrl}
                    />
                ))}

            </AvatarGroup>
            <IconButton
                sx={{
                    background: "#EB5757",
                    fontSize: "12px",
                    color: "white",
                    borderRadius: 2,
                }}
                onClick={() => setEnrollDialogOpen(true)}
            >
                <GroupIcon />
                Add Clients
            </IconButton>




            <Dialog open={enrollDialogOpen} onClose={onClose} fullWidth maxWidth="md">

                <DialogContent>
                    <Typography variant="h5">Enroll Clients</Typography>
                    <br />
                    {/* <TextField
                        label="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        fullWidth
                        sx={{ zIndex: 100 }}
                    /> */}
                    <List style={{ maxHeight: 400, overflow: 'auto', margin: 2, padding: 1 }}>
                        {clients.map(client => (
                            <ListItem button onClick={() => handleSelect(client.clientId)} key={client.clientId}>
                                <ListItemAvatar>
                                    <Avatar src={client.photoUrl}>{client.photoUrl}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={client.displayName} />

                                {client.memberOfGroup ?
                                    <>Member</>
                                    :
                                    <Checkbox
                                        edge="end"
                                        checked={selectedClients.includes(client.clientId)}
                                    />
                                }

                            </ListItem>
                        ))}
                    </List>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <AvatarGroup max={4}>
                            {clients.filter(client => selectedClients.includes(client.clientId)).map((client) => (
                                <Avatar
                                    alt={client.name}
                                    src={client.photoUrl}
                                    key={client.clientId}
                                />
                            ))}
                        </AvatarGroup>
                        <Button variant="contained" color="primary" onClick={handleEnroll} disabled={selectedClients.length === 0}>
                            Add ({selectedClients.length})
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>


        </Box>
    )
}

export default AddMemberToGroup