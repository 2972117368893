import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  TextField,
  Box,
  Select,
  MenuItem,
  Menu,
  Avatar,
  AvatarGroup,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import { Add, MoreHoriz } from "@mui/icons-material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";
import AddNewGroupModal from "../../components/groups/AddNewGroupModal"
import GroupItem from "../../components/groups/GroupItem";
import theme from "../../components/theme/theme";

const GroupsPage = () => {
  const { apiUrl } = useAppContext()
  const [view, setView] = useState(localStorage.getItem('view') || 'grid');
  const [sort, setSort] = useState("asc");
  const [tab, setTab] = useState("active");
  const navigate = useNavigate();
  const [groups, setGroups] = React.useState([]);
  const [reloadTrigger, setReloadTrigger] = React.useState(false);
  const [filter, setFilter] = React.useState('');


  const filteredGroups = groups.filter((group) =>
    tab === "active" ? !group.archived : group.archived
  );


  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      localStorage.setItem('view', newView);
      setView(newView);
    }
  };
  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      setTab(newTab);
    }
  };
  const handleReload = () => {
    setReloadTrigger(!reloadTrigger)
  }

  const handleNewGroupCreated = () => {
    setReloadTrigger(!reloadTrigger)
  }

  React.useEffect(() => {
    async function getData() {
      let resp = await axios.get(`${apiUrl}/api/pages/groups`);
      console.log(resp.data);
      setGroups(resp.data.groups);
    }
    if (apiUrl !== null) {
      getData();
    }
  }, [apiUrl, reloadTrigger]);

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="div" align="left">
            Groups
          </Typography>

          {/* Add Group */}
          <AddNewGroupModal handleNewGroupCreated={handleNewGroupCreated} />
          {/* END Add Group */}


        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="active" style={{ flex: 1 }}>
              Active
            </ToggleButton>
            <ToggleButton value="archived" style={{ flex: 1 }}>
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      <div style={{ padding: "20px" }}>
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <TextField
                label="Search Groups"
                variant="outlined"
                sx={{ background: "white" }}
                value={filter}
                onChange={(e) => { setFilter(e.target.value) }}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {/* 
            TODO: Sorting
              <Typography>Sort By:</Typography>
              <Select
                value={sort}
                onChange={handleSortChange}
                variant="outlined"
                sx={{ background: "white" }}
              >
                <MenuItem value={"desc"}>Name</MenuItem>
                <MenuItem value={"desc"}>Due Date Programs</MenuItem>
                <MenuItem value={"desc"}>Date Added (Newest First)</MenuItem>
                <MenuItem value={"desc"}>Date Added (Oldest First)</MenuItem>
                <MenuItem value={"asc"}>Last Active (Newest First)</MenuItem>
                <MenuItem value={"desc"}>Last Active (Oldest First)</MenuItem>
              </Select>
            */}
              <ToggleButtonGroup
                color="primary"
                value={view}
                exclusive
                onChange={handleViewChange}
              >
                <ToggleButton value="grid">
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list">
                  <ViewListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

          </div>
          <Typography sx={{ margin: "10px 0" }}>
            Showing {filteredGroups.length} Groups{" "}
          </Typography>
        </Box>
        {/* Grid or List of Program Cards */}

        <GroupItem
          sort={sort}
          handleSortChange={handleSortChange}
          view={view}
          handleViewChange={handleViewChange}
          groups={filteredGroups.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()))}
          reloadTrigger={handleReload} />

      </div >
    </>
  );
};

export default GroupsPage;
