import React, { useState } from 'react';
import {
    Box,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Card,
    CardContent,
    TextField,
    InputAdornment
} from '@mui/material';

import { Button, Input } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import theme from '../theme/theme'

const ClientPortal = ({ title, userSettings, apiUrl, coachId }) => {
    const [formData, setFormData] = useState({
        name: userSettings.coachName,
        portalUrl: userSettings.portalUrl,
        motivationMsg: userSettings.motivationalMsg,
        enableMealLogging: userSettings.enableMealLogging,
        primaryColor: userSettings.colour,
        customColor: userSettings.customColour
    });

    const [uploadedImage, setUploadedImage] = useState(null);
    const [canClickSave, setCanClickSave] = useState(false)

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setUploadedImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setCanClickSave(true)
    }

    const handleMealLoggingToggle = () => {
        setFormData({
            ...formData,
            "enableMealLogging": !formData.enableMealLogging
        });
        setCanClickSave(true)
    };

    const handleColorChange = (e) => {
        setFormData({
            ...formData,
            "primaryColor": e.target.value
        });
        setCanClickSave(true)
    };

    const updateProfileSettings = async () => {
        setCanClickSave(false)

        const response = await axios.post(`${apiUrl}/api/SetClientPortal`, {
            ...formData,
            CoachId: coachId
        });

        if (response.status === 200) {
            // Success message using SweetAlert
            Swal.fire({
                title: 'Success',
                text: 'Profile settings updated successfully!',
                icon: 'success',
            });
        } else {
            // Error message using SweetAlert
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while updating profile settings.',
                icon: 'error',
            });
        }

    }

    return (
        <>
            <Typography variant="h3">{title}</Typography>
            <Card style={{ marginTop: '20px' }}>

                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {uploadedImage && (
                            <img src={uploadedImage} alt="Uploaded" style={{ height: '100px', marginRight: '20px' }} />
                        )}
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            sx={{ color: 'primary.main', borderColor: 'white' }}
                        >
                            Upload Logo
                            <Input type="file" sx={{ display: 'none' }} hidden onChange={handleImageUpload} />
                        </Button>
                    </Box>
                    <TextField label="Name" fullWidth style={{ margin: '8px 0' }} name="name" value={formData.name} onChange={handleFormChange} />
                    <TextField
                        label="Portal URL"
                        fullWidth
                        style={{ margin: '8px 0' }}
                        name="portalUrl"
                        value={formData.portalUrl}
                        onChange={handleFormChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">www.stridist.com/</InputAdornment>,
                        }}
                    />
                    <TextField label="Motivational Message" fullWidth style={{ margin: '8px 0' }} name="motivationMsg" value={formData.motivationMsg} onChange={handleFormChange} />
                    <>
                        <FormGroup>
                            <Typography variant="h6">Enable Meal Logging</Typography>
                            <Typography variant="subtitle1">If enabled, clients can log meals from the home calendar</Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.enableMealLogging}
                                        onChange={handleMealLoggingToggle}
                                        name="enableMealLogging"
                                    />
                                }
                                label={formData.enableMealLogging ? "Enabled" : "Disabled"}
                            />

                        </FormGroup>
                        <Typography variant="h6">Theme Settings</Typography>
                        <Typography variant="subtitle1">Change the appearance of what your clients see when they log in</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            name="primaryColor"
                            value={formData.primaryColor}
                            exclusive
                            onChange={handleColorChange}
                            style={{ margin: '8px 0' }}
                        >
                            <ToggleButton value="red" sx={{ textDecoration: 'none', border: '1px solid', borderColor: 'divider' }}>
                                <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: theme.palette.primary.main, marginRight: 1 }} />
                                Default
                            </ToggleButton>
                            <ToggleButton value="blue" sx={{ textDecoration: 'none', border: '1px solid', borderColor: 'divider' }}>
                                <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: '#AED9E0', marginRight: 1 }} />
                                Blue
                            </ToggleButton>
                            <ToggleButton value="green" sx={{ textDecoration: 'none', border: '1px solid', borderColor: 'divider' }}>
                                <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: '#9EE493', marginRight: 1 }} />
                                Green
                            </ToggleButton>
                            <ToggleButton value="custom" sx={{ textDecoration: 'none', border: '1px solid', borderColor: 'divider' }}>
                                Custom
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {formData.primaryColor === 'custom' && (
                            <TextField
                                label="Custom Color Hex Code"
                                fullWidth
                                style={{ margin: '8px 0' }}
                                name="customColor"
                                onChange={handleFormChange}
                            />
                        )}
                    </>
                </CardContent>

                <div style={{ display: 'flex', justifyContent: 'end', padding: '1rem' }}>

                    <Button variant="contained" color="primary" disabled={!canClickSave} onClick={updateProfileSettings} >
                        Save
                    </Button>

                </div>

            </Card>
        </>
    );
};

export default ClientPortal