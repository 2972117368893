import React, { useState } from "react"
import {
    Box,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Card,
    CardContent,
    TextField,
    FormControl,
    FormLabel,
    Select,
    InputAdornment,
    IconButton,
    Checkbox,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Input } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { FormControlLabel } from '@mui/material';
import axios from "axios";
import Swal from 'sweetalert2';
import PasswordResetModal from "../settings/ResetPasswordModal"


const timeZones = ['UTC-12:00', 'UTC-11:00']; // Replace this with the actual time zones


const ProfileSettings = ({ title, userSettings, apiUrl }) => {

    const [fullName, setFullName] = useState(userSettings.fullName);
    const [emailAddress, setEmailAddress] = useState(userSettings.email);
    const [phoneNumber, setPhoneNumber] = useState(userSettings.phoneNumber ?? '');
    const [profileImage, setProfileImage] = useState(userSettings.photoUrl);
    const [unit, setUnit] = useState(localStorage.getItem("unit") || "imperial");
    const [notifications, setNotifications] = useState({
        newMessage: userSettings.newMsgFromClient,
        newResponse: userSettings.newResponseFromClient,
        activityComplete: userSettings.clientCompletesActivity,
    });
    const [canClickSave, setCanClickSave] = useState(false)
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = React.useState(false);

    const handleUnitChange = (event, newUnit) => {
        if (newUnit !== null) {
            localStorage.setItem("unit", newUnit);
            setUnit(newUnit);
        }
    };

    const handleNotificationChange = (event) => {
        setNotifications({ ...notifications, [event.target.name]: event.target.checked });
        setCanClickSave(true)
    };

    const handleProfileImageUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${apiUrl}/api/setProfilePhoto`, formData, {
                headers: {
                    'Content-Type': 'undefined', // Let Axios set the correct content type
                },
            });

            if (response.status === 200) {
                setProfileImage(URL.createObjectURL(file));
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const updateProfileSettings = async () => {
        setCanClickSave(false)
        const response = await axios.post(`${apiUrl}/api/SetUserProfile`, {
            FullName: fullName,
            Email: emailAddress,
            PhoneNumber: phoneNumber,
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            NewMsgFromClient: notifications.newMessage,
            newResponseFromClient: notifications.newResponse,
            ClientCompletesActivity: notifications.activityComplete
        });

        if (response.status === 200) {
            // Success message using SweetAlert
            Swal.fire({
                title: 'Success',
                text: 'Profile settings updated successfully!',
                icon: 'success',
            });
        } else {
            // Error message using SweetAlert
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while updating profile settings.',
                icon: 'error',
            });
        }

    }

    return (
        <>
            <Typography variant="h3">{title}</Typography>
            <Card style={{ marginTop: '20px' }}>

                <CardContent>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {profileImage && (
                            <img src={profileImage} alt="Profile" style={{ height: '100px', width: '100px', borderRadius: '50%', margin: '20px' }} />
                        )}
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            sx={{ color: 'primary.main', borderColor: 'white' }}
                        >
                            {profileImage ? 'Replace Image' : 'Upload an Image'}

                            <Input type="file" hidden style={{ display: 'none' }} onChange={handleProfileImageUpload} />
                        </Button>
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField label="Full Name" style={{ flex: 1, margin: '8px 4px 8px 0' }} value={fullName} onChange={(e) => { setFullName(e.target.value); setCanClickSave(true) }} />
                        <TextField label="Email Address" style={{ flex: 1, margin: '8px 0 8px 4px' }} value={emailAddress} onChange={(e) => { setEmailAddress(e.target.value); setCanClickSave(true) }} />
                    </div>

                    <TextField
                        label="Phone Number"
                        fullWidth
                        style={{ margin: '8px 0' }}
                        value={phoneNumber}
                        onChange={(e) => { setPhoneNumber(e.target.value); setCanClickSave(true) }}
                    />

                    {/* 
                    <FormControl fullWidth style={{ margin: '8px 0' }}>
                        <FormLabel>Time Zone</FormLabel>
                        <Select native>
                            {timeZones.map((zone, index) => (
                                <option key={index} value={zone}>
                                    {zone}
                                </option>
                            ))}
                        </Select>
                    </FormControl> */}



                    <Typography variant="h6">Unit of Measurement</Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={unit}
                        exclusive
                        onChange={handleUnitChange}
                        style={{ margin: '8px 0' }}
                    >
                        <ToggleButton value="imperial">Imperial/US</ToggleButton>
                        <ToggleButton value="metric">Metric</ToggleButton>
                    </ToggleButtonGroup>
                    <Typography variant="h6">Notifications</Typography>
                    <Typography variant="subtitle1">Choose when you want to be notified</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifications.newMessage}
                                onChange={handleNotificationChange}
                                name="newMessage"
                            />
                        }
                        label="I get a new message from a client"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifications.newResponse}
                                onChange={handleNotificationChange}
                                name="newResponse"
                            />
                        }
                        label="New responses from my clients"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifications.activityComplete}
                                onChange={handleNotificationChange}
                                name="activityComplete"
                            />
                        }
                        label="My client completes an activity"
                    />

                </CardContent>

                <div style={{ display: 'flex', justifyContent: 'end', padding: '1rem' }}>

                    <Button variant="contained" color="primary" onClick={() => { setOpenResetPasswordDialog(true) }} sx={{ marginRight: "1rem" }} >
                        Update Password
                    </Button>

                    <Button variant="contained" color="primary" disabled={!canClickSave} onClick={updateProfileSettings} >
                        Save
                    </Button>

                </div>
            </Card>

            <PasswordResetModal
                show={openResetPasswordDialog}
                setShow={setOpenResetPasswordDialog} />
        </>
    )
}

export default ProfileSettings