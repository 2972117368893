import React, { useState } from "react";
import { Card, CardContent, CardActions, Button, Typography, Tab, Tabs, Box, IconButton, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails, TextField} from "@mui/material";
import { FmdBad, CheckCircle, Warning, DoDisturbOn, ExpandMore } from "@mui/icons-material";
import { Slate } from "slate-react"

export const Text = ({ attributes, children }) => {
  return <p {...attributes}>{children}</p>;
};

export const HeadingOne = ({ attributes, children }) => {
  return <h1 {...attributes}>{children}</h1>;
};

export const HeadingTwo = ({ attributes, children }) => {
  return <h2 {...attributes}>{children}</h2>;
};

export const Audio = ({ attributes, children, file }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [link, setLink] = useState("");
  const [tabValue, setTabValue] = useState(0); // new state variable for the tab value

  const handleFileUpload = (event) => {
    setFileUploaded(true);
    setFileName(event.target.files[0].name);
  };

  const handleLinkPaste = (event) => {
    setLink(event.target.value);
  };

  const handleTabChange = (event, newValue) => { // new handler for changing the tab
    setTabValue(newValue);
  };

  return (
    <div {...attributes}>
      {!fileUploaded ? (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">Upload Audio or Embed</Typography>
            <Tabs value={tabValue} onChange={handleTabChange}> {/* new Tabs component */}
              <Tab label="Upload" />
              <Tab label="Embed" />
            </Tabs>
            {tabValue === 0 && (
              <Button size="small" component="label">
                Upload File
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
            )}
            {tabValue === 1 && (
              <input type="text" placeholder="Paste link here" onChange={handleLinkPaste} />
            )}
          </CardContent>
        </Card>
      ) : (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">{fileName || link}</Typography>
          </CardContent>
          <CardActions>
            <Button size="small" download>
              Download
            </Button>
          </CardActions>
        </Card>
      )}
      {children}
    </div>
  );
};

export const Video = ({ attributes, children, file }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [link, setLink] = useState("");
  const [tabValue, setTabValue] = useState(0); // new state variable for the tab value

  const handleFileUpload = (event) => {
    setFileUploaded(true);
    setFileName(event.target.files[0].name);
  };

  const handleLinkPaste = (event) => {
    setLink(event.target.value);
  };

  const handleTabChange = (event, newValue) => { // new handler for changing the tab
    setTabValue(newValue);
  };

  return (
    <div {...attributes}>
      {!fileUploaded ? (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">Upload Video or Embed</Typography>
            <Tabs value={tabValue} onChange={handleTabChange}> {/* new Tabs component */}
              <Tab label="Upload" />
              <Tab label="Embed" />
            </Tabs>
            {tabValue === 0 && (
              <Button size="small" component="label">
                Upload File
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
            )}
            {tabValue === 1 && (
              <input type="text" placeholder="Paste link here" onChange={handleLinkPaste} />
            )}
          </CardContent>
        </Card>
      ) : (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6">{fileName || link}</Typography>
          </CardContent>
          <CardActions>
            <Button size="small" download>
              Download
            </Button>
          </CardActions>
        </Card>
      )}
      {children}
    </div>
  );
};

export const FileAttachment = ({ attributes, children, file }) => {
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileName, setFileName] = useState("");
    const [link, setLink] = useState("");
    const [tabValue, setTabValue] = useState(0); // new state variable for the tab value
  
    const handleFileUpload = (event) => {
      setFileUploaded(true);
      setFileName(event.target.files[0].name);
    };
  
    const handleLinkPaste = (event) => {
      setLink(event.target.value);
    };
  
    const handleTabChange = (event, newValue) => { // new handler for changing the tab
      setTabValue(newValue);
    };
  
    return (
      <div {...attributes}>
        {!fileUploaded ? (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Upload File or Embed</Typography>
              <Tabs value={tabValue} onChange={handleTabChange}> {/* new Tabs component */}
                <Tab label="Upload" />
                <Tab label="Embed" />
              </Tabs>
              {tabValue === 0 && (
                <Button size="small" component="label">
                  Upload File
                  <input type="file" hidden onChange={handleFileUpload} />
                </Button>
              )}
              {tabValue === 1 && (
                <input type="text" placeholder="Paste link here" onChange={handleLinkPaste} />
              )}
            </CardContent>
          </Card>
        ) : (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">{fileName || link}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" download>
                Download
              </Button>
            </CardActions>
          </Card>
        )}
        {children}
      </div>
    );
  };

  export const ChecklistItem = ({ attributes, children }) => {
    return (
      <li {...attributes} style={{ listStyleType: 'none' }}>
        <input type="checkbox" />
        {children}
      </li>
    );
  };
  
  export const BulletedList = ({ attributes, children }) => {
    return <ul {...attributes}>{children}</ul>;
  };
  
  
  export const ChecklistNode = ({ attributes, children }) => {
    return <ul {...attributes}>{children}</ul>;
  };
  
  export const ListItem = ({ attributes, children }) => {
    return <li {...attributes}>{children}</li>;
  };
  
  export const NumberedList = ({ attributes, children }) => {
    return <ol {...attributes}>{children}</ol>;
  };

  export const ImageNode = ({ attributes, children }) => {
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileName, setFileName] = useState("");
    const [link, setLink] = useState("");
    const [tabValue, setTabValue] = useState(0);

  
    const handleFileUpload = (event) => {
      setFileUploaded(true);
      setFileName(URL.createObjectURL(event.target.files[0]));
    };
  
    const handleLinkPaste = (event) => {
      setLink(event.target.value);
    };
  
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };
  
    return (
      <div {...attributes} contentEditable={false}>
        {!fileUploaded ? (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Image</Typography>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Upload" />
                <Tab label="Paste Link" />
         
              </Tabs>
              {tabValue === 0 && (
                <Button size="small" component="label">
                  Upload File
                  <input type="file" hidden onChange={handleFileUpload} />
                </Button>
              )}
              {tabValue === 1 && (
                <input type="text" placeholder="Paste link here" onChange={handleLinkPaste} />
              )}
           
            </CardContent>
          </Card>
        ) : (
          <Card variant="outlined">
            <CardContent>
              <img src={fileName || link} alt="Uploaded content" />
            </CardContent>
          </Card>
        )}
        {children}
      </div>
    );
  };


  export const Callout = ({ attributes, children }) => {
    const [icon, setIcon] = useState('pin');
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleIconClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuItemClick = (selectedIcon) => {
      setIcon(selectedIcon);
      setAnchorEl(null);
    };
  
    let IconComponent;
    let backgroundColor;
    switch (icon) {
      case 'pin':
        IconComponent = FmdBad;
        backgroundColor = 'lightblue';
        break;
      case 'check':
        IconComponent = CheckCircle;
        backgroundColor = 'lightgreen';
        break;
      case 'warning':
        IconComponent = Warning;
        backgroundColor = 'lightyellow';
        break;
      case 'stop':
        IconComponent = DoDisturbOn;
        backgroundColor = 'pink';
        break;
      default:
        IconComponent = FmdBad;
        backgroundColor = 'lightblue';
    }
    return (
      <div {...attributes}>
        <Card variant="outlined" sx={{ bgcolor: backgroundColor, borderRadius: 2 }}>
          <CardContent style={{ display: 'flex', alignItems: 'center' }} contentEditable={false}>
            <IconButton onClick={handleIconClick}><IconComponent /></IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => handleMenuItemClick('pin')}><FmdBad /></MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('check')}><CheckCircle /></MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('warning')}><Warning /></MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('stop')}><DoDisturbOn /></MenuItem>
            </Menu>
            <div 
              contentEditable={true} 
              suppressContentEditableWarning={true}
              style={{ outline: 'none', flexGrow: 1 }}
              placeholder="Insert your callout text here"
            >
              {children}
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  export const TabbedEditor = ({ attributes, children, element }) => {
    const [tabValue, setTabValue] = useState(0);
    const [tabs, setTabs] = useState([{ id: 0, content: "" }]);
  
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };
  
    const handleAddTab = () => {
      setTabs([...tabs, { id: tabs.length, content: "" }]);
    };
  
    const handleEditorChange = (id, event) => {
      setTabs(tabs.map(tab => tab.id === id ? { ...tab, content: event.target.value } : tab));
    };
  
    return (
      <div {...attributes} contentEditable={false}>
      {children}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              {tabs.map((tab, index) => (
                <Tab key={index} label={`Tab ${index + 1}`} />
              ))}
              <Button onClick={handleAddTab}>Add Tab</Button>
            </Tabs>
          </Box>
          {tabs.map((tab, index) => (
            <div
              role="tabpanel"
              hidden={tabValue !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              key={index}
            >
              {tabValue === index && (
                <Box sx={{ p: 3 }}>
                  <textarea value={tab.content} onChange={event => handleEditorChange(tab.id, event)} />
                </Box>
              )}
            </div>
          ))}
        </Box>
      </div>
    );
  };

  export const AccordionEditor = ({ attributes, children, element }) => {
    const [expanded, setExpanded] = useState(false);
    const [panels, setPanels] = useState([{ title: 'Panel 1', content: '' }]); // Initial panel
  
    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    const handlePanelTitleChange = (index, newTitle) => {
      setPanels(panels.map((panel, panelIndex) => panelIndex === index ? { ...panel, title: newTitle } : panel));
    };
  
    const handlePanelContentChange = (index, newContent) => {
      setPanels(panels.map((panel, panelIndex) => panelIndex === index ? { ...panel, content: newContent } : panel));
    };
  
    const handleAddPanel = () => {
      setPanels([...panels, { title: `Panel ${panels.length + 1}`, content: '' }]); // Add a new panel with empty content
    };
  
    return (
      <div {...attributes} contentEditable={false}>
        {children}
        {panels.map((panel, index) => (
          <Accordion expanded={expanded === `panel${index}`} onChange={handleAccordionChange(`panel${index}`)} style={{ boxShadow: 'none', width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <TextField
                value={panel.title}
                onChange={(e) => handlePanelTitleChange(index, e.target.value)}
                style={{ width: '100%' }}
                InputProps={{ disableUnderline: true }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={panel.content}
                onChange={(e) => handlePanelContentChange(index, e.target.value)}
                style={{ width: '100%' }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
        <Button onClick={handleAddPanel}>Add Panel</Button>
      </div>
    );
  };
