import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/storage";


const firebaseConfig = firebase.initializeApp({
    apiKey: "AIzaSyAdEFodJfzGmGholw7Y7Zy609RZ14TC2v4",
    authDomain: "stridistcore-24173.firebaseapp.com",
    projectId: "stridistcore-24173",
    storageBucket: "stridistcore-24173.appspot.com",
    messagingSenderId: "976540699776",
    appId: "1:976540699776:web:f050086d092a73ab0daa65"
});




export default firebaseConfig;

