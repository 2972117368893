import React, { useState } from 'react';
import { IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getDay, subMonths, addMonths, subDays, addDays, isSameDay, isToday } from 'date-fns';
import PlannerItem from '../../pages/planner/plannerItem.js';


const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const calculateWeekIndex = (date) => {
  // For drag n drop.
  return 0;
};

const calculateDayIndex = (date) => {
  // For drag n drop.
  return 0;
};

const yourMoveEventFunction = (event) => {
  // For drag n drop.
  console.log('Moved event:', event);
};

const StridistCalendar = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getDayAdjusted = (date) => {
    const day = getDay(date);
    return day === 0 ? 6 : day - 1;
  };

  const handlePrevMonth = () => {
    setCurrentDate(prevDate => subMonths(prevDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => addMonths(prevDate, 1));
  };

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const datesInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });


  const firstDayOfMonth = getDayAdjusted(monthStart);
  for (let i = 1; i <= firstDayOfMonth; i++) {
    datesInMonth.unshift(subDays(monthStart, i));
  }

  const lastDayOfMonth = getDayAdjusted(monthEnd);
  for (let i = 1; i < (7 - lastDayOfMonth); i++) {
    datesInMonth.push(addDays(monthEnd, i));
  }

  return (
    <>
      <div>
        <Button onClick={handlePrevMonth}>&lt;</Button>
        <span>{format(currentDate, 'MMMM yyyy')}</span>
        <Button onClick={handleNextMonth}>{'>'}</Button>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)' }}>

        {days.map((day) => (
          <div style={{ border: '0.5px solid #D0D8DC', padding: '10px', textAlign: 'center' }}>{day}</div>
        ))}
        {datesInMonth.map((date, i) => (
          <div className="calendar-cell" style={{ border: '0.5px solid #D0D8DC', padding: '10px', position: 'relative', backgroundColor: format(date, 'MM') === format(currentDate, 'MM') ? 'white' : '#7E898F', minHeight: '170px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', fontWeight: 'bold', color: '#7E898F' }}>
            <div className="add-button" style={{ position: 'absolute', top: '5px', right: '5px', visibility: 'hidden' }}>
              <IconButton size="small"><AddIcon fontSize="inherit" /></IconButton>
            </div>
            {isToday(date) ?
              <div style={{ borderRadius: '50%', backgroundColor: 'grey', color: 'white', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {format(date, 'd')}
              </div>
              :
              format(date, 'd')
            }
            {events.filter(event => {
              return isSameDay(new Date(event.date), date);
            }).map((event, index) => (
              <PlannerItem
                key={index}
                event={event}
                weekIndex={calculateWeekIndex(new Date(event.date))}
                dayIndex={calculateDayIndex(new Date(event.date))}
                onMoveEvent={yourMoveEventFunction}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default StridistCalendar;