import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <div className="gradient"></div>
    </div>
  );
}

export default Loader;