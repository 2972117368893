import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LogoFull from '../../images/logofull.svg'
import { Typography } from '@mui/material';
import { useAppContext } from "../../libs/contextLib";
import axios from "axios"

const SubscriptionSuccess = () => {
  const { apiUrl, coachId } = useAppContext()
  const [showButton, setShowButton] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  useEffect(() => {
    async function getData() {
      try {
        let resp = await axios.post(`${apiUrl}/api/ValidateWithStripe`, {
          CoachId: coachId
        });
        console.log(resp)
        setShowButton(true)
      } catch (error) {
        console.log("error", error);
        if (error.response.status === 400) {
          // we have no customer data, they need to signup
          setShowFailure(true)
        }
        else {
          alert(error.response.data);
        }
      }
    }

    if (apiUrl !== null) {
      getData();
    }
  }, [apiUrl]);


  if (showFailure) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <img src={LogoFull} />
        <Typography variant="h4">
          Sorry, it looks like you don't have an active subscription.
        </Typography>
        <br />
        <Fade in={showFailure}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            href="/signup"
          >
            Let's get started
          </Button>
        </Fade>
      </div>
    );

  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <img src={LogoFull} />
      <Typography variant="h4">
        Congrats, you're now a Stridist Coach!
      </Typography>
      <br />
      <Fade in={showButton}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={() => { window.location.reload() }}
        >
          Let's get started
        </Button>
      </Fade>
    </div>
  );
}

export default SubscriptionSuccess;