import { formatDistanceToNow } from "date-fns";

function isDateValid(dateString) {
    const dateObject = new Date(dateString);
    // Check if the dateObject is a valid date and not NaN
    return !isNaN(dateObject) && dateObject.toString() !== 'Invalid Date' && dateObject.getFullYear() > 2000;
}

const PendingInviteStatusComponent = ({ invite }) => {

    const isValid = isDateValid(invite.updatedAt);
    const hasEmailBeenSent = isDateValid(invite.lastInviteSentAt)

    if (hasEmailBeenSent === false && isValid === false) {
        return (
            <>Error Sending Email Invite - Try to resend</>
        )
    }

    if (isValid) {
        if (invite.accepted) {
            return (
                <>Accepted - Waiting for App install</>
            )
        }
        else {

            return (
                <>Declined</>
            )
        }
    } else {
        return (
            <>
                No Response, {`Last Email sent ${formatDistanceToNow(
                    new Date(invite.lastInviteSentAt+"Z")
                )} ago`}
            </>
        )
    }



}

export default PendingInviteStatusComponent