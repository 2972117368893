import { Chip } from "@mui/material"

const ProgramStatusChip = ({ status }) => {

    const bgc = status === "PUBLISHED" ? "#C2C2C2" : "#E7F3EC";
    const c = status === "PUBLISHED" ? "white" : "#4DA530";

    return (
        <Chip
            label={status}
            variant="label"
            style={{
                backgroundColor: bgc,
                color: c,
                borderRadius: 5,
                alignSelf: "flex-start",
            }}
        />
    )
}

export default ProgramStatusChip