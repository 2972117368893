import React from "react";
import {
    IconButton,
    MenuItem,
    Menu,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";

const duplicateComponent = async (apiUrl, componentId) => {
    let resp = await axios.post(`${apiUrl}/api/DuplicateComponent/`, { ComponentId: componentId })
    return resp
}

const ComponentCardMenu = ({ componentId, handleReloadTrigger }) => {

    const { apiUrl } = useAppContext()
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleDuplicateComponent = async () => {
        try {
            let resp = await duplicateComponent(apiUrl, componentId)
            handleMenuClose()
            if (handleReloadTrigger) {
                handleReloadTrigger()
            }
        } catch (error) {
            console.log("error", error)
            if (error.response.data) {
                alert(error.response.data)
            }
            if (error.message) {
                alert(error.message)
            }
        }
    }

    const handleDelete = () => {
        alert("todo")
        handleMenuClose();
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
            >
                <MoreHoriz />
            </IconButton>

            <Menu
                id="program-card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >

                <MenuItem>Edit</MenuItem>
                <MenuItem onClick={() => handleDuplicateComponent()}>Duplicate</MenuItem>

                <MenuItem onClick={handleDelete}>Delete</MenuItem>

            </Menu>
        </>
    )
}

export default ComponentCardMenu