import React, { useState } from 'react';
import { Dialog, Box, AvatarGroup, DialogContent, TextField, List, ListItem, ListItemText, ListItemAvatar, Avatar, Button, Checkbox, Typography } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';


const enrollClients = async (apiUrl, programId, clientIds) => {
  let resp = await axios.post(`${apiUrl}/api/EnrollClients/`, {
    ProgramId: programId,
    ClientIds: clientIds
  })
  return resp
}

export const EnrollDialog = ({ open, onClose, clients, programId, apiUrl, handleReloadTrigger }) => {
  const [search, setSearch] = useState('');
  const [selectedClients, setSelectedClients] = useState([])

  const handleSelect = (clientId) => {
    setSelectedClients(prev =>
      prev.includes(clientId) ? prev.filter(id => id !== clientId) : [...prev, clientId]
    )
  }


  const filteredClients = clients.filter(client => client.displayName.toLowerCase().includes(search.toLowerCase()));

  const handleEnroll = async () => {
    const response = await enrollClients(apiUrl, programId, selectedClients)
    if (response.status === 200) {
      // Success message using SweetAlert
      Swal.fire({
        title: 'Success',
        text: 'Users enrolled!',
        icon: 'success',
      }).then((result) => {
        setSelectedClients([])
        if (handleReloadTrigger) {
          handleReloadTrigger()
        }
      });

      onClose()
    } else {
      // Error message using SweetAlert
      Swal.fire({
        title: 'Error',
        text: 'An error occurred ',
        icon: 'error',
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">

      <DialogContent>
        <Typography variant="h5">Enroll Clients</Typography>
        <br />
        <TextField
          label="Search"
          value={search}
          onChange={e => setSearch(e.target.value)}
          fullWidth
          sx={{ zIndex: 100 }}
        />
        <List style={{ maxHeight: 400, overflow: 'auto', margin: 2, padding: 1 }}>
          {filteredClients.map(client => (
            <ListItem button onClick={() => handleSelect(client.clientId)} key={client.clientId}>
              <ListItemAvatar>
                <Avatar src={client.photoUrl}>{client.photoUrl}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={client.displayName} />

              {client.enrolled ?
                <>Enrolled</>
                :
                <Checkbox
                  edge="end"
                  checked={selectedClients.includes(client.clientId)}
                />
              }

            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <AvatarGroup max={4}>
            {clients.filter(client => selectedClients.includes(client.clientId)).map((client) => (
              <Avatar
                alt={client.name}
                src={client.photoUrl}
                key={client.clientId}
              />
            ))}
          </AvatarGroup>
          <Button variant="contained" color="primary" onClick={handleEnroll} disabled={selectedClients.length === 0}>
            Enroll ({selectedClients.length})
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};