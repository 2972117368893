import React from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  TextField,
  Box,
  Select,
  MenuItem,
  Menu,
  Avatar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useNavigate } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";
import { useAppContext } from "../../libs/contextLib";
import PendingInviteStatusComponent from "./PendingInviteStatusComponent";
import theme from "../theme/theme";


const deleteInvite = async (apiUrl, inviteId) => {
  let resp = await axios.delete(`${apiUrl}/api/Invites/${inviteId}`)
  return resp
}

const resendInvite = async (apiUrl, inviteId) => {
  let resp = await axios.put(`${apiUrl}/api/Invites/${inviteId}`)
  return resp
}

const PendingClients = ({
  sort,
  handleSortChange,
  view,
  handleViewChange,
  clients,
  reloadTrigger
}) => {
  const { apiUrl } = useAppContext()
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inviteId, setInviteId] = React.useState(null);
  const [filter, setFilter] = React.useState('');

  const handleClientClick = () => {
    navigate(`/clients/`);
    //    navigate(`/client/${clientId}`);
  };

  const handleMenuOpen = (event, id) => {
    setInviteId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteInvite = async () => {
    try {
      let resp = await deleteInvite(apiUrl, inviteId)
      if (reloadTrigger) {
        // hook from parent 
        reloadTrigger()
        handleMenuClose()
      }
    } catch (error) {
      console.log("error", error)
      if (error.response.data) {
        alert(error.response.data)
      }
      if (error.message) {
        alert(error.message)
      }
    }
  }

  const handleResendInvite = async () => {
    try {
      let resp = await resendInvite(apiUrl, inviteId)
      if (reloadTrigger) {
        // hook from parent 
        reloadTrigger()
        handleMenuClose()
      }
    } catch (error) {
      console.log("error", error)
      if (error.response.data) {
        alert(error.response.data)
      }
      if (error.message) {
        alert(error.message)
      }
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <Box sx={{ marginTop: 1, marginBottom: 1 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextField
              label="Search Clients"
              variant="outlined"
              style={{ background: "white" }}
              value={filter}
              onChange={(e) => { setFilter(e.target.value) }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

            <ToggleButtonGroup
              color="primary"
              value={view}
              exclusive
              onChange={handleViewChange}
            >
              <ToggleButton value="grid">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <Typography sx={{ margin: "10px 0" }}>
          Showing {clients.length} Client(s){" "}
        </Typography>
      </Box>
      {/* Grid or List of Program Cards */}
      <Grid container spacing={2}>
        {clients
          .filter(item => item.email.toLowerCase().includes(filter.toLowerCase()))
          .map((c, index) => (
            <Grid
              item
              xs={12}
              sm={view === "grid" ? 6 : 12}
              md={view === "grid" ? 3 : 12}
              lg={view === "grid" ? 3 : 12}
            >
              <Card style={{ height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: view === "grid" ? "column" : "row",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Avatar
                    src={c.photoUrl}
                    alt="program image"
                    onClick={handleClientClick}
                    sx={{
                      width: view === "grid" ? 120 : 60,
                      height: view === "grid" ? 120 : 60,
                      marginBottom: view === "grid" ? 0 : 0,
                      marginRight: view === "grid" ? 0 : 5,
                      marginLeft: view === "grid" ? 0 : 5,
                      marginTop: view === "grid" ? 5 : 0,
                    }}
                  />
                  <IconButton
                    aria-label="settings"
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={(event) => handleMenuOpen(event, c.inviteId)}
                  >
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    id="program-card-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleResendInvite()}>Resend Invite</MenuItem>
                    <MenuItem onClick={() => handleDeleteInvite()}>Delete</MenuItem>
                  </Menu>
                  <CardContent
                    sx={{
                      textAlign: view === "grid" ? "center" : "left",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: view === "grid" ? "center" : "flex-start",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      align={view === "grid" ? "center" : "left"}  
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '200px',
                      }}
                    >
                      {c.email}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "10px", flexDirection: "column"}}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align={view === "grid" ? "center" : "left"}
                      >
                        {`Invited ${formatDistanceToNow(
                          new Date(c.createdAt+"Z")
                        )} ago`}
                      </Typography>

                      {/* TODO, style pretty  */}
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align={view === "grid" ? "center" : "left"}
                      >
                        <strong>Status:</strong>
                        <PendingInviteStatusComponent invite={c} />
                      </Typography>


                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div >
  );
};

export default PendingClients;
