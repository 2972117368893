import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
    Button,
    Card,
    CardContent,
    CardActions,
    FormControl,
    InputLabel,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useAppContext } from "../../libs/contextLib";
import axios from "axios";
import AlreadySubscribedButton from "./AlreadySubscribedButton";

const WhatYouDo = ({ nextStep }) => {

    const { dbUser, apiUrl } = useAppContext()

    const [formData, setFormData] = useState({
        where: "",
        what: "",
        howmany: "",
    })
    const [error, setError] = useState('')

    const handleFormChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (formData.where.length < 3) {
            setError("Please complete the form")
        }
        else if (formData.what.length < 3) {
            setError("Please complete the form")
        }
        else if (formData.howmany.length < 3) {
            setError("Please complete the form")
        }
        else {
            // good to go
            let resp = await axios.post(`${apiUrl}/api/CaptureWhatYouDo/`, {
                ...formData
            });
            console.log(resp.data);
            if (resp.status === 200) {
                nextStep()
            }
        }
    }


    return (
        <>
            <AlreadySubscribedButton />
            <Typography variant="h3">What Do You Do?</Typography>
            <br />
            <form
                onSubmit={onSubmit}
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >

                <FormControl>
                    <InputLabel
                        id="client-location-label"
                        sx={{
                            background: "white",
                            paddingLeft: 1,
                            paddingRight: 1,
                        }}
                    >
                        Where are your clients?
                    </InputLabel>
                    <Select
                        labelId="client-location-label"
                        value={formData.where}
                        name="where"
                        onChange={handleFormChange}
                    >
                        <MenuItem value="online">Online</MenuItem>
                        <MenuItem value="in person">In Person</MenuItem>
                        <MenuItem value="both">Both</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="client-location-label"
                        sx={{
                            background: "white",
                            paddingLeft: 1,
                            paddingRight: 1,
                        }}
                    >
                        What is included in your service?
                    </InputLabel>
                    <Select
                        label="What is included in your service?"
                        value={formData.what}
                        name="what"
                        onChange={handleFormChange}
                    >
                        <MenuItem value="nutrition">Nutrition</MenuItem>
                        <MenuItem value="training">Training</MenuItem>
                        <MenuItem value="both">Both</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="client-location-label"
                        sx={{
                            background: "white",
                            paddingLeft: 1,
                            paddingRight: 1,
                        }}
                    >
                        How many clients do you work with?
                    </InputLabel>
                    <Select
                        label="How many clients do you work with?"
                        value={formData.howmany}
                        name="howmany"
                        onChange={handleFormChange}
                    >
                        <MenuItem value="0-10">0-10</MenuItem>
                        <MenuItem value="11-30">11-30</MenuItem>
                        <MenuItem value="30+">30+</MenuItem>
                    </Select>
                </FormControl>

                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        background:
                            "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                    }}
                >
                    Next
                </Button>
                {error}
            </form>
        </>
    )
}

export default WhatYouDo