import React, { useState } from "react";
import firebaseConfig from "../../auth/config";
import { Link } from "react-router-dom";
import { NotificationsDialog } from "../notifications/notificationsDialog";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Avatar,
  Box,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import logoSml from "../../images/logo_sml.svg";
import logoText from "../../images/logo_text.svg";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { SchoolOutlined } from "@mui/icons-material";

const drawerWidth = 200;
const closedDrawerWidth = 60;

const Sidebar = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
  currentUser,
}) => {
  const [notificationsCount, setNotificationsCount] = useState(3);
  const [messagesCount, setMessagesCount] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotificationsDialog, setOpenNotificationsDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotificationsDialog = () => {
    setOpenNotificationsDialog(true);
  };

  const handleCloseNotificationsDialog = () => {
    setOpenNotificationsDialog(false);
  };

  const handleSignout = async () => {
    await firebaseConfig.auth().signOut()
    window.location.reload()

  }

  const sampleNotifications = [
    {
      id: 1,
      link: '/notification/1',
      user: {
        name: 'John Doe',
        avatarUrl: 'https://source.unsplash.com/random/?person,headshot'
      },
      time: '2 hours ago',
      description: 'John Doe left a comment in messages.'
    },
    {
      id: 2,
      link: '/notification/2',
      user: {
        name: 'Jane Smith',
        avatarUrl: 'https://source.unsplash.com/random/?person,headshot'
      },
      time: '1 day ago',
      description: 'Jane Smith left feedback in Group 1'
    },
    {
      id: 3,
      link: '/notification/2',
      user: {
        name: 'Jane Smith',
        avatarUrl: 'https://source.unsplash.com/random/?person,headshot'
      },
      time: '1 day ago',
      description: 'Jane Smith left feedback in Group 1'
    },
    {
      id: 4,
      link: '/notification/2',
      user: {
        name: 'Jane Smith',
        avatarUrl: 'https://source.unsplash.com/random/?person,headshot'
      },
      time: '1 day ago',
      description: 'Jane Smith left feedback in Group 1'
    },
    {
      id: 5,
      link: '/notification/2',
      user: {
        name: 'Jane Smith',
        avatarUrl: 'https://source.unsplash.com/random/?person,headshot'
      },
      time: '1 day ago',
      description: 'Jane Smith left feedback in Group 1'
    },
    {
      id: 6,
      link: '/notification/2',
      user: {
        name: 'Jane Smith',
        avatarUrl: 'https://source.unsplash.com/random/?person,headshot'
      },
      time: '1 day ago',
      description: 'Jane Smith left feedback in Group 1'
    },
    // May be the wrong place for this
  ];

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: open ? drawerWidth : closedDrawerWidth },
        flexShrink: "0",
      }}
    >
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { sm: open ? drawerWidth : closedDrawerWidth },
            height: "100%",
            bgcolor: "#1A1818",
            overflow: "visible",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          position="relative"
        >
          <IconButton
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "50%",
              color: "#1A1818",
              position: "absolute",
              top: "10px",
              right: "0",
              height: "24px",
              width: "24px",
              marginRight: "-15px",
              zIndex: "999",
              boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                backgroundColor: "#000000",
                color: "#ffffff",
              },
            }}
          >
            {open ? <ChevronLeftIcon fontSize="small" /> : <ChevronRightIcon />}
          </IconButton>

          <Box>
            <Divider />
            <List>
              <ListItem>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: open ? "flex-start" : "center",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <img src={logoSml} alt="logo" />
                  {open && <img src={logoText} alt="text logo" />}
                </Box>
              </ListItem>

              <Divider />
              <ListItem button component={Link} to="/dashboard">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Dashboard" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>

              <ListItem button component={Link} to="/programs">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <AppsOutlinedIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Programs" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>
              <ListItem button component={Link} to="/clients">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <PersonOutlineOutlinedIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Clients" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>
              <ListItem button component={Link} to="/groups">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <Groups2OutlinedIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Groups" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>
              <ListItem button component={Link} to="/activity">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <MonitorHeartOutlinedIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Activity" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>

              {/* Messages */}
              <ListItem button component={Link} to="/messages">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <Badge
                    badgeContent={open ? messagesCount : null}
                    color="error"
                    variant={open ? "standard" : "dot"}
                  >
                    <ChatOutlinedIcon />
                  </Badge>
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Messages" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>
              {/* Lessons */}
              <ListItem button component={Link} to="/lessons">
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <SchoolOutlined />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary="Client Resources"
                    sx={{ color: "#F2F3F5" }}
                  />
                )}
              </ListItem>
              {/* Marketing */}
              <ListItem
                button
                component={Link}
                to="https:///marketing.stridist.com"
                target="_blank"
              >
                <ListItemIcon
                  sx={{
                    color: "#767575",
                    justifyContent: open ? "flex-start" : "center",
                    minWidth: open ? "56px" : "100%",
                  }}
                >
                  <CampaignOutlinedIcon />
                </ListItemIcon>
                {open && (
                  <ListItemText primary="Marketing" sx={{ color: "#F2F3F5" }} />
                )}
              </ListItem>
            </List>
          </Box>
          <Box>
            <Divider />
            {/* Notifications go here */}
            <ListItem button onClick={handleOpenNotificationsDialog}>
              <ListItemIcon
                sx={{
                  color: "#767575",
                  justifyContent: open ? "flex-start" : "center",
                  minWidth: open ? "56px" : "100%",
                }}
              >
                <Badge
                  badgeContent={open ? notificationsCount : null}
                  color="error"
                  variant={open ? "standard" : "dot"}
                >
                  <NotificationsOutlinedIcon />
                </Badge>
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary="Notifications"
                  sx={{ color: "#F2F3F5" }}
                />
              )}
            </ListItem>
            <Divider />

            {/* User avatar and name go here */}
            <ListItem button onClick={handleClick}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: open ? "row" : "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Avatar alt="User Name" src={currentUser.photoUrl} />
                {open && (
                  <ListItemText
                    primary={currentUser.displayName || currentUser.email}
                    sx={{ color: "#F2F3F5", marginLeft: 1 }}
                  />
                )}
              </Box>
            </ListItem>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem component={Link} to="/settings">
                Settings
              </MenuItem>
              <MenuItem onClick={handleSignout}>
                Sign Out
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Drawer>
      <NotificationsDialog
        open={openNotificationsDialog}
        onClose={handleCloseNotificationsDialog}
        notifications={sampleNotifications}
      />    </Box>
  );
};

export default Sidebar;
