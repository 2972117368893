import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
    Button,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2';
import theme from '../theme/theme';

const GroupSettings = ({ title, groupSettings, apiUrl, groupId }) => {
    const [formData, setFormData] = useState({
        membersCanNotPost: false,
        membersCanNotDiscover: false,
        membersCanNotSeeSidebar: false,
        description: '',
        image: '',
        membersCanMessage: false,
    });


    const [canClickSave, setCanClickSave] = useState(false);

    useEffect(() => {
        // Fetch group settings from the API
        const fetchGroupSettings = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/GroupSettings/${groupId}`);
                const groupSettings = response.data;

                // Update formData state with the fetched group settings
                setFormData({
                    membersCanNotPost: groupSettings.membersCanNotPost || false,
                    membersCanNotDiscover: groupSettings.membersCanNotDiscover || false,
                    membersCanNotSeeSidebar: groupSettings.membersCanNotSeeSidebar || false,
                    description: groupSettings.description || '',
                    image: groupSettings.image || '',
                    membersCanMessage: groupSettings.membersCanMessage || false,
                });
            } catch (error) {
                // Handle error, e.g., show an error message
                console.error('Error fetching group settings:', error);
            }
        };

        fetchGroupSettings(); // Call the function when the component mounts
    }, [apiUrl, groupId]);


    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setCanClickSave(true);
    };

    const handleSwitchChange = (prop) => {
        setFormData({
            ...formData,
            [prop]: !formData[prop],
        });
        setCanClickSave(true);
    };

    const updateGroupSettings = async () => {
        setCanClickSave(false);

        const response = await axios.post(`${apiUrl}/api/GroupSettings`, {
            ...formData,
            GroupId: groupId,
        });

        if (response.status === 200) {
            // Success message using SweetAlert
            Swal.fire({
                title: 'Success',
                text: 'Group settings updated successfully!',
                icon: 'success',
            });
        } else {
            // Error message using SweetAlert
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while updating group settings.',
                icon: 'error',
            });
        }
    };

    return (
        <>
            <Typography variant="h3">{title}</Typography>
            <Card style={{ marginTop: '20px' }}>
                <CardContent>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={formData.membersCanNotPost} onChange={() => handleSwitchChange('membersCanNotPost')} />}
                            label="Members Can Not Post"
                        />
                        <FormControlLabel
                            control={<Switch checked={formData.membersCanNotDiscover} onChange={() => handleSwitchChange('membersCanNotDiscover')} />}
                            label="Members Can Not Discover"
                        />
                        <FormControlLabel
                            control={<Switch checked={formData.membersCanNotSeeSidebar} onChange={() => handleSwitchChange('membersCanNotSeeSidebar')} />}
                            label="Members Can Not See Sidebar"
                        />
                        <FormControlLabel
                            control={<Switch checked={formData.membersCanMessage} onChange={() => handleSwitchChange('membersCanMessage')} />}
                            label="Members Can Message"
                        />
                    </FormGroup>
                    <TextField
                        label="Description"
                        fullWidth
                        style={{ margin: '8px 0' }}
                        name="description"
                        value={formData.description}
                        onChange={handleFormChange}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            sx={{ color: 'primary.main', borderColor: 'white' }}
                        >
                            Upload Image
                            <input type="file" style={{ display: 'none' }} hidden />
                        </Button>
                    </Box>
                </CardContent>
                <div style={{ display: 'flex', justifyContent: 'end', padding: '1rem' }}>
                    <Button variant="contained" color="primary" disabled={!canClickSave} onClick={updateGroupSettings}>
                        Save
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default GroupSettings;
