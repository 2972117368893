import { Box, Typography } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const FormattedContent = ({ content, handleImageClick }) => {

    let parsedContent;
    try {
      parsedContent = JSON.parse(content);
    } catch (error) {
      parsedContent = [{ type: "message", children: [{ text: content }] }];
    }

    return parsedContent && parsedContent.map((item, index) => {
        if (item.type === "paragraph") {
          return (
            <Typography variant="body1" key={index}>
              {item.children.map((child, childIndex) => {
                if (child.type === "link") {
                  return (
                    <a href={child.url} key={childIndex} target="_blank" rel="noopener noreferrer">
                      {child.children[0].text}
                    </a>
                  );
                } else {
                  return child.text;
                }
              })}
            </Typography>
          );
        } else if (item.type === "message") {
            return (
              <Typography variant="body1" key={index}>
                {item.children.map((child, childIndex) => child.text)}
              </Typography>
            );
          }
          else if (item.type === "attachment") {
      if (item.mimeType === "image/jpeg") {
        return (
          <Box key={index}>
            <img 
              src={item.url} 
              alt={item.name} 
              style={{maxWidth: "100px", cursor: "pointer"}} 
              onClick={() => handleImageClick(item.url)}
            />
          </Box>
        );
      }   else if (item.mimeType === "application/pdf") {
          return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
             
              <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
              <PictureAsPdfIcon />
                {item.name}
              </a>
            </Box>
          );
        }
        else if (item.type === "gif") {
          const giphyUrl = `https://media.giphy.com/media/${item.giphyId}/giphy.gif`;
          return (
            <Box key={index}>
              <img src={giphyUrl} alt="Giphy gif" style={{maxWidth: "100%"}} />
            </Box>
          );
        }
      } else if (item.type === "video") {
        let embedUrl;
        if (item.url.includes("youtube.com")) {
          const videoId = new URL(item.url).searchParams.get('v');
          embedUrl = `https://www.youtube.com/embed/${videoId}`;
        } else if (item.url.includes("youtu.be")) {
          const parts = item.url.split('/');
          const videoId = parts[parts.length - 1];
          embedUrl = `https://www.youtube.com/embed/${videoId}`;
        } else if (item.url.includes("vimeo")) {
          const parts = item.url.split('/');
          const videoId = parts[parts.length - 1];
          embedUrl = `https://player.vimeo.com/video/${videoId}`;
        }
        return (
          <Box key={index}>
            <iframe src={embedUrl} title={item.name} />
          </Box>
        );
      } else if (item.type === "link") {
        return (
          <Box key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline', textDecorationStyle: 'dotted'}}>
              {item.children[0].text}
            </a>
          </Box>
        );
      }
      return null;
    })
  };

export default FormattedContent;