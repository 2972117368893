import React, { useEffect, useState } from "react"
import { useAppContext } from "../../libs/contextLib";
import WeekPlanner from "../planner/planner"
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader/loader";

const getProgram = async (apiUrl, programId) => {
    let resp = await axios.get(`${apiUrl}/api/program/${programId}`)
    return resp
}

const Program = () => {
    const { apiUrl } = useAppContext()
    const { id } = useParams();
    const [program, setProgram] = useState(null)

    useEffect(() => {
        async function getData() {
            let resp = await getProgram(apiUrl, id)
            console.log(resp.data);
            setProgram(resp.data);
        }
        if (apiUrl !== null) {
            getData();
        }
    }, [apiUrl]);

    if (program === null) {
        return <Loader />
    }

    return (
        <WeekPlanner
            title={program.name}
            weeksLength={program.weeksLength}
            programId={id}
            apiUrl={apiUrl}
        />
    )
}

export default Program