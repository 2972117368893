import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  TextField,
  Box,
  Select,
  MenuItem,
  Menu,
  Avatar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { formatDistanceToNow } from "date-fns";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useNavigate } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";
import theme from "../../components/theme/theme";

const ActiveClients = ({
  sort,
  handleSortChange,
  view,
  handleViewChange,
  clients,
}) => {
  const navigate = useNavigate();

  const handleClientClick = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [programId, setProgramId] = React.useState(null);
  const [filter, setFilter] = React.useState('');

  const handleMenuOpen = (event, id) => {
    setProgramId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Box sx={{ marginTop: 1, marginBottom: 1 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextField
              label="Search Clients"
              variant="outlined"
              style={{ background: "white" }}
              value={filter}
              onChange={(e) => { setFilter(e.target.value) }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography>Sort By:</Typography>
            <Select
              value={sort}
              onChange={handleSortChange}
              variant="outlined"
              style={{ background: "white" }}
            >
              <MenuItem value={"desc"}>Name</MenuItem>
              <MenuItem value={"desc"}>Due Date Programs</MenuItem>
              <MenuItem value={"desc"}>Date Added (Newest First)</MenuItem>
              <MenuItem value={"desc"}>Date Added (Oldest First)</MenuItem>
              <MenuItem value={"asc"}>Last Active (Newest First)</MenuItem>
              <MenuItem value={"desc"}>Last Active (Oldest First)</MenuItem>
            </Select>
            <ToggleButtonGroup
              color="primary"
              value={view}
              exclusive
              onChange={handleViewChange}
            >
              <ToggleButton value="grid">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <Typography sx={{ margin: "10px 0" }}>
          Showing {clients.length} Client(s){" "}
        </Typography>
      </Box>
      {/* Grid or List of Program Cards */}
      <Grid container spacing={2}>
        {clients
          .filter(item => item.displayName.toLowerCase().includes(filter.toLowerCase()))
          .map((c, index) => (
            <Grid
              item
              xs={12}
              sm={view === "grid" ? 6 : 12}
              md={view === "grid" ? 3 : 12}
              lg={view === "grid" ? 3 : 12}
            >
              <Card style={{ height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: view === "grid" ? "column" : "row",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Avatar
                    src={c.photoUrl}
                    alt="program image"
                    onClick={() => handleClientClick(c.clientId)}
                    sx={{
                      width: view === "grid" ? 120 : 60,
                      height: view === "grid" ? 120 : 60,
                      marginBottom: view === "grid" ? 0 : 0,
                      marginRight: view === "grid" ? 0 : 5,
                      marginLeft: view === "grid" ? 0 : 5,
                      marginTop: view === "grid" ? 5 : 0,
                    }}
                  />
                  <IconButton
                    aria-label="settings"
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={(event) => handleMenuOpen(event, index)}
                  >
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    id="program-card-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>Archive</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
                  </Menu>
                  <CardContent
                    sx={{
                      textAlign: view === "grid" ? "center" : "left",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: view === "grid" ? "center" : "flex-start",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      align={view === "grid" ? "center" : "left"}
                    >
                      {c.displayName}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align={view === "grid" ? "center" : "left"}
                      >
                        {`Added ${formatDistanceToNow(
                          new Date(c.createdAt+"Z")
                        )} ago`}
                      </Typography>
                    </Box>
                    {c.tags.map((tag, ti) => (
                      <Chip
                        label={tag.title}
                        variant="filled"
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#C2C2C2" : "#E7F3EC",
                          color: index % 2 === 0 ? "white" : "#4DA530",
                          borderRadius: 5,
                          margin: 1,
                          alignSelf: view === "grid" ? "center" : "flex-start",
                        }}
                      />
                    ))}
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default ActiveClients;
