import React, { useState, useContext } from "react";
import { AuthContext } from "../../auth/auth";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Delete, MoreVert, ArrowUpward, ArrowDownward, Add, FitnessCenter } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import settings from "../../appsettings";
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";
import Loader from "../../components/loader/loader";
import { Box, Card, InputLabel, Checkbox, Chip } from "@mui/material";

const Exercise = ({ exercise, handleAddExercise }) => {
  return (
    <Grid
      item
      xs={12}
      key={exercise.title}
      sx={{
        padding: 1,
        cursor: 'pointer',
        border: "1px solid #f5f5f5",
        margin: 1,
        borderRadius: "5px",
        '&:hover': {
          backgroundColor: '#f5f5f5',
        }
      }}
      onClick={() => handleAddExercise(exercise)}
    >
      <Typography variant="body1">{exercise.title}</Typography>
    </Grid>
  );
};

function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  let copy = obj.constructor();
  for (let attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = deepCopy(obj[attr]);
    }
  }

  return copy;
}

const Section = ({
  section,
  index,
  sections,
  setSections,
  setShowExercises,
  showExercises,
  setActiveSection,
  expanded,
  setExpanded,
}) => {
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, exerciseIndex) => {
    setAnchorEl(event.currentTarget);
    setCurrentExerciseIndex(exerciseIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (exerciseIndex) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].exercises = newSections[index].exercises.filter(
        (_, i) => i !== exerciseIndex
      );
      return newSections;
    });
    handleClose();
  };

  const handleSetTypeChange = (newSetType) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].exercises[currentExerciseIndex].setType = newSetType;
      return newSections;
    });
    handleClose();
  };

  const handleFieldValueChange = (newFieldValue) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].exercises[currentExerciseIndex].fieldValue = newFieldValue;
      return newSections;
    });
    handleClose();
  };

  const handleAddRow = (exerciseIndex) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].exercises[exerciseIndex].sets.push({});
      return newSections;
    });
  };

  const handleRemoveRow = (exerciseIndex) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].exercises[exerciseIndex].sets.pop();
      return newSections;
    });
  };

  const handleMoveExercise = (exerciseIndex, direction) => {
    const newSections = JSON.parse(JSON.stringify(sections));
    const tempExercise = newSections[index].exercises[exerciseIndex];
    newSections[index].exercises[exerciseIndex] = newSections[index].exercises[exerciseIndex + direction];
    newSections[index].exercises[exerciseIndex + direction] = tempExercise;
    setSections(newSections);
  };

  const handleMoveSection = (currentIndex, newIndex) => {
    setSections(prevSections => {
      // Create a deep copy of the sections array
      const newSections = deepCopy(prevSections);
      // Create a deep copy of the exercises array
      const newExercises = deepCopy(newSections[currentIndex].exercises);
      // Move the section
      const movedSection = newSections.splice(currentIndex, 1)[0];
      movedSection.exercises = newExercises;
      newSections.splice(newIndex, 0, movedSection);
      return newSections;
    });

    // Update the expanded state
    setExpanded(prevExpanded => {
      const newExpanded = [...prevExpanded];
      const movedExpanded = newExpanded.splice(currentIndex, 1)[0];
      newExpanded.splice(newIndex, 0, movedExpanded);
      return newExpanded;
    });
  };

  const handleAddExercise = (exercise) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index].exercises.push({
        video: exercise.video,
        title: exercise.title,
        description: exercise.description,
        setType: "Weight",
        fieldValue: "whole",
        sets: [{}],
      });
      return newSections;
    });
    setShowExercises(false);
    setActiveSection(index);
  };

  const handleDeleteSection = (sectionIndex) => {
    setSections((prevSections) => prevSections.filter((_, i) => i !== sectionIndex));
  };

  const isEmpty = section.exercises.length === 0;

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
      key={index} sx={{
        margin: "10px", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05)", borderRadius: "12px", border: "none", '&::before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: '10px',
          background: '#eeeeee',
        },
      }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <TextField
            value={section.title}
            onChange={(e) => {
              const newSections = [...sections];
              newSections[index].title = e.target.value;
              setSections(newSections);
            }}
            placeholder="Give Your Section a Title"
            variant="standard"
            onClick={(e) => e.stopPropagation()}
            InputProps={{ disableUnderline: true, style: { fontSize: '1.6em', fontWeight: '700' } }}
          />
          <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteSection(index); }} disabled={sections.length === 1}>
            <Delete />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        style={{
          borderRadius: "5px",
          padding: "10px",
          margin: "5px",
        }}
      >
        {isEmpty && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", maxWidth: "400px" }}>
            <Typography variant="body2">No exercises added yet</Typography>

          </Box>
        )}
        {section.exercises.map((exercise, exerciseIndex) => (
          <Card key={exercise.title} sx={{ padding: 2, margin: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 1 }}>
              <IconButton onClick={() => handleDelete(exerciseIndex)}>
                <Delete />
              </IconButton>
              <IconButton onClick={(e) => { e.stopPropagation(); handleClick(e, exerciseIndex); }}>
                <MoreVert />
              </IconButton>
            </Box>
            <Grid item xs={12} sx={{ textAlign: "left" }}>

              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Type of Sets</FormLabel>
                    <RadioGroup
                      value={exercise.setType}
                      onChange={(e) => handleSetTypeChange(e.target.value)}
                    >
                      <FormControlLabel
                        value="Distance"
                        control={<Radio />}
                        label="Distance"
                      />
                      <FormControlLabel
                        value="Time"
                        control={<Radio />}
                        label="Time"
                      />
                      <FormControlLabel
                        value="Weight"
                        control={<Radio />}
                        label="Weight"
                      />
                      <FormControlLabel
                        value="RPE"
                        control={<Radio />}
                        label="RPE"
                      />
                      <FormControlLabel
                        value="1RM"
                        control={<Radio />}
                        label="1RM"
                      />
                    </RadioGroup>
                  </FormControl>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Field Values</FormLabel>
                    <RadioGroup
                      value={exercise.fieldValue}
                      onChange={(e) => handleFieldValueChange(e.target.value)}
                    >
                      <FormControlLabel
                        value="whole"
                        control={<Radio />}
                        label="Whole Numbers"
                      />
                      <FormControlLabel
                        value="range"
                        control={<Radio />}
                        label="Range"
                      />
                    </RadioGroup>
                  </FormControl>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid container alignItems="center" spacing={5} padding={1}>
              <Grid item xs={8}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Grid item sx={{ padding: 2 }}>
                    <Typography variant="h4">{exercise.title}</Typography>
                    <Typography variant="body2">{exercise.description}
                    </Typography>
                  </Grid>
                </Box>


                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sets</TableCell>
                        <TableCell>Reps</TableCell>
                        <TableCell>{exercise.setType}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exercise.sets.map((set, setIndex) => (
                        <TableRow key={setIndex}>
                          <TableCell>
                            <Typography variant="body1">{setIndex + 1}</Typography>
                          </TableCell>
                          <TableCell>
                            {exercise.fieldValue === "range" ? (
                              <TextField
                                variant="standard"
                                placeholder="Number - Number"
                                value={set.reps}
                                onChange={(e) => {
                                  const newSections = deepCopy(sections);
                                  newSections[index].exercises[exerciseIndex].sets[setIndex].reps = e.target.value;
                                  setSections(newSections);
                                }}
                              />
                            ) : (
                              <TextField
                                variant="standard"
                                placeholder="1"
                                value={set.reps}
                                onChange={(e) => {
                                  const newSections = deepCopy(sections);
                                  newSections[index].exercises[exerciseIndex].sets[setIndex].reps = e.target.value;
                                  setSections(newSections);
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="standard"
                              value={set.weight}
                              onChange={(e) => {
                                const newSections = deepCopy(sections);
                                newSections[index].exercises[exerciseIndex].sets[setIndex].weight = e.target.value;
                                setSections(newSections);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <IconButton onClick={() => handleAddRow(exerciseIndex)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  <IconButton onClick={() => handleRemoveRow(exerciseIndex)} disabled={exercise.sets.length === 1}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </TableContainer>

              </Grid>
              <Grid item xs={4}>
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${exercise.video.split('/').pop()}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => handleMoveExercise(exerciseIndex, -1)} disabled={exerciseIndex === 0}>
                <ArrowUpward />
              </IconButton>
              <IconButton onClick={() => handleMoveExercise(exerciseIndex, 1)} disabled={exerciseIndex === sections[index].exercises.length - 1}>
                <ArrowDownward />
              </IconButton>
            </Box>
          </Card>
        ))}
      </AccordionDetails>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
        <IconButton
          variant="outlined"
          onClick={() => { setShowExercises(true); setActiveSection(index); }}
          sx={{
            background: "#EB5757",
            fontSize: "12px",
            color: "white",
            borderRadius: 2,
          }}
        >
          <Add />
          Add An Exercise
        </IconButton>
        <Box>
          <IconButton onClick={() => handleMoveSection(index, -1)} disabled={index === 0}>
            <ArrowUpward />
          </IconButton>
          <IconButton onClick={() => handleMoveSection(index, 1)} disabled={index === sections.length - 1}>
            <ArrowDownward />
          </IconButton>
        </Box>
      </Box>
    </Accordion>
  );
};

const WorkoutModal = ({ open, handleClose }) => {
  const { dbUser, coachId, apiUrl } = useAppContext();
  const [exercises, setExercises] = useState([]);
  const [showExercises, setShowExercises] = useState(false);
  const [sections, setSections] = useState([
    { title: "", exercises: [] },
  ]);
  const [videoFile, setVideoFile] = useState(null);
  const [sectionTitle, setSectionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [workoutTitle, setWorkoutTitle] = useState("");
  const [activeSection, setActiveSection] = useState(0);
  const [expandedSections, setExpandedSections] = useState(sections.map((_, index) => index === 0));
  const [addingExercise, setAddingExercise] = useState(false);
  const [addToLibrary, setAddToLibrary] = useState(false);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [cover, setCover] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Array(mediaFiles.length).fill(null));
  const isMenuOpen = Boolean(menuOpen);
  const [videoOption, setVideoOption] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [videoLink, setVideoLink] = useState("");


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddExercise = (exercise) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[activeSection].exercises.push({
        video: exercise.video,
        title: exercise.title,
        description: exercise.description,
        setType: "Weight",
        fieldValue: "whole",
        sets: [{}],
      });
      return newSections;
    });
    setShowExercises(false);
  };
  const addSection = () => {
    setSections([
      ...sections,
      { title: "", exercises: [] },
    ]);
  };

  const [searchValue, setSearchValue] = useState("");

  React.useEffect(() => {
    async function getData() {
      if (coachId === 0) return;
      let resp = await axios.get(`${apiUrl}/api/Movement/${coachId}`);
      console.log(resp.data);
      setExercises(
        resp.data.map((exercise) => ({
          video: exercise.video,
          title: exercise.title,
          description: exercise.description,
          sets: [],
        }))
      );
    }
    if (apiUrl !== null) {
      getData();
    }
  }, []);

  React.useEffect(() => {
    async function getData() {
      if (searchValue.length >= 3) {
        let resp = await axios.get(
          `${apiUrl}/api/Movement/search/${searchValue}`
        );
        console.log(resp.data);
        setExercises(
          resp.data.map((exercise) => ({
            video: exercise.video,
            title: exercise.title,
            description: exercise.description,
            sets: [],
          }))
        );
      }
    }
    if (apiUrl !== null) {
      getData();
    }
  }, [searchValue, apiUrl]);

  if (coachId === 0) {
    return <Loader />;
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          width: "90%",
          height: "90vh",
          overflowY: "hidden"
        },
      }}
    >
      <DialogContent>
        <Grid container sx={{ columnGap: 2, justifyContent: "space-between", overflow: "hidden", flexWrap: "nowrap" }}>
          {showExercises && (
            <Grid
              item
              sx={{
                paddingRight: "15px",
                maxHeight: "calc(90vh - 40px)",
                height: "100vh",
                overflowY: "auto",
                width: "300px",
                maxWidth: "300px",
                flexGrow: 1,
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05)"
              }}
            >
              {addingExercise ? (
                <Box sx={{ padding: 2 }}>
                  <Typography variant="h6">Create a New Exercise</Typography>
                  <br />
                  <InputLabel>Exercise Name</InputLabel>
                  <TextField
                    placeholder="ex. Alternating bodyweight lunge"
                    fullWidth
                  // Add onChange handler to update state
                  />
                  <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Add a video
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => { setVideoOption('link'); handleMenuClose(); }}>Embed link</MenuItem>
                    <MenuItem onClick={() => { setVideoOption('upload'); handleMenuClose(); }}>Upload</MenuItem>
                  </Menu>

                  {videoOption === 'link' && (
                    <TextField
                      label="Enter a video link"
                      value={videoLink}
                      onChange={(e) => setVideoLink(e.target.value)}
                    />
                  )}

                  {videoOption === 'upload' && (
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ marginTop: 2, marginBottom: 2, display: 'block', textAlign: 'center' }}
                    >
                      Upload a video
                      <input
                        type="file"
                        hidden
                        onChange={(e) => setVideoFile(e.target.files[0])}
                      />
                    </Button>
                  )}

                  {videoLink && (
                    <iframe
                      width="100%"
                      style={{ marginTop: "10px" }}
                      height="200"
                      src={videoLink}
                      title="Video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  )}

                  {videoFile && (
                    <video width="100%" height="200" controls style={{ marginTop: "10px" }}>
                      <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <InputLabel>Instructions</InputLabel>
                  <TextField
                    placeholder="ex. Make sure to brace your core"
                    fullWidth
                    multiline
                    rows={4}
                  // Add onChange handler to update state
                  />

                  <Button
                    variant="contained"
                    component="label"
                    sx={{ marginTop: 2, marginBottom: 2, display: 'block', textAlign: 'center' }}
                  >
                    Add Media
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => {
                        const newFiles = Array.from(e.target.files);
                        setMediaFiles(prevFiles => [...prevFiles, ...newFiles]);
                      }}
                    />
                  </Button>
                  {mediaFiles.map((file, index) => {
                    const isMenuOpen = Boolean(menuOpen[index]);

                    const handleClick = (event) => {
                      // Create a new array with the state for each menu
                      const newMenuOpen = [...menuOpen];
                      newMenuOpen[index] = event.currentTarget;
                      setMenuOpen(newMenuOpen);
                    };

                    const handleMenuClose = () => {
                      // Create a new array with the state for each menu
                      const newMenuOpen = [...menuOpen];
                      newMenuOpen[index] = null;
                      setMenuOpen(newMenuOpen);
                    };

                    return (
                      <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                        <Typography>{file.name}</Typography>
                        {cover === file && <Chip label="Cover" />}
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                        <Menu anchorEl={menuOpen[index]} open={isMenuOpen} onClose={handleMenuClose}>
                          <MenuItem onClick={() => {
                            if (cover === file) {
                              setCover(null);
                            } else {
                              setCover(file);
                            }
                            handleMenuClose();
                          }}>
                            {cover === file ? 'Remove as cover' : 'Set as cover'}
                          </MenuItem>
                          <MenuItem onClick={() => {
                            setMediaFiles(mediaFiles.filter((_, i) => i !== index));
                            if (cover === file) {
                              setCover(null);
                            }
                            handleMenuClose();
                          }}>Delete</MenuItem>
                        </Menu>
                      </Box>
                    );
                  })}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addToLibrary}
                        onChange={(e) => setAddToLibrary(e.target.checked)}
                      />
                    }
                    label="Add to exercise library"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button onClick={() => setAddingExercise(false)}>Back</Button>
                    <Box>
                      <Button color="secondary" onClick={() => setAddingExercise(false)}>Discard</Button>
                      <Button color="primary" variant="contained">Save</Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h6">Exercises</Typography>
                  <TextField
                    placeholder="Search exercises"
                    fullWidth
                    value={searchValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchValue(value);
                    }}
                  />

                  <IconButton
                    onClick={() => setAddingExercise(true)}
                    sx={{
                      background: "#EB5757",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: 2,
                      marginTop: 1,
                    }}
                  >
                    <Add />
                    Create a new Exercise
                  </IconButton>
                </Box>
              )}
              <Grid container >
                {exercises.map((exercise, index) => (
                  <Exercise
                    key={index}
                    exercise={exercise}
                    handleAddExercise={handleAddExercise}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          <Grid item sx={{ flexGrow: 1, maxHeight: "calc(90vh - 40px)", overflowY: "auto" }}>

            <Box sx={{ padding: 2, maxWidth: "80%" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FitnessCenter sx={{ fontSize: "large", color: "grey.500" }} />
                <Typography variant="body2" sx={{ color: "grey.500", margin: 0 }}>Workout</Typography>
              </Box>
              <TextField
                value={workoutTitle}
                onChange={(e) => setWorkoutTitle(e.target.value)}
                variant="standard"
                placeholder="Give Your Workout a Title"
                fullWidth
                InputProps={{ disableUnderline: true, style: { fontSize: '2.4em', fontWeight: '700' } }}
              />
            </Box>
            {sections.map((section, index) => (
              <Section
                key={index}
                section={section}
                index={index}
                sections={sections}
                setSections={setSections}
                setShowExercises={setShowExercises}
                showExercises={showExercises}
                setActiveSection={setActiveSection}
                expanded={expandedSections[index]}
                setExpanded={(expanded) => {
                  setExpandedSections((prevExpandedSections) => {
                    const newExpandedSections = [...prevExpandedSections];
                    newExpandedSections[index] = expanded;
                    return newExpandedSections;
                  });
                }}
              />
            ))}
            <br />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={addSection}>Add Section</Button>
              <Button>Save Workout</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WorkoutModal;