import React, { useState } from "react";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Box,
  IconButton,
  TextField,
  Card,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import GifIcon from "@mui/icons-material/Gif";
import Picker from 'emoji-picker-react';
import GifSearch from 'react-giphy-search-picker';
import Popover from '@mui/material/Popover';



const Activity = () => {

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [gifAnchorEl, setGifAnchorEl] = useState(null);
  const [showGiphySearch, setShowGiphySearch] = useState(false);

  const handleSelectGif = (item) => {
    // handle the selected gif here
    console.log(item.images.original.url);
    setShowGiphySearch(false);
  };

const handleSelectEmoji = (emojiObject) => {
// handle the selected emoji here
console.log(emojiObject.emoji);
setShowEmojiPicker(false);
};


  return (
    <Card sx={{ mb: 2, p: 0 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Avatar />
        <Box sx={{ marginLeft: "15px" }}>
          <h3 style={{ margin: 2 }}>User Name</h3>
          <p style={{ margin: 2 }}>A brief description</p>
          <p style={{ margin: 2, fontSize: 12 }}>
            {moment(Date.now()).format("HH:mm DD/MM/YYYY")}
          </p>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "15px",
        }}
      >
        <p>
          This is a block of text that contains varying information about the
          activity. It could contain details about the activity, comments made
          by the user, or any other relevant information.
        </p>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "sticky",
          bottom: 0,
          borderTop: "1px solid #ddd",
          padding: "15px",
          backgroundColor: "#D0D8DC",
        }}
      >

        <TextField
          fullWidth
          placeholder="Type a comment..."
          variant="outlined"
          sx={{
            flexGrow: 1,
            marginLeft: 2,
            marginRight: 2,
            background: "white",
            borderRadius: 2,
          }}
        />

        <IconButton>
          <AttachFileIcon />
        </IconButton>
        <IconButton onClick={(event) => {
  setShowEmojiPicker(!showEmojiPicker);
  setEmojiAnchorEl(event.currentTarget);
}}>
  <EmojiEmotionsIcon />
</IconButton>
<Popover
  open={showEmojiPicker}
  anchorEl={emojiAnchorEl}
  onClose={() => setShowEmojiPicker(false)}

>
  <Picker onEmojiClick={handleSelectEmoji} />
</Popover>

<IconButton onClick={(event) => {
  setShowGiphySearch(!showGiphySearch);
  setGifAnchorEl(event.currentTarget);
}}>
  <GifIcon />
</IconButton>
<Popover
  open={showGiphySearch}
  anchorEl={gifAnchorEl}
  onClose={() => setShowGiphySearch(false)}
  sx={{
    '.MuiPaper-root': {
      padding: 1,
      borderRadius: 2,
    },
  }}
>
<GifSearch
    apiKey="WFPkOXJmkguoG5ghLeHgfPLfoUD644hS"
    onSelect={(item) => {
      console.log(item.images.original.url);
      setShowGiphySearch(false);
    }}
    listWrapperStyles={{ overflowY: 'unset' }} // This will unset the overflowY property
  />
</Popover>
      </Box>
    </Card>
  );
};

const ActivityPage = () => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("asc");
  const [tab, setTab] = useState("active");

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      setTab(newTab);
    }
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="div" align="left">
            Client Activity
          </Typography>
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="active" style={{ flex: 1 }}>
              Active
            </ToggleButton>
            <ToggleButton value="archived" style={{ flex: 1 }}>
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      <div style={{ padding: "20px" }}>
        <Box>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography>Filter By:</Typography>
              <Select
                value={sort}
                onChange={handleSortChange}
                variant="outlined"
                sx={{ background: "white" }}
              >
                <MenuItem value={"asc"}>All Types</MenuItem>
                <MenuItem value={"desc"}>Meal logs</MenuItem>
              </Select>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography>Sort By:</Typography>
              <Select
                value={sort}
                onChange={handleSortChange}
                variant="outlined"
                sx={{ background: "white" }}
              >
                <MenuItem value={"asc"}>Date Posted</MenuItem>
                <MenuItem value={"desc"}>Date Scheduled</MenuItem>
              </Select>
            </div>
          </div>
          <br />
        </Box>
        <Box>
          <Activity />
          <Activity />
          <Activity />
        </Box>
      </div>
    </>
  );
};

export default ActivityPage;
