import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ProgressIndicator from "./ProgressIndicator";
import RegisterAccount from "../../components/signup/RegisterAccount";
import { useAppContext } from "../../libs/contextLib";
import YourBusiness from "../../components/signup/YourBusiness";
import WhatYouDo from "../../components/signup/WhatYouDo";
import ChooseAPlan from "../../components/signup/ChooseAPlan";
import firebaseConfig from "../../auth/config";
import { useNavigate } from 'react-router';

const SignUp = () => {  
  const navigate = useNavigate();
  const { dbUser } = useAppContext()
  const [step, setStep] = useState(dbUser.id ? 1 : 0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg')); // 'sm' is for screens larger than 600px


  const nextStep = () => {
    setStep(step + 1);
  };

  const handleSignout = async () => {
    await firebaseConfig.auth().signOut()
    navigate("/")
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", flexWrap: "wrap-reverse" }}>
      <Box
        sx={{
          background:
            "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
          padding: "2rem",
          color: "white",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%", // always full width
          flexBasis: matches ? "300px" : "100%", // 300px width on desktop, full width on mobile
        }}
      >
        <div style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          marginTop: "2rem",
          alignItems: "center",
        }}>
          <Typography variant="h2">
            Start your free trial of Stridist today
          </Typography>{" "}
          <br />
          <Typography variant="body1">
            Get full access to Stridist free for 14 days! Try our suite of
            coaching tools with no risk and no commitment. We won't charge you
            until after your free trial and you can cancel anytime
          </Typography>{" "}
          <br />
          <Typography variant="h6">What's included?</Typography>
          <br />
          <ul>
            <li>Unlimited training and nutrition programmes</li>
            <li>
              Marketing suite tools including unlimited landing pages and email
              automations
            </li>
            <li>Stridist resource library and templates</li>
            <li>Automate client onboarding, check-ins and communication</li>
            <li>Take payments and manage clients in one place</li>
          </ul>

          <button onClick={() => { handleSignout() }} >Sign Out</button>
        </div>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
          width: matches ? "calc(100% - 300px)" : "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "2rem",
            paddingTop: "5rem",


          }}
        >
          <ProgressIndicator step={step} />
          {step === 0 && (
            <RegisterAccount nextStep={nextStep} />
          )}
          {step === 1 && (
            <YourBusiness nextStep={nextStep} />
          )}
          {step === 2 && (
            <WhatYouDo nextStep={nextStep} />
          )}
          {step === 3 && (
            <ChooseAPlan nextStep={nextStep} />
          )}
          {step === 4 && <Typography variant="h6">Stripe Checkout</Typography>}
        </div>
      </Box>
    </Box>
  );
};

export default SignUp;
