import { createTheme } from "@mui/material/styles";
import '@fontsource/inter';


const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700, 
    },
    h4: {
      fontWeight: 700,
      fontSize: "40px",
    },
    h5: {
      fontWeight: 700, 
    },
    h6: {
      fontWeight: 700, 
    },
    body2: {
  fontSize: "18px",
  color: "#777",
    },
  },
  palette: {
    primary: {
      main: "#EB5757",
      background: "#eeeeee"
    },
    secondary: {
      main: "#FAFAFA",
    },
    text: {
      primary: "#333",
      secondary: "#777",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: 48,
          height: 48,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05)",
          borderRadius: "12px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "12px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: "none",
          textTransform: "none",
          borderRadius: 0,
          "&.Mui-selected": {
            borderBottom: "3px solid #EB5757",
            color: "#EB5757",
            background: "none",
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: "none",
          color: "black",
          fontWeight: "bold",
        },
      },
    },
  },
});

export default theme;
