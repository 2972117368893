import React from 'react';
import Button from '@mui/material/Button';

const AlreadySubscribedButton = () => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        position: 'absolute',
        top: '10px', // Adjust the top position as needed
        right: '10px', // Adjust the right position as needed
        zIndex: 1, // Make sure it's above other content
      }}
      href="/subscription-success"
    >
      Already have a subscription?
    </Button>
  );
};

export default AlreadySubscribedButton;
