import React, { useEffect, useState } from "react";
import {
    Box,
    IconButton,
} from "@mui/material";
import { EnrollDialog } from "../../components/enroll/enrollDialog";
import GroupIcon from "@mui/icons-material/Group";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import Swal from "sweetalert2";
import { useAppContext } from "../../libs/contextLib";


const getEnrollmentsForProgram = async (apiUrl, programId) => {
    let resp = await axios.get(`${apiUrl}/api/GetEnrollmentsForProgram/${programId}`)
    return resp
}

const EnrollmentsComponent = ({ programId }) => {
    const { apiUrl } = useAppContext()
    const [enrollDialogOpen, setEnrollDialogOpen] = useState(false);
    const [clients, setClients] = useState([])
    const [trigger, setTrigger] = useState(true);

    useEffect(() => {
        async function getData() {
            let resp = await getEnrollmentsForProgram(apiUrl, programId)
            setClients(resp.data)
            console.log(resp.data)
        }
        if (apiUrl !== null) {
            getData()
        }
    }, [apiUrl, trigger])


    const handleReloadTrigger = () => {
        setTrigger(!trigger)
    }

    return (

        <Box sx={{ display: "flex", alignItems: "center" }}>

            <AvatarGroup max={4}>

                {clients.filter(i => i.enrolled).map((c, index) => (
                    <Avatar
                        alt={c.displayName}
                        src={c.photoUrl}
                    />
                ))}

            </AvatarGroup>
            <IconButton
                sx={{
                    background: "#EB5757",
                    fontSize: "12px",
                    color: "white",
                    borderRadius: 2,
                }}
                onClick={() => setEnrollDialogOpen(true)}
            >
                <GroupIcon />
                Enroll Clients
            </IconButton>
            <EnrollDialog
                open={enrollDialogOpen}
                onClose={() => setEnrollDialogOpen(false)}
                clients={clients}
                fullWidth
                maxWidth="lg"
                apiUrl={apiUrl}
                programId={programId}
                handleReloadTrigger={handleReloadTrigger}
            />
        </Box>
    )
}

export default EnrollmentsComponent