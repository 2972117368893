import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

const ClientNotes = ({ onSubmit }) => {
  const [note, setNote] = useState('');

  const handleSubmit = () => {
    onSubmit(note);
    setNote(''); // clear the note
  };

  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
      <ReactQuill 
        value={note} 
        onChange={setNote} 
        style={{ height: "auto", flexGrow: 1, margin: 0, padding: 0 }} // adjust this as needed
      />
      <button onClick={handleSubmit} style={{ margin: 0, padding: 0 }}>Submit</button>
    </div>
  );
}

export default ClientNotes;