import React from "react";
import {
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import axios from "axios";

const duplicateProgram = async (apiUrl, programId) => {
    let resp = await axios.post(`${apiUrl}/api/DuplicateProgram/`, { ProgramId: programId })
    return resp
}
const rePublishProgram = async (apiUrl, programId) => {
    let resp = await axios.post(`${apiUrl}/api/RePublishProgram/`, { ProgramId: programId })
    return resp
}
const archiveOrDeleteProgram = async (apiUrl, programId) => {
    // active -> archive -> delete
    let resp = await axios.delete(`${apiUrl}/api/Program/${programId}`)
    return resp
}

const ProgramCardMenu = ({ programId, status, apiUrl, reloadTrigger }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDuplicateProgram = async () => {
        try {
            let resp = await duplicateProgram(apiUrl, programId)
            reloadTrigger()
            handleMenuClose()
        } catch (error) {
            console.log("error", error)
            if (error.response.data) {
                alert(error.response.data)
            }
            if (error.message) {
                alert(error.message)
            }
        }
    }
    const handleRePublishProgram = async () => {
        try {
            let resp = await rePublishProgram(apiUrl, programId)
            reloadTrigger()
            handleMenuClose()
        } catch (error) {
            console.log("error", error)
            if (error.response.data) {
                alert(error.response.data)
            }
            if (error.message) {
                alert(error.message)
            }
        }
    }
    const handleArchiveOrDeleteProgram = async () => {
        try {
            let resp = await archiveOrDeleteProgram(apiUrl, programId)
            reloadTrigger()
            handleMenuClose()
        } catch (error) {
            console.log("error", error)
            if (error.response.data) {
                alert(error.response.data)
            }
            if (error.message) {
                alert(error.message)
            }
        }
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="settings"
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={(event) => handleMenuOpen(event)}
            >
                <MoreHoriz />
            </IconButton>

            <Menu
                id="program-card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleDuplicateProgram()}>Duplicate</MenuItem>

                {status === "ARCHIVED"
                    ?
                    <MenuItem onClick={() => handleRePublishProgram()}>Re-Publish</MenuItem>
                    :
                    <MenuItem onClick={() => handleArchiveOrDeleteProgram()}>Delete</MenuItem>
                }

            </Menu>
        </>
    )
}

export default ProgramCardMenu