import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const ProgressIndicator = ({ step }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {["You", "Your Business", "What do you do?", "Plan", "Checkout"].map(
        (title, index) => (
          <Box
            display="flex"
            alignItems="center"
            key={index}
            marginBottom="20px"
          >
            <Box
              width={20}
              height={20}
              borderRadius="50%"
              bgcolor={step >= index ? "primary.main" : "grey.300"}
              color="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={1}
            >
              <Typography>{index + 1}</Typography>
            </Box>
            {(!isSmallScreen || step === index) && <Typography variant="body2" sx={{fontSize: "14px"}}>{title}</Typography>}
            {index < 4 && (
              <Box
                flexGrow={1}
                height={2}
                bgcolor={step > index ? "primary.main" : "grey.300"}
                mx={2}
              />
            )}
          </Box>
        )
      )}
    </Box>
  );
};

export default ProgressIndicator;