import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import theme from "../../components/theme/theme";
import {
  Avatar,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GifIcon from "@mui/icons-material/Gif";

import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import MicIcon from "@mui/icons-material/Mic";
import { formatDistanceToNow } from "date-fns";
import Picker from 'emoji-picker-react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import GifSearch from 'react-giphy-search-picker';
import Popover from '@mui/material/Popover';
import FormattedContent from "./FormattedPost";
import axios from "axios";



const GroupPost = ({ posts, apiUrl, trigger }) => {
  console.log(posts)
  const [showGiphySearch, setShowGiphySearch] = useState(false);

  const handleSelectGif = (item) => {
    // handle the selected gif here
    console.log(item.images.original.url);
    setShowGiphySearch(false);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [gifAnchorEl, setGifAnchorEl] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSelectEmoji = (emojiObject) => {
    // handle the selected emoji here
    console.log(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setOpenImageDialog(true);
  };

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };


  const handleEditPost = () => {
    // TODO
  };

  const handleDeletePost = () => {
    // setPosts((prevPosts) => prevPosts.filter((post, i) => i !== menuIndex));
    handleCloseMenu();
  };



  const handleReply = async (index, reply) => {

    const response = await axios.post(`${apiUrl}/api/AddCommentToGroupPost`, {
      GroupPostId: posts[index].id,
      Content: reply
    });

    if (response.status === 200) {
      if (trigger) {
        trigger()
      }
    }

  };




  return (
    <>
      {
        posts.map((post, i) => (
          <Card
            key={i}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: 2,
              marginTop: 2,

            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Avatar src={post.user.avatar} />
              <Box sx={{ marginLeft: 1 }}>
                <Typography variant="subtitle1">{post.user.name}</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {`Added ${formatDistanceToNow(
                    new Date(post.timestamp + "Z")
                  )} ago`}
                </Typography>
              </Box>

              <IconButton
                size="small"
                onClick={(e) => handleOpenMenu(e, i)}
                sx={{ marginLeft: "auto" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>

            <Typography variant="h6" sx={{ marginTop: 1 }}>
              {post.title}
            </Typography>
            <FormattedContent content={post.content} handleImageClick={handleImageClick} />
            <Dialog
              open={openImageDialog}
              onClose={() => setOpenImageDialog(false)}
              maxWidth="md"
              fullWidth
            >
              <img src={selectedImage} alt="Full size" style={{ width: "100%" }} />
            </Dialog>

            {
              post.replies && post.replies.map((reply, j) => (
                <Box key={j} sx={{ display: "flex", flexDirection: "column", borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: 2 }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle2">{reply.user}</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {`Added ${formatDistanceToNow(
                        new Date(reply.timestamp + "Z")
                      )} ago`}
                    </Typography>
                  </Grid>
                  <FormattedContent content={reply.content} handleImageClick={handleImageClick} />
                  <Divider />
                </Box>
              ))
            }

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 2,
                width: "100%",
              }}
            >
              <TextField
                label="Write a reply..."
                fullWidth
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleReply(i, e.target.value);
                    e.target.value = "";
                    e.preventDefault();
                  }
                }}
              />

              <IconButton onClick={(event) => {
                setShowEmojiPicker(!showEmojiPicker);
                setEmojiAnchorEl(event.currentTarget);
              }}>
                <EmojiEmotionsIcon />
              </IconButton>
              <Popover
                open={showEmojiPicker}
                anchorEl={emojiAnchorEl}
                onClose={() => setShowEmojiPicker(false)}
              >
                <Picker onEmojiClick={handleSelectEmoji} />
              </Popover>

              <IconButton onClick={(event) => {
                setShowGiphySearch(!showGiphySearch);
                setGifAnchorEl(event.currentTarget);
              }}>
                <GifIcon />
              </IconButton>
              <Popover
                open={showGiphySearch}
                anchorEl={gifAnchorEl}
                onClose={() => setShowGiphySearch(false)}
              >
                <GifSearch
                  apiKey="WFPkOXJmkguoG5ghLeHgfPLfoUD644hS"
                  onSelect={(item) => {
                    console.log(item.images.original.url);
                    setShowGiphySearch(false);
                  }}
                />
              </Popover>
              <IconButton>
                <MicIcon />
              </IconButton>
            </Box>
          </Card>
        ))
      }



      {/* Post Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEditPost}>Edit</MenuItem>
        <MenuItem onClick={handleDeletePost}>Delete</MenuItem>
      </Menu>
    </>
  )
}

export default GroupPost