import { MoreHoriz } from "@mui/icons-material"
import { Card, Grid, Box, CardMedia, IconButton, Menu, CardContent, Typography, AvatarGroup, Avatar, MenuItem } from "@mui/material"
import axios from "axios";
import React from "react"
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

const archiveOrDeleteGroup = async (apiUrl, groupId) => {
    // active -> archive -> delete
    let resp = await axios.delete(`${apiUrl}/api/Group/${groupId}`)
    return resp
}

const GroupItem = ({ groups, sort, handleSortChange, view, handleViewChange, reloadTrigger }) => {

    const { apiUrl } = useAppContext()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [groupId, setGroupId] = React.useState(null);
    const navigate = useNavigate();


    const handleClick = (event, id) => {
        navigate(`/group/${id}`);
        handleMenuClose();
    };

    const handleMenuOpen = (event, id) => {
        setGroupId(id);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleArchiveOrDeleteGroup = async () => {
        try {
            let resp = await archiveOrDeleteGroup(apiUrl, groupId)
            if (reloadTrigger) {
                // hook from parent 
                reloadTrigger()
            }
            handleMenuClose()
        } catch (error) {
            console.log("error", error)
            if (error.response.data) {
                alert(error.response.data)
            }
            if (error.message) {
                alert(error.message)
            }
        }
    }


    return (
        <Grid container spacing={2}>
            {groups.map((group, index) => (
                <Grid
                    item
                    xs={12}
                    sm={view === "grid" ? 6 : 12}
                    md={view === "grid" ? 3 : 12}
                    lg={view === "grid" ? 3 : 12}
                >
                    <Card sx={{ height: '100%' }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: view === "grid" ? "column" : "row",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <CardMedia

                                component="img"
                                height="140"
                                image="https://images.unsplash.com/photo-1580086319619-3ed498161c77?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2669&q=80"
                                alt="program image"
                                style={{ width: view === "grid" ? "100%" : "200px" }}
                                onClick={(event) => handleClick(event, group.groupId)}
                            />
                            <IconButton
                                aria-label="settings"
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={(event) => handleMenuOpen(event, group.groupId)}
                            >
                                <MoreHoriz />
                            </IconButton>
                            <Menu
                                id="program-card-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleArchiveOrDeleteGroup}> {group.archived ? "Delete" : "Archive"} </MenuItem>
                            </Menu>
                            <CardContent
                                sx={{
                                    textAlign: "left",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "70%",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: view === "grid" ? "column" : "row",
                                        gap: "10px",
                                        width: "100%",
                                    }}
                                >
                                    <Typography variant="h5" component="div" align="left">
                                        {group.name}
                                    </Typography>
                                    <AvatarGroup max={4} sx={{ justifyContent: "flex-end" }}>
                                        {group.users.map((user) => (
                                            <Avatar
                                                key={user.userId}
                                                alt={user.displayName}
                                                src={
                                                    user.photoUrl
                                                }
                                            />
                                        ))}
                                    </AvatarGroup>
                                </Box>
                                <Box sx={{ display: "flex", gap: "10px" }}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        align="left"
                                    >
                                        {group.users.length} Members
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>

    )
}

export default GroupItem