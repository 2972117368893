import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Logo from "../../images/logofull.svg";
import { useTheme } from "@mui/material/styles";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const passwordSubmit = (e) => {};

  const inputWidth = "50%";
  const inputHeight = "50px";
  const theme = useTheme();
  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.background,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Link to="/">
        <img src={Logo} alt="Logo" style={{ marginBottom: "2rem" }} />
      </Link>
      <Typography variant="h4">Forgot your password?</Typography>
      <br />
      <form onSubmit={passwordSubmit} style={{ width: inputWidth }}>
        <TextField
          label="email"
          variant="outlined"
          name="email"
          type="email"
          fullWidth
          required
          style={{
            marginBottom: "1rem",
            height: inputHeight,
            background: "white",
          }}
          value={email}
          onChange={handleEmailChange}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={!email}
          fullWidth
          style={{
            height: inputHeight,
            fontWeight: "bold",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          Login
        </Button>
      </form>
      <Typography
        variant="body2"
        style={{
          marginTop: "2rem",
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >
        Don’t have an account? <Link to="/signup">Sign Up as Coach</Link>
      </Typography>
    </div>
  );
};

export default ForgotPassword;
