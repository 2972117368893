import React, { useState, useContext } from "react";
import { CssBaseline } from "@mui/material";
import Sidebar from "./components/navBar/navbar";
import ProgramPage from "./pages/programs/programs";
import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AllClients from "./pages/clients/clients";
import GroupsPage from "./pages/groups/groups";
import MessagesPage from "./pages/messages/messages";
import ActivityPage from "./pages/activity/activity";
import WysiwygEditor from "./components/editor/editor";
import ClientPage from "./pages/clients/client";
import theme from "./components/theme/theme";
import GroupPage from "./pages/groups/group";
import { AuthContext } from "./auth/auth";
import Login from "./pages/auth/Login";
import axios from "axios";
import firebaseConfig from "./auth/config";
import ForgotPassword from "./pages/auth/ForgotPassword";
import SignUp from "./pages/auth/Signup";
import SettingsPage from "./pages/settings/settings";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AppContext } from './libs/contextLib';
import settings from './appsettings'
import Program from "./pages/programs/program";
import Loader from "./components/loader/loader";
import SubscriptionSuccess from "./components/signup/SubscriptionSuccess";


const drawerWidth = 200;
const closedDrawerWidth = 60;


// Create a function to get the user token
const getUserToken = async () => {
  const user = firebaseConfig.auth().currentUser;
  if (user) {
    console.log(user)
    return await user.getIdToken();
  }
  return null; // Return null if the user is not authenticated
};

// Add an interceptor to set the Authorization header
axios.interceptors.request.use(
  async (config) => {
    const token = await getUserToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const App = () => {
  const [open, setOpen] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [dbUser, setDbUser] = useState({})
  const [coachId, setCoachId] = useState(-1)
  const [hasPlan, setHasPlan] = useState(false)
  const [apiUrl, setApiUrl] = useState(null)

  console.log("hasPlan", hasPlan)
  console.log("coachId", coachId)
  console.log("path", window.location.pathname);


  React.useEffect(() => {
    async function getData() {
      let resp = await axios.get(`${apiUrl}/api/WhoAmI`);
      setDbUser(resp.data)
      if (resp.data.coachAccounts && resp.data.coachAccounts.length === 1) {
        setCoachId(resp.data.coachAccounts[0].coachId)
        setHasPlan(resp.data.coachAccounts[0].hasPlan)
      }
      else {
        setCoachId(0)
      }
      console.log(resp.data)
    }
    if (apiUrl !== null && currentUser) {
      getData();
    }
  }, [currentUser, apiUrl]);

  React.useEffect(() => {
    async function getApiUrl() {
      let url = settings.apiUrl()
      if (url === "https://ccsmart.co.uk/getStridistApiUrl.php") {
        const response = await fetch("https://ccsmart.co.uk/getStridistApiUrl.php", {
          method: "GET",
        });
        // console.log(response)
        const responseData = await response.json(); // Assuming the response is JSON
        // console.log(responseData); // Use the response data as needed
        if (response.ok) {
          setApiUrl(responseData.apiUrl)
        }
      }
      else {
        setApiUrl(url)
      }
    }
    getApiUrl();
  }, []);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (currentUser && coachId === -1) {
    return (
      <Loader />
    )
  }

  if (currentUser === null) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContext.Provider value={{ dbUser, coachId, setCoachId, apiUrl }}>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Router>
        </AppContext.Provider>
      </ThemeProvider>
    )
  }
  
  if (currentUser && (coachId === 0 || !hasPlan)) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContext.Provider value={{ dbUser, coachId, setCoachId, apiUrl }}>
          <Router>
            <Routes>
              <Route path="/subscription-success" element={<SubscriptionSuccess />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="*" element={<Navigate to="/signup" />} />
            </Routes>
          </Router>
        </AppContext.Provider>
      </ThemeProvider>
    )
  }
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContext.Provider value={{ dbUser, coachId, setCoachId, apiUrl }}>
        <Router>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: open
                ? `${drawerWidth}px auto`
                : `${closedDrawerWidth}px auto`,
              height: "100vh",
            }}
          >
            <Sidebar
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              currentUser={dbUser}
            />
            <main style={{ flexGrow: 1, padding: "0px", background: theme.palette.primary.background }}>
              <DndProvider backend={HTML5Backend}>
                <Routes>
                  {/* <Route path="/subscription-success" element={<SubscriptionSuccess />} /> */}
                  <Route path="*" element={<Navigate to="/programs" />} />
                  <Route path="/programs" element={<ProgramPage />} />
                  <Route path="/program/:id" element={<Program />} />
                  <Route path="/clients" element={<AllClients />} />
                  <Route path="/client/:id" element={<ClientPage />} />
                  <Route path="/groups" element={<GroupsPage />} />
                  <Route path="/group/:id" element={<GroupPage />} />
                  <Route path="/messages" element={<MessagesPage />} />
                  <Route path="/activity" element={<ActivityPage />} />
                  <Route path="/editor" element={<WysiwygEditor />} />
                  <Route path="/settings" element={<SettingsPage />} />
                </Routes>
              </DndProvider>
            </main>
          </div>

        </Router>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
