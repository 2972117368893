import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Editor from "../../components/editor/editor";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  ToggleButtonGroup,
  ToggleButton
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Add from "@mui/icons-material/Add";
import { useDrop } from "react-dnd";
import PlannerItem from "./plannerItem";
import WorkoutModal from "./WorkoutModal";
import axios from "axios";
import Loader from "../../components/loader/loader";
import Swal from "sweetalert2";
import EnrollmentsComponent from "../../components/programs/EnrollmentsComponent";


const getProgramPlannerView = async (apiUrl, programId) => {
  let resp = await axios.get(`${apiUrl}/api/ProgramPlannerView/${programId}`)
  return resp
}
const addWeek = async (apiUrl, programId) => {
  let resp = await axios.post(`${apiUrl}/api/AddWeek/`, { ProgramId: programId })
  return resp
}
const duplicateWeek = async (apiUrl, weekId) => {
  let resp = await axios.post(`${apiUrl}/api/DuplicateWeek/`, { WeekId: weekId })
  return resp
}

const WeekPlanner = ({ title, weeksLength, programId, apiUrl }) => {
  const [weeks, setWeeks] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [selectedWeeksCount, setSelectedWeeksCount] = useState(0);

  useEffect(() => {
    async function getData() {
      let resp = await getProgramPlannerView(apiUrl, programId)
      setWeeks(resp.data.weeks)
      console.log(resp.data)
    }
    getData()
  }, [trigger])
  useEffect(() => {
    if (weeks.length > 0) {
      setExpanded(weeks.map(x => x.id))
    }
  }, [weeks])

  const handleAddWeek = async () => {
    let resp = await addWeek(apiUrl, programId);
    if (resp.status === 200) {
      handleReloadTrigger()
      Swal.fire({
        title: 'Success',
        text: 'Week added',
        icon: 'success',
      })
    }
    else {
      alert("error adding week")
    }
  }


  const handleReloadTrigger = () => {
    setTrigger(!trigger)
  }

  const handleAccordionChange = (weekId) => (event, newExpanded) => {
    if (newExpanded) {
      setExpanded((prev) => [...prev, weekId]);
    } else {
      setExpanded((prev) => prev.filter((id) => id !== weekId));
    }
  };

  const toggleSelection = (weekId) => {
    setWeeks((prevWeeks) => {
      const newWeeks = prevWeeks.map((week) =>
        week.id === weekId ? { ...week, selected: !week.selected } : week
      );

      // Calculate the new count of selected weeks
      const newSelectedWeeksCount = newWeeks.filter(
        (week) => week.selected
      ).length;
      setSelectedWeeksCount(newSelectedWeeksCount);

      return newWeeks;
    });
  };

  const duplicateSelectedWeeks = () => {
    alert("todo")
    const newWeeks = weeks.flatMap((week, index) => {
      if (week.selected) {
        const newWeek = JSON.parse(JSON.stringify(week));
        newWeek.id = `${Date.now()} - ${Math.random()}`;
        newWeek.selected = false;
        return [week, newWeek];
      } else {
        return week;
      }
    });

    newWeeks.forEach((week) => {
      week.selected = false;
    });

    setWeeks(newWeeks);

    // Reset the selectedWeeksCount
    setSelectedWeeksCount(0);
  };
  const deleteSelectedWeeks = () => {
    alert("todo")
    const newWeeks = weeks.filter((week) => !week.selected);
    const newExpanded = expanded.filter((id) =>
      newWeeks.find((week) => week.id === id)
    );

    setWeeks(newWeeks);
    setExpanded(newExpanded);

    // Reset the selectedWeeksCount
    setSelectedWeeksCount(0);
  };

  const addEventToDay = (weekIndex, dayIndex, eventType) => {
    setWeeks((prevWeeks) => {
      const newWeeks = JSON.parse(JSON.stringify(prevWeeks)); // Make a deep copy of the weeks
      let content = "";
      switch (eventType) {
        case "Workout":
          content =
            "3 x 10 squats 100kg, 3 x 10 deadlifts 10 rpe, 5 x 5 pullups";
          break;
        case "Habit":
          content = "Drink water";
          break;
        case "Lesson":
          content = "Nutrition essentials";
          break;
        case "Message":
          content = "How are you today?";
          break;
        case "Check-in":
          content = "Bodyweight?";
          break;
        default:
          break;
      }
      const event = { id: Date.now(), type: eventType, content: content }; // Each event has a unique id based on the current timestamp
      newWeeks[weekIndex].days[dayIndex].push(event); // Add the event to the appropriate day
      return newWeeks;
    });
  };

  const moveEvent = (
    sourceWeekIndex,
    sourceDayIndex,
    targetWeekIndex,
    targetDayIndex,
    event
  ) => {
    setWeeks((prevWeeks) => {
      const newWeeks = JSON.parse(JSON.stringify(prevWeeks)); // Make a deep copy of the weeks

      // Find and remove the event from the source day
      const sourceDayEvents = newWeeks[sourceWeekIndex].days[sourceDayIndex];
      const eventIndex = sourceDayEvents.components.findIndex((e) => e.id === event.id);
      if (eventIndex > -1) {
        sourceDayEvents.components.splice(eventIndex, 1);
      }

      // If target week and day are not null, add the event to the target day
      if (targetWeekIndex !== null && targetDayIndex !== null) {
        newWeeks[targetWeekIndex].days[targetDayIndex].components.push(event);
      }

      return newWeeks;
    });
  };

  const handleDuplicateWeek = async (weekId) => {
    let resp = await duplicateWeek(apiUrl, weekId);
    if (resp.status === 200) {
      handleReloadTrigger()
      Swal.fire({
        title: 'Success',
        text: 'Week added',
        icon: 'success',
      })
    }
    else {
      alert("error adding week")
    }
  };

  const deleteWeek = (weekIndex) => {
    setWeeks(weeks.filter((_, wi) => wi !== weekIndex));
  };

  const handleEditorSave = (content) => {
    // ToDO
  };

  const [editorOpen, setEditorOpen] = useState(false);
  const [editorType, setEditorType] = useState(null);
  const [tab, setTab] = useState("curriculum");

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      setTab(newTab);
    }
  };

  if (weeks.length === 0) {
    return <Loader />
  }



  return (
    <div>
      {editorOpen && (
        <Dialog
          open={editorOpen}
          onClose={() => setEditorOpen(false)}
          fullWidth
          maxWidth="xl"
          PaperProps={{
            style: {
              width: "90%",
              height: "90%",
            },
          }}
        >
          <Editor
            type={editorType}
            onClose={() => setEditorOpen(false)}
            onSave={handleEditorSave}
          />
        </Dialog>
      )}
       <div
        style={{
          backgroundColor: "white",
          padding: "20px 20px 0px 20px",
          borderBottom: "2px solid #EAE9E9",
        }}
      >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <Button variant="outlined">Edit Info</Button>
        </Box>

        <EnrollmentsComponent programId={programId} />

      </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ToggleButton value="curriculum" style={{ flex: 1 }}>
              Curriculum
            </ToggleButton>
            <ToggleButton value="enrollments" style={{ flex: 1 }}>
              Enrollments
            </ToggleButton>
            <ToggleButton value="settings" style={{ flex: 1 }}>
              Settings
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        </div>
           {tab === "curriculum" && ( 
            <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
          padding: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="primary"
            onClick={duplicateSelectedWeeks}
            disabled={selectedWeeksCount === 0}
            sx={{ marginLeft: 2, color: "grey", borderRadius: "4px" }}
          >
            <FileCopyIcon />
            {selectedWeeksCount > 0 && (
              <Typography sx={{ marginLeft: 1 }}>
                Duplicate Selected Weeks
              </Typography>
            )}
          </IconButton>
          <IconButton
            color="secondary"
            onClick={deleteSelectedWeeks}
            disabled={selectedWeeksCount === 0}
            sx={{ marginLeft: 2, color: "grey", borderRadius: "4px" }}
          >
            <DeleteIcon />
            {selectedWeeksCount > 0 && (
              <Typography sx={{ marginLeft: 1 }}>
                Delete Selected Weeks
              </Typography>
            )}
          </IconButton>
        </Box>
        <IconButton
          variant="outlined"
          onClick={handleAddWeek}
          sx={{
            background: "#EB5757",
            fontSize: "12px",
            color: "white",
            borderRadius: 2,
          }}
        >
          <Add />
          Add Week
        </IconButton>
      </Box>

      <Box sx={{ flexGrow: 1, padding: "20px" }}>
        {weeks.map((week, weekIndex) => (
          <Box key={week.id} sx={{ marginBottom: 2 }}>
            <Accordion
              expanded={expanded.includes(week.id)}
              onChange={handleAccordionChange(week.id)}
              key={week.id}
            >
              <AccordionSummary
                // Prevent click propagation on the AccordionSummary to avoid accordion open/close when clicking on the buttons
                onClick={(event) => event.stopPropagation()}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%", // Make sure to fill the entire width
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={week.selected}
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleSelection(week.id);
                      }}
                    />

                    <Typography variant="h6">Week {weekIndex + 1}</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      color="black"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDuplicateWeek(week.id);
                      }}
                      sx={{
                        fontSize: "15px",
                        border: "1px solid grey",
                        borderRadius: "4px",
                        marginRight: "5px",
                      }}
                    >
                      <FileCopyIcon /> Duplicate
                    </IconButton>
                    <IconButton
                      color="black"
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteWeek(weekIndex);
                      }}
                      sx={{
                        fontSize: "15px",
                        border: "1px solid grey",
                        borderRadius: "4px",
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  {week.days.map((dayEvents, dayIndex) => (
                    <Grid key={dayIndex} item xs>
                      <DropTarget
                        weekIndex={weekIndex}
                        dayIndex={dayIndex}
                        dayEvents={dayEvents.components}
                        onAddEvent={addEventToDay}
                        onMoveEvent={moveEvent}
                        handleEditorSave={handleEditorSave}
                        setEditorOpen={setEditorOpen}
                        editorType={editorType}
                        setEditorType={setEditorType}
                        handleReloadTrigger={handleReloadTrigger}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
      </>
      )}
    </div>
  );
};

const DropTarget = ({
  weekIndex,
  dayIndex,
  dayEvents,
  onAddEvent,
  onMoveEvent,
  handleEditorSave,
  setEditorOpen,
  editorType,
  setEditorType,
  handleReloadTrigger
}) => {
  const navigate = useNavigate();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "event",
    drop: (item) => {
      onMoveEvent(
        item.weekIndex,
        item.dayIndex,
        weekIndex,
        dayIndex,
        item.event
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const [workoutModalOpen, setWorkoutModalOpen] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (eventType) => {
    if (eventType === "Workout") {
      setSelectedWorkout(eventType);
      setWorkoutModalOpen(true);
    } else if (eventType === "Check-in") {
      setEditorType("Check-in");
      setEditorOpen(true);
    } else if (eventType === "Habit") {
      setEditorType("Habit");
      setEditorOpen(true);
    } else if (eventType === "Message") {
      setEditorType("Message");
      setEditorOpen(true);
    }
    else if (eventType === "Lesson") {
      setEditorType("Lesson");
      setEditorOpen(true);
    }
    else {
      onAddEvent(weekIndex, dayIndex, eventType);
    }
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div
      ref={drop}
      style={{
        padding: 8,
        backgroundColor: isOver ? "#cccccc" : "#ffffff",
        boxShadow: "0px 1px 4px rgba(16, 24, 40, 0.07)",
        borderRadius: 0,
        border: "1px solid #EAE9E9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="button">Day {dayIndex + 1}</Typography>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          sx={{
            background: "#AFAEAE",
            minWidth: "16px",
            maxWidth: "16px",
            height: "16px",
            color: "white",
            fontSize: "14px",
            fontWeight: 700,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#000000",
              color: "#ffffff",
            },
          }}
        >
          +
        </Button>
      </Box>
      {dayEvents.map((event) => (
        <PlannerItem
          key={event.id}
          event={event}
          weekIndex={weekIndex}
          dayIndex={dayIndex}
          onMoveEvent={onMoveEvent}
          handleReloadTrigger={handleReloadTrigger}
        />
      ))}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Workout")}>
          Workout
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Habit")}>Habit</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Check-in")}>
          Check-in
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Lesson")}>
          Lesson
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Message")}>
          Message
        </MenuItem>
      </Menu>
      {workoutModalOpen && selectedWorkout === "Workout" && (
        <WorkoutModal
          open={workoutModalOpen}
          handleClose={() => setWorkoutModalOpen(false)}
        />
      )}
    </div>
  );
};

export default WeekPlanner;
