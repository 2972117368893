import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import RocketIcon from "@mui/icons-material/Rocket";
import BuildIcon from "@mui/icons-material/Build";
import ScaleIcon from "@mui/icons-material/Scale";
import settings from '../../appsettings'
import axios from "axios"
import { useAppContext } from "../../libs/contextLib";
import AlreadySubscribedButton from "./AlreadySubscribedButton";

const ChooseAPlan = ({ nextStep }) => {
    const { apiUrl, coachId } = useAppContext()
    const [stripeUrl, setStripeUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null);

    const setPlan = async (plan, planDetails) => {
        setIsLoading(true)
        setSelectedPlan(planDetails);
        // TODO:, some sort of loading screen here

        try {
            let resp = await axios.post(`${apiUrl}/api/SetupNewSubscription/`, {
                PriceId: plan,
                CoachId: coachId
            });
            if (resp.status === 200) {
                console.log(resp.data)
                setStripeUrl(resp.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }

    if (stripeUrl.length > 10) {
        return (
            <>
                <Typography variant="h3"> Selected Plan</Typography>
                <br />
                <Card sx={{ padding: 2 }}>
                    <CardContent>
                        <Typography variant="h4">{selectedPlan.name}</Typography><br />
                        {selectedPlan.benefits.map((benefit, index) => (
                            <Typography key={index} variant="body1">
                                <CheckIcon /> {benefit}
                            </Typography>
                        ))}
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                                background:
                                    "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                            }}
                            href={stripeUrl}
                        >
                            Pay With Stripe
                        </Button>

                        <br />

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                                background:
                                    "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                            }}
                            onClick={() => { setStripeUrl('') }}
                        >
                            Change Plan
                        </Button>
                    </CardActions>
                </Card>
            </>
        )
    }

    return (
        <>
            <AlreadySubscribedButton />
            <form onSubmit={nextStep}>
                <Typography variant="h6">Choose a Plan</Typography>
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        gap: 10,
                        flexWrap: "wrap",
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            minHeight: "400px",
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <CardContent>
                            <RocketIcon />
                            <Typography variant="h3">Launch</Typography>
                            <Typography variant="body1">
                                <CheckIcon /> Up to 50 clients
                            </Typography>
                            <Typography variant="body1">
                                <CheckIcon /> Stridist branded apps
                            </Typography>
                            <Typography variant="body1">
                                <CheckIcon /> $95 per month
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                style={{
                                    background:
                                        "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                                }}
                                onClick={() => {
                                    if (!isLoading) {
                                        setPlan(settings.stripeCheckoutLinks.upto50, {
                                            name: 'Launch',
                                            benefits: ['Up to 50 clients', 'Stridist branded apps', '$95 per month']
                                        });
                                    }
                                }}
                            >
                                {isLoading ? 'Processing...' : 'Choose'}
                            </Button>
                        </CardActions>
                    </Card>
                    <Card
                        variant="outlined"
                        sx={{
                            minHeight: "400px",
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <CardContent>
                            <BuildIcon />
                            <Typography variant="h3">Build</Typography>
                            <Typography variant="body1">
                                <CheckIcon /> Up to 100 clients
                            </Typography>
                            <Typography variant="body1">
                                <CheckIcon /> Custom branded app
                            </Typography>
                            <Typography variant="body1">
                                <CheckIcon /> $135 per month
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                style={{
                                    background:
                                        "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                                }}
                                onClick={() => {
                                    if (!isLoading) {
                                        setPlan(settings.stripeCheckoutLinks.upto100, {
                                            name: 'Build',
                                            benefits: ['Up to 100 clients', 'Custom branded apps', '$135 per month']
                                        });
                                    }
                                }}
                            >
                                {isLoading ? 'Processing...' : 'Choose'}</Button>
                        </CardActions>
                    </Card>
                    <Card
                        variant="outlined"
                        sx={{
                            minHeight: "400px",
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <CardContent>
                            <ScaleIcon />
                            <Typography variant="h3">Scale</Typography>
                            <Typography variant="body1">
                                <CheckIcon /> Unlimited clients
                            </Typography>
                            <Typography variant="body1">
                                <CheckIcon /> Custom branded app
                            </Typography>
                            <Typography variant="body1">
                                <CheckIcon /> $225 per month
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                style={{
                                    background:
                                        "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                                }}
                                onClick={() => {
                                    if (!isLoading) {
                                        setPlan(settings.stripeCheckoutLinks.uptoUnlim, {
                                            name: 'Scale',
                                            benefits: ['Unlimited clients', 'Custom branded apps', '$225 per month']
                                        });
                                    }
                                }}
                            >
                                {isLoading ? 'Processing...' : 'Choose'}
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </form>
        </>
    )
}

export default ChooseAPlan