import React, { useState } from 'react';
import axios from "axios";
import { useAppContext } from "../../libs/contextLib";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import ProfileSettings from '../../components/settings/ProfileSettings';
import ClientPortal from '../../components/settings/ClientPortal';
import PlanSettings from '../../components/settings/PlanSettings';
import Loader from "../../components/loader/loader.js";


const getUserSettings = async (apiUrl, coachId) => {
  let resp = await axios.get(`${apiUrl}/api/getUserSettings/${coachId}`)
  return resp
}

const SettingsPage = () => {
  const { apiUrl, coachId } = useAppContext()
  const [tab, setTab] = useState('profile');
  const [userSettings, setUserSettings] = useState()


  React.useEffect(() => {
    async function getData() {
      let resp = await getUserSettings(apiUrl, coachId)
      console.log(resp.data);
      setUserSettings(resp.data);
    }
    if (apiUrl !== null && coachId > 0) {
      getData();
    }
  }, [apiUrl, coachId]);

  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      setTab(newTab);
    }
  };

  if (!userSettings) {
    return <Loader />
  }

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px 20px 0px 20px',
          borderBottom: '2px solid #EAE9E9',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" component="div" align="left">
            Settings          </Typography>
        </Box>
        <Box sx={{ marginTop: 1, marginBottom: 0 }}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={handleTabChange}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <ToggleButton value="profile" style={{ flex: 1 }}>
              Profile
            </ToggleButton>
            <ToggleButton value="portal" style={{ flex: 1 }}>
              Client Portal
            </ToggleButton>
            <ToggleButton value="plan" style={{ flex: 1 }}>
              Plan
            </ToggleButton>

          </ToggleButtonGroup>
        </Box>
      </div>
      <div style={{ padding: '20px' }}>
        {tab === 'profile' && <ProfileSettings title="Profile" userSettings={userSettings} apiUrl={apiUrl} />}
        {tab === 'portal' && <ClientPortal title="Client Portal" userSettings={userSettings} apiUrl={apiUrl} coachId={coachId} />}
        {tab === 'plan' && <PlanSettings title="Plan" />}

      </div>
    </>
  );
};

export default SettingsPage;
