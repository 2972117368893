const settings = {
  useDevUrl: false,
  apiUrl: () => {
    return settings.useDevUrl
      ? "https://localhost:7272"
      : "https://ccsmart.co.uk/getStridistApiUrl.php";
  },
  stripeCheckoutLinks:{
    upto50: "price_1MGmPxKMXGjdxyrw0ud5mAdk",
    upto100: "price_1MGmPxKMXGjdxyrwoJHQJcI9",
    uptoUnlim: "price_1MGmPuKMXGjdxyrwbIcDqBzC"
  }
};

export default settings;
