import React, { useMemo, useState, useCallback } from "react";
import { Editable, withReact, Slate, ReactEditor } from "slate-react";
import { createEditor, Editor, Transforms, Path } from "slate";
import { IconButton, Menu, MenuItem, Typography,  Card,
  CardContent, Select, Box, Button, TextField} from "@mui/material";
import {
  MoreVert,
  LooksOne,
  Audiotrack,
  TextFields,
  LooksTwo,
  Checklist,
  FormatListNumberedRtl,
  FormatListNumbered,
  FormatListBulleted,
  AttachFile,
  OndemandVideo,
  Image,
  Abc,
  Tab,
  ArrowCircleDown,
  HorizontalRule,
  FitnessCenter,
  TableChart,
  Language,
  Explore,
  ThumbsUpDown,
  Subject,
  ShortText,
  Scale,
  AddAPhoto,
  LinearScale,
  UploadFile,
  Tag,
  RadioButtonChecked,
  CheckBox,
  CalendarMonth,
  EmojiEmotions,
  Star,
  Restaurant,
  DoneAll,
  School,
  MailOutline
} from "@mui/icons-material";
import { Text, HeadingOne, HeadingTwo, ChecklistNode, NumberedList, ChecklistItem, ListItem, BulletedList, FileAttachment, Video, Audio, ImageNode, Callout, TabbedEditor, AccordionEditor } from './editornodes';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';

const options = [
  { label: "Basic Content", isTitle: true },
  {
    value: "TEXT",
    label: "Text",
    icon: TextFields,
    description: "Add Some Normal Text",
  },
  {
    value: "H1",
    label: "Heading 1",
    icon: LooksOne,
    description: "Use for large section headings",
  },
  {
    value: "H2",
    label: "Heading 2",
    icon: LooksTwo,
    description: "Use for medium section headings",
  },
  {
    value: "Checklist",
    label: "Checklist",
    icon: Checklist,
    description: "Add a checklist",
  },
  {
    value: "NumberedList",
    label: "Numbered List",
    icon: FormatListNumbered,
    description: "Create a list using numbers",
  },
  {
    value: "BulletedList",
    label: "Bulleted List",
    icon: FormatListBulleted,
    description: "Create a list using bullet points",
  },
  {
    value: "FileAttachment",
    label: "File Attachment",
    icon: AttachFile,
    description: "Attach a file for download",
  },
  {
    value: "Video",
    label: "Video",
    icon: OndemandVideo,
    description: "Embed video (YouTube, Vimeo, etc...)",
  },
  {
    value: "Audio",
    label: "Audio",
    icon: Audiotrack,
    description: "Embed audio (Soundcloud, Spotify, etc...)",
  },
  {
    value: "Image",
    label: "Image",
    icon: Image,
    description: "Upload or embed images",
  },
  {
    value: "Callout",
    label: "Callout",
    icon: Abc,
    description: "Make a message stand out",
  },
  {
    value: "Tabs",
    label: "Tabs",
    icon: Tab,
    description: "Separate content into tabs",
  },
  {
    value: "Accordion",
    label: "Accordion",
    icon: ArrowCircleDown,
    description: "Easily show and hide content",
  },
  {
    value: "Divider",
    label: "Divider",
    icon: HorizontalRule,
    description: "Visually separate content",
  },
  {
    value: "ExerciseSection",
    label: "Exercise Section",
    icon: FitnessCenter,
    description: "Add exercises and supersets",
  },
  {
    value: "Table",
    label: "Table",
    icon: TableChart,
    description: "Add a simple table",
  },
  {
    value: "WebBookmark",
    label: "Web Bookmark",
    icon: Language,
    description: "Add a link with a rich preview",
  },
  {
    value: "Embed",
    label: "Embed",
    icon: Explore,
    description: "Add an external website",
  },

  { label: "Check-in questions", isTitle: true },
  {
    value: "YesNo",
    label: "Yes/No",
    icon: ThumbsUpDown,
    description: "Offer two options to choose from",
  },
  {
    value: "LongTextAnswer",
    label: "Long text answer",
    icon: Subject,
    description: "Ask an open ended question",
  },
  {
    value: "ShortTextAnswer",
    label: "Short text answer",
    icon: ShortText,
    description: "Ask an open ended question",
  },
  {
    value: "Measurement",
    label: "Measurement",
    icon: Scale,
    description: "Collect body weight or other body stats",
  },
  {
    value: "ProgressPhotos",
    label: "Progress Photos",
    icon: AddAPhoto,
    description: "Ask for progress pictures",
  },
  {
    value: "RatingScale",
    label: "Rating Scale",
    icon: LinearScale,
    description: "Ask for a numerical rating",
  },
  {
    value: "FileUpload",
    label: "File Upload",
    icon: UploadFile,
    description: "Ask for a file to be uploaded",
  },
  {
    value: "Number",
    label: "Number",
    icon: Tag,
    description: "Ask for a numeric answer",
  },
  {
    value: "MultipleChoice",
    label: "Multiple Choice",
    icon: RadioButtonChecked,
    description: "Provide a set of options to choose from",
  },
  {
    value: "Checkboxes",
    label: "Checkboxes",
    icon: CheckBox,
    description: "Provide a set of options to choose more than one option from",
  },
  {
    value: "Date",
    label: "Date",
    icon: CalendarMonth,
    description: "Ask for a date answer",
  },
  {
    value: "Emoji",
    label: "Emoji",
    icon: EmojiEmotions,
    description: "Ask for a mood rating",
  },
  {
    value: "StarRating",
    label: "Star Rating",
    icon: Star,
    description: "Ask for a star rating",
  },
  {
    value: "NutritionTarget",
    label: "Nutrition Target",
    icon: Restaurant,
    description: "Add a nutrition target",
  },
];

const DEFAULT_VALUE = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];

const Container = styled.div`
  padding: 16px;
`;

const SlashCommandMenu = ({ anchorEl, onClose, onOptionClicked }) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {options.map((option, index) =>
        option.isTitle ? (
          <MenuItem disabled key={index}>
            <Typography variant="h6">{option.label}</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            key={option.value}
            onClick={() => {
              onOptionClicked(option);
              onClose();
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {option.icon && (
                <option.icon style={{ marginRight: "8px", color: "grey" }} />
              )}
              <div>
                <div>{option.label}</div>
                {option.description && (
                  <Typography variant="body2" color="textSecondary">
                    {option.description}
                  </Typography>
                )}
              </div>
            </div>
          </MenuItem>
        )
      )}
    </Menu>
  );
};

const WysiwygEditor = ({ type }) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState(DEFAULT_VALUE);
  const [slashCommandAnchor, setSlashCommandAnchor] = useState(null);
  const [savedSelection, setSavedSelection] = useState(null);
  const [slashCommandEntered, setSlashCommandEntered] = useState(false);
  const [placeholder, setPlaceholder] = useState("Add your content here...");
  const location = useLocation();
  const showHabitCard = type === "Habit";
  const showCheckinCard = type === "Check-in";
  const showLessonCard = type === "Lesson";
  const showMessageCard = type === "Message";
  const [showCheckinSettings, setShowCheckinSettings] = useState(false);
  const [showHabitSettings, setShowHabitSettings] = useState(false);
  const [showHabitPrompt, setShowHabitPrompt] = useState(false);
  const [showLessonSettings, setShowLessonSettings] = useState(false);
  const [showMessageSettings, setShowMessageSettings] = useState(false);
  const [startingWeek, setStartingWeek] = useState(1);
  const [days, setDays] = useState([1]);
  const [frequency, setFrequency] = useState('every week');
  const [lastKey, setLastKey] = useState(null);


  const openSlashCommand = () => {
    setSlashCommandEntered(true);
    setSavedSelection(editor.selection);
    setSlashCommandAnchor(document.activeElement);
  };

  const closeSlashCommand = () => {
    setSlashCommandAnchor(null);
  };


  

  const handleSlashCommandOptionClicked = (option) => {
    ReactEditor.focus(editor);
    let insertionRange = savedSelection;
    if (!savedSelection) {
      // If there is no selection, place the insertion point at the end of the document.
      const end = Editor.end(editor, []);
      insertionRange = { anchor: end, focus: end };
    }
    Transforms.select(editor, insertionRange);

    // Check if the current character is '/', if so replace it
    const text = Editor.string(editor, editor.selection);
    if (text === "/" && slashCommandEntered) {
      Transforms.delete(editor, { unit: "character" });
    }
  // Check if the option is a divider
  if (option.value === "Divider") {
    // Create a new divider block
    const divider = { type: 'divider', children: [{ text: '' }] };
    // Insert the divider block
    Transforms.insertNodes(editor, divider);
    const text = { type: 'paragraph', children: [{ text: '' }] };
    // Insert the text block after the divider to avoid dead end
    Transforms.insertNodes(editor, text);
  } else if (option.value === "TEXT") {
    // Create a new text block
    const text = { type: 'paragraph', children: [{ text: '' }] };
    // Insert the text block
    Transforms.insertNodes(editor, text);
  } else if (option.value === "H1") {
    // Create a new heading 1 block
    const headingOne = { type: 'heading-one', children: [{ text: '' }] };
    // Insert the heading 1 block
    Transforms.insertNodes(editor, headingOne);
  } else if (option.value === "H2") {
    // Create a new heading 2 block
    const headingTwo = { type: 'heading-two', children: [{ text: '' }] };
    // Insert the heading 2 block
    Transforms.insertNodes(editor, headingTwo);
  } 
  else if (option.value === "Checklist") {
    // Create a new checklist block with a single empty item
    const checklistNode = { type: 'checklist', children: [{ type: 'checklist-item', children: [{ text: '' }] }] };
    // Insert the checklist block
    Transforms.insertNodes(editor, checklistNode);
  }
  else if (option.value === "NumberedList") {
    // Create a new numbered list block with a single empty item
    const numberedList = { type: 'numberedlist', children: [{ type: 'list-item', children: [{ text: '' }] }] };
    // Insert the numbered list block
    Transforms.insertNodes(editor, numberedList);
  }
  else if (option.value === "BulletedList") {
    // Create a new bulleted list block with a single empty item
    const bulletedList = { type: 'bulletedlist', children: [{ type: 'list-item', children: [{ text: '' }] }] };
    // Insert the bulleted list block
    Transforms.insertNodes(editor, bulletedList);
  }

    // Check if the option is a file attachment
    else if (option.value === "FileAttachment") {
      // Create a new file attachment block
      const fileAttachment = { type: 'file-attachment', children: [{ text: '' }] };
      // Insert the file attachment block
      Transforms.insertNodes(editor, fileAttachment);
    } 

        // Check if the option is video
        else if (option.value === "Video") {
          // Create a new file attachment block
          const video = { type: 'video', children: [{ text: '' }] };
          // Insert the file attachment block
          Transforms.insertNodes(editor, video);
        } 
                // Check if the option is audio
                else if (option.value === "Audio") {
                  // Create a new file attachment block
                  const audio = { type: 'audio', children: [{ text: '' }] };
                  // Insert the file attachment block
                  Transforms.insertNodes(editor, audio);
                } 
      // Check if the option is image
      else if (option.value === "Image") {
        // Create a new image block
        const image = { type: 'image', children: [{ text: '' }] };
        // Insert the image block
        Transforms.insertNodes(editor, image);
      } 

            // Check if the option is image
            else if (option.value === "Callout") {
              // Create a new image block
              const callout = { type: 'callout', children: [{ text: '' }] };
              // Insert the image block
              Transforms.insertNodes(editor, callout);
            } 

            else if (option.value === "Tabs") {
              const tabs = { type: 'tabs', children: [{ text: '' }] };
              Transforms.insertNodes(editor, tabs);
            }

            else if (option.value === "Accordion") {
              const accordion = { type: 'accordion', children: [{ text: '' }], panels: [{ content: 'Panel 1' }, { content: 'Panel 2' }] };
              Transforms.insertNodes(editor, accordion);
            }
  else {
    // Insert the option value as a text node
    Transforms.insertText(editor, option.value, { at: insertionRange });
  }

    setSlashCommandEntered(false);
    closeSlashCommand();
  };

const handleKeyDown = (event) => {
  if (event.key === "/") {
    openSlashCommand();
  } else if (event.key === 'Enter') {
    const { selection } = editor;
    if (selection && JSON.stringify(selection.anchor) === JSON.stringify(selection.focus) && lastKey === 'Enter') {
      event.preventDefault();
      // Check if the current node is not at the root level
      if (selection.anchor.path.length > 1) {
        // Change the type of the checklist item to a paragraph
        Transforms.setNodes(editor, { type: 'paragraph' }, { match: n => n.type === 'checklist-item' });
        // Lift the checklist item out of the checklist
        Transforms.liftNodes(editor, { match: n => n.type === 'paragraph' });
        // Lift the checklist out of its parent
        Transforms.liftNodes(editor, { match: n => n.type === 'checklist' });
      }
      // Insert a new paragraph at the new location
      const paragraph = { type: 'paragraph', children: [{ text: '' }] };
      Transforms.insertNodes(editor, paragraph);
    }
  }
  setLastKey(event.key);
};

  const handleFocus = () => {
    setPlaceholder("Type / for commands");
  };

  const handleBlur = () => {
    setPlaceholder("Add your content here...");
    setSlashCommandEntered(false);
  };

  const [Title, setTitle] = useState("");
  const isVoid = useCallback((element) => {
    return element.type === 'divider';
  }, []);

  const renderElement = useCallback(props => {
    switch (props.element.type) {
      case 'heading-one':
        return <HeadingOne {...props} />
      case 'heading-two':
        return <HeadingTwo {...props} />
      case 'paragraph':
        return <Text {...props} />
        case 'checklist':
      return <ChecklistNode {...props} />
    case 'checklist-item':
      return <ChecklistItem {...props} />
    case 'numberedlist':
      return <NumberedList {...props} />
      case 'bulletedlist':
        return <BulletedList {...props} />
    case 'list-item':
      return <ListItem {...props} />
      case 'file-attachment':
        return <FileAttachment {...props} />
        case 'video':
          return <Video {...props} />
          case 'audio':
            return <Audio {...props} />
            case 'image':
              return <ImageNode {...props} />
              case 'callout':
                return <Callout {...props} />
                case 'tabs':
                  return <TabbedEditor {...props} />
                  case 'accordion':
                    return <AccordionEditor {...props} />
                    case 'divider':
                      return (
                        <div {...props.attributes} contentEditable={false}>
                          <hr />
                          <span style={{ display: 'none' }}>{props.children}</span>
                        </div>
                      );
                    default:
        return <Text {...props} />
    }
  }, [])
  
  return (
    <Container>
       {showCheckinCard && (
        <Box sx={{padding: 2}}>
        <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
<DoneAll sx={{fontSize: "large", color: "grey.500"}} />
<Typography variant="body2" sx={{color: "grey.500", margin: 0}}>Check-In</Typography>
</Box>
<TextField
            value={Title}
            onChange={(e) => setTitle(e.target.value)}
            variant="standard"
            placeholder="Give Check-In a Title"
            fullWidth
            InputProps={{ disableUnderline: true, style:{fontSize: '2.4em', fontWeight: '700'} }}
          />
  
          <Card display="flex" alignItems="center" gap="10px" justifyContent="space-between" sx={{ padding: 2, marginTop: "20px", width: '100%'}}>
          <Box display="flex" flexDirection="row" gap="10px" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
            <Typography variant="h6" component="div">
            Schedule
          </Typography>
            <Typography variant="body2" component="div">
             Week {startingWeek} - Day(s) {days.join(', ')} - {frequency}
            </Typography>
            </Box>
          <Button onClick={() => setShowCheckinSettings(!showCheckinSettings)} sx={{borderRadius: 1}}>
           {showCheckinSettings ? "Save" : "Edit"}
          </Button>
          </Box>
         
      
        {showCheckinSettings && (
   
            <Box display="flex" flexDirection="row"  gap="10px" sx={{padding: '10px', marginTop: "20px", borderRadius: '5px', width: '100%'}}>
                <Box sx={{flexGrow: 1, width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Starting Week
                  </Typography>
                  <Select
                    value={startingWeek}
                    onChange={(e) => setStartingWeek(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Week 1</MenuItem>
                    <MenuItem value={2}>Week 2</MenuItem>
                    <MenuItem value={3}>Week 3</MenuItem>
                    <MenuItem value={4}>Week 4</MenuItem>
                    <MenuItem value={5}>Week 5</MenuItem>
                    <MenuItem value={6}>Week 6</MenuItem>
                    <MenuItem value={7}>Week 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Days
                  </Typography>
                  <Select
                    multiple
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Day 1</MenuItem>
                    <MenuItem value={2}>Day 2</MenuItem>
                    <MenuItem value={3}>Day 3</MenuItem>
                    <MenuItem value={4}>Day 4</MenuItem>
                    <MenuItem value={5}>Day 5</MenuItem>
                    <MenuItem value={6}>Day 6</MenuItem>
                    <MenuItem value={7}>Day 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Frequency
                  </Typography>
                  <Select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={"every week"}>Every Week</MenuItem>
                    <MenuItem value={"every 2 weeks"}>Every 2 Weeks</MenuItem>
                    <MenuItem value={"every 4 weeks"}>Every 4 Weeks</MenuItem>
                  </Select>
                </Box>
              </Box>
      
        )}
   
       </Card>
       </Box>
        )}
        

{showHabitCard && (
       <Box sx={{padding: 2}}>
          <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
  <FitnessCenter sx={{fontSize: "large", color: "grey.500"}} />
  <Typography variant="body2" sx={{color: "grey.500", margin: 0}}>Habit</Typography>
</Box>
<TextField
              value={Title}
              onChange={(e) => setTitle(e.target.value)}
              variant="standard"
              placeholder="Give Habit a Title"
              fullWidth
              InputProps={{ disableUnderline: true, style:{fontSize: '2.4em', fontWeight: '700'} }}
            />
    
            <Card display="flex" alignItems="center" gap="10px" justifyContent="space-between" sx={{ padding: 2, marginTop: "20px", width: '100%'}}>
            <Box display="flex" flexDirection="row" gap="10px" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Typography variant="h6" component="div">
              Schedule
            </Typography>
              <Typography variant="body2" component="div">
               Week {startingWeek} - Day(s) {days.join(', ')} - {frequency}
              </Typography>
              </Box>
            <Button onClick={() => setShowHabitSettings(!showHabitSettings)} sx={{borderRadius: 1}}>
             {showHabitSettings ? "Save" : "Edit"}
            </Button>
            </Box>


          
         
      
        {showHabitSettings && (

            <Box display="flex" flexDirection="row"  gap="10px" sx={{ padding: '10px', marginTop: "20px", borderRadius: '5px', width: '100%'}}>
                <Box sx={{flexGrow: 1, width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Starting Week
                  </Typography>
                  <Select
                    value={startingWeek}
                    onChange={(e) => setStartingWeek(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Week 1</MenuItem>
                    <MenuItem value={2}>Week 2</MenuItem>
                    <MenuItem value={3}>Week 3</MenuItem>
                    <MenuItem value={4}>Week 4</MenuItem>
                    <MenuItem value={5}>Week 5</MenuItem>
                    <MenuItem value={6}>Week 6</MenuItem>
                    <MenuItem value={7}>Week 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Days
                  </Typography>
                  <Select
                    multiple
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Day 1</MenuItem>
                    <MenuItem value={2}>Day 2</MenuItem>
                    <MenuItem value={3}>Day 3</MenuItem>
                    <MenuItem value={4}>Day 4</MenuItem>
                    <MenuItem value={5}>Day 5</MenuItem>
                    <MenuItem value={6}>Day 6</MenuItem>
                    <MenuItem value={7}>Day 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Frequency
                  </Typography>
                  <Select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={"every week"}>Every Week</MenuItem>
                    <MenuItem value={"every 2 weeks"}>Every 2 Weeks</MenuItem>
                    <MenuItem value={"every 4 weeks"}>Every 4 Weeks</MenuItem>
                  </Select>
                </Box>
              </Box>
    
        )}            </Card>

<Card display="flex" alignItems="center" gap="10px" justifyContent="space-between" sx={{ padding: 2, marginTop: "20px", width: '100%'}}>
            <Box display="flex" flexDirection="row" gap="10px" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Typography variant="h6" component="div">
              Prompt
            </Typography>
              <Typography variant="body2" component="div">
          Will clients be shown a custom prompt?
              </Typography>
              </Box>
            <Button onClick={() => setShowHabitPrompt(!showHabitPrompt)} sx={{borderRadius: 1}}>
             {showHabitPrompt ? "Save" : "Edit"}
            </Button>
            </Box>


          
         
      
        {showHabitPrompt && (

            <Box display="flex" flexDirection="column"  gap="10px" sx={{ padding: '10px', marginTop: "20px", borderRadius: '5px', width: '100%'}}>
               <Typography variant="body2">Motivate your clients with a custom message.</Typography>
               <TextField placeholder="Did you follow your habbit today?"></TextField>
              </Box>
    
        )}            </Card>
        
         
          </Box>
        )}

{showLessonCard && (
       <Box sx={{padding: 2}}>
       <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
<School sx={{fontSize: "large", color: "grey.500"}} />
<Typography variant="body2" sx={{color: "grey.500", margin: 0}}>Lesson</Typography>
</Box>
<TextField
           value={Title}
           onChange={(e) => setTitle(e.target.value)}
           variant="standard"
           placeholder="Give Lesson a Title"
           fullWidth
           InputProps={{ disableUnderline: true, style:{fontSize: '2.4em', fontWeight: '700'} }}
         />
 
         <Card display="flex" alignItems="center" gap="10px" justifyContent="space-between" sx={{ padding: 2, marginTop: "20px", width: '100%'}}>
         <Box display="flex" flexDirection="row" gap="10px" justifyContent="space-between" alignItems="center">
           <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
           <Typography variant="h6" component="div">
           Schedule
         </Typography>
           <Typography variant="body2" component="div">
            Week {startingWeek} - Day(s) {days.join(', ')} - {frequency}
           </Typography>
           </Box>
         <Button onClick={() => setShowLessonSettings(!showLessonSettings)} sx={{borderRadius: 1}}>
          {showLessonSettings ? "Save" : "Edit"}
         </Button>
         </Box>
         
      
        {showLessonSettings && (
 
            <Box display="flex" flexDirection="row"  gap="10px" sx={{ padding: '10px', marginTop: "20px", borderRadius: '5px', width: '100%'}}>
                <Box sx={{flexGrow: 1, width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Starting Week
                  </Typography>
                  <Select
                    value={startingWeek}
                    onChange={(e) => setStartingWeek(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Week 1</MenuItem>
                    <MenuItem value={2}>Week 2</MenuItem>
                    <MenuItem value={3}>Week 3</MenuItem>
                    <MenuItem value={4}>Week 4</MenuItem>
                    <MenuItem value={5}>Week 5</MenuItem>
                    <MenuItem value={6}>Week 6</MenuItem>
                    <MenuItem value={7}>Week 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Days
                  </Typography>
                  <Select
                    multiple
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Day 1</MenuItem>
                    <MenuItem value={2}>Day 2</MenuItem>
                    <MenuItem value={3}>Day 3</MenuItem>
                    <MenuItem value={4}>Day 4</MenuItem>
                    <MenuItem value={5}>Day 5</MenuItem>
                    <MenuItem value={6}>Day 6</MenuItem>
                    <MenuItem value={7}>Day 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Frequency
                  </Typography>
                  <Select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={"every week"}>Every Week</MenuItem>
                    <MenuItem value={"every 2 weeks"}>Every 2 Weeks</MenuItem>
                    <MenuItem value={"every 4 weeks"}>Every 4 Weeks</MenuItem>
                  </Select>
                </Box>
              </Box>

        )}
</Card>
</Box>
        )}

{showMessageCard && (
       <Box sx={{padding: 2}}>
       <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
<MailOutline sx={{fontSize: "large", color: "grey.500"}} />
<Typography variant="body2" sx={{color: "grey.500", margin: 0}}>Message</Typography>
</Box>
<TextField
           value={Title}
           onChange={(e) => setTitle(e.target.value)}
           variant="standard"
           placeholder="Give Your Message a Title"
           fullWidth
           InputProps={{ disableUnderline: true, style:{fontSize: '2.4em', fontWeight: '700'} }}
         />
 
         <Card display="flex" alignItems="center" gap="10px" justifyContent="space-between" sx={{ padding: 2, marginTop: "20px", width: '100%'}}>
         <Box display="flex" flexDirection="row" gap="10px" justifyContent="space-between" alignItems="center">
           <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
           <Typography variant="h6" component="div">
           Schedule
         </Typography>
           <Typography variant="body2" component="div">
            Week {startingWeek} - Day(s) {days.join(', ')} - {frequency}
           </Typography>
           </Box>
         <Button onClick={() => setShowMessageSettings(!showMessageSettings)} sx={{borderRadius: 1}}>
          {showMessageSettings ? "Save" : "Edit"}
         </Button>
         </Box>
         
      
        {showMessageSettings && (

            <Box display="flex" flexDirection="row"  gap="10px" sx={{ padding: '10px', marginTop: "20px", borderRadius: '5px', width: '100%'}}>
                <Box sx={{flexGrow: 1, width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Starting Week
                  </Typography>
                  <Select
                    value={startingWeek}
                    onChange={(e) => setStartingWeek(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Week 1</MenuItem>
                    <MenuItem value={2}>Week 2</MenuItem>
                    <MenuItem value={3}>Week 3</MenuItem>
                    <MenuItem value={4}>Week 4</MenuItem>
                    <MenuItem value={5}>Week 5</MenuItem>
                    <MenuItem value={6}>Week 6</MenuItem>
                    <MenuItem value={7}>Week 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Days
                  </Typography>
                  <Select
                    multiple
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={1}>Day 1</MenuItem>
                    <MenuItem value={2}>Day 2</MenuItem>
                    <MenuItem value={3}>Day 3</MenuItem>
                    <MenuItem value={4}>Day 4</MenuItem>
                    <MenuItem value={5}>Day 5</MenuItem>
                    <MenuItem value={6}>Day 6</MenuItem>
                    <MenuItem value={7}>Day 7</MenuItem>
                  </Select>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography variant="body1" component="div">
                    Frequency
                  </Typography>
                  <Select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value={"every week"}>Every Week</MenuItem>
                    <MenuItem value={"every 2 weeks"}>Every 2 Weeks</MenuItem>
                    <MenuItem value={"every 4 weeks"}>Every 4 Weeks</MenuItem>
                  </Select>
                </Box>
              </Box>
  
        )}
 </Card>
 </Box>
        )}
      
      <IconButton onClick={openSlashCommand}>
        <MoreVert />
      </IconButton>
      <SlashCommandMenu
        anchorEl={slashCommandAnchor}
        onClose={closeSlashCommand}
        onOptionClicked={handleSlashCommandOptionClicked}
      />
      <Slate editor={editor} initialValue={value} onChange={setValue} isVoid={isVoid} plugins={escape}>
      <Editable
  renderElement={renderElement}
  placeholder={placeholder}
  onFocus={handleFocus}
  onBlur={handleBlur}
  onKeyDown={handleKeyDown}
/>
      </Slate>
    </Container>
  );
};

export default WysiwygEditor;


