import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useAppContext } from "../../libs/contextLib";
import axios from "axios";

const YourBusiness = ({ nextStep }) => {

    const { dbUser, apiUrl, coachId, setCoachId } = useAppContext()

    const [formData, setFormData] = useState({
        name: "",
        businessName: "",
        country: "",
        telephone: "",
    })
    const [error, setError] = useState('')

    useEffect(()=>{
        if(coachId > 0 && nextStep){
            nextStep()
        }
    }, [coachId])

    const handleFormChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (formData.name.length < 3) {
            setError("Please enter a name")
        }
        else if (formData.businessName.length < 3) {
            setError("Please enter a Business Name")
        }
        else if (formData.country.length < 3) {
            setError("Please enter a country or location")
        }
        else if (formData.telephone.length < 3) {
            setError("Please enter a telephone")
        }
        else {
            // good to go
            let resp = await axios.post(`${apiUrl}/api/SetupAsCoach/`, {
                ...formData
            });
            console.log(resp.data);
            if (resp.status === 200) {
                setCoachId(resp.data)
            }
        }
    }


    return (
        <>
            <Typography variant="h3">Your Business</Typography>
            <br />
            <form
                onSubmit={onSubmit}
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >

                <TextField
                    label="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleFormChange}
                    style={{ width: "100%" }}
                />
                <TextField
                    label="Business Name"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleFormChange}
                />
                <TextField
                    label="Country or Location"
                    name="country"
                    value={formData.country}
                    onChange={handleFormChange}
                />
                <TextField
                    label="Telephone"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleFormChange}
                />
                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        background:
                            "linear-gradient(98.28deg, #3f0a06 -5.34%, rgba(209, 45, 31, 0.96) 20.96%, rgba(222, 74, 136, 0.84) 62.2%, #94d1db 93.65%)",
                    }}
                >
                    Next
                </Button>
                {error}
            </form>
        </>
    )
}

export default YourBusiness