import React from "react";
import { useAppContext } from "../../libs/contextLib";
import { Add } from "@mui/icons-material"
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextareaAutosize, TextField, Typography } from "@mui/material"
import axios from "axios";

const addNewGroup = async (apiUrl, groupName, groupDesc, coachId) => {
    let resp = await axios.post(`${apiUrl}/api/Group/`, { CoachId: coachId, Name: groupName, Description: groupDesc })
    return resp
}

const AddNewClientModal = ({ handleNewGroupCreated }) => {
    const [groupImage, setGroupImage] = React.useState(null);
    const [groupImagePreview, setGroupImagePreview] = React.useState(null);
    const { apiUrl, coachId } = useAppContext()
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [groupNameInput, setGroupNameInput] = React.useState('');
    const [groupDescInput, setGroupDescInput] = React.useState('');

    const handleAddGroup = async () => {
        // check we have at least 1 email added, and if not, do we have an input?
        if (groupNameInput.length === 0) {
            // do nothing
        }
        else {
            try {
                let resp = await addNewGroup(apiUrl, groupNameInput, groupDescInput, coachId)
                console.log("response", resp)
                if (handleNewGroupCreated) {
                    // hook from parent 
                    handleNewGroupCreated()
                }
                // close the modal
                setOpenAddDialog(false)
                // flush the emails incase user wants to add more 
                setGroupNameInput('')
                setGroupDescInput('')
            } catch (error) {
                console.log("error", error)
                if (error.response.data) {
                    alert(error.response.data)
                }
            }
        }
    }


    return (
        <>

            <IconButton
                sx={{
                    background: "#EB5757",
                    fontSize: "12px",
                    color: "white",
                    borderRadius: 2,
                }}
                onClick={() => { setOpenAddDialog(true) }}
            >
                <Add />
                Add A Group
            </IconButton>


            {/* Dialog shows/hides based on trigger button above */}
            <Dialog
                open={openAddDialog}
                onClose={() => { setOpenAddDialog(false) }}
                PaperProps={{
                    style: {
                        width: "50vw",
                        height: "50vh",
                        overflowY: "hidden"
                    },
                }}
            >
                <DialogTitle>Create New Group</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="group"
                        label="Group Name"
                        type="text"
                        fullWidth
                        value={groupNameInput}
                        onChange={(event) => setGroupNameInput(event.target.value)}
                    />

                    <TextareaAutosize
                        minRows={3} // Set the minimum number of rows you want to display
                        maxRows={10} // Set the maximum number of rows you want to display
                        id="group"
                        placeholder="Group Description" // You can use placeholder instead of label
                        fullWidth
                        style={{
                            width: '100%', // Ensure the textarea takes up full width
                            border: '1px solid #ced4da', // Add a border similar to TextField
                            borderRadius: '4px', // Add border radius similar to TextField
                            padding: '8px', // Add padding similar to TextField
                            fontFamily: 'inherit',
                            fontSize: 'inherit'
                          }}
                      
                        value={groupDescInput}
                        onChange={(event) => setGroupDescInput(event.target.value)}
                    />
<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
<input
   accept="image/*"
   id="group-image"
   type="file"
   style={{ display: 'none' }}
    onChange={(event) => {
        setGroupImage(event.target.files[0]);
        const reader = new FileReader();
        reader.onloadend = () => {
            setGroupImagePreview(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    }}
/>
<label htmlFor="group-image">
    <Button variant="contained" color="primary" component="span">
        Upload Cover Image
    </Button>
</label>
{groupImagePreview && <img src={groupImagePreview} alt="Group Preview" style={{ width: '60px', height: '60px', borderRadius: 14, objectFit: 'cover', margin: 2}} />}
</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenAddDialog(false) }}>Cancel</Button>
                    <Button onClick={handleAddGroup}>Create Group</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default AddNewClientModal