import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDrag } from "react-dnd";
import ComponentCardMenu from "../../components/programs/ComponentCardMenu";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const getEventStyles = (event, theme) => {
  switch (event.type) {
    case "HABIT":
      return { borderLeft: "10px solid #57D0EB" };
    case "WORKOUT":
      return { borderLeft: "10px solid #EB5757" };
    case "MESSAGE":
      return { borderLeft: "10px solid #8657EB" };
    case "CHECKIN":
      return { borderLeft: "10px solid #F1CD4D" };
    case "LESSON":
      return { borderLeft: "10px solid #83C462" };
    default:
      return { borderLeft: "10px solid #F5F5F5" };
  }
};

const PlannerItem = ({ event, weekIndex, dayIndex, onMoveEvent, handleReloadTrigger }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "event",
    item: { weekIndex, dayIndex, event },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, width: "100%" }}>
      <StyledPaper
        sx={({ theme }) => ({
          ...getEventStyles(event, theme),
          background: "#ffffff",
          margin: 1,
          borderRadius: "8px",
          width: "95%",
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ alignItems: "start", textAlign: "left", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{event.type}</Typography>

              <ComponentCardMenu
                componentId={event.id}
                reloadTrigger={null}
                handleReloadTrigger={handleReloadTrigger}
              />

            </Box>
            <Box
              sx={{
                background: "#FAFAFA",
                width: "100%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {event.content.split(",").map((item, index) => (
                <Typography variant="caption" key={index}>
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>

        </Box>
      </StyledPaper>
    </div>
  );
};

export default PlannerItem;
