import React from "react";
import axios from "axios";
import { Add, CropLandscapeOutlined } from "@mui/icons-material"
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material"
import { useAppContext } from "../../libs/contextLib";

const inviteNewUser = async (apiUrl, emails, coachId, message) => {
    let resp = await axios.post(`${apiUrl}/api/Invites/`, { CoachId: coachId, Emails: emails, Message: message })
    return resp
}

const AddNewClientModal = ({ handleNewInvitesSent }) => {

    const { apiUrl, coachId } = useAppContext()
    const [openAddClientDialog, setOpenAddClientDialog] = React.useState(false);
    const [emails, setEmails] = React.useState([]);
    const [emailInput, setEmailInput] = React.useState('');
    const [message, setMessage] = React.useState("Hello!\n\nI’d like to invite you to be my client on Stridist. It’s a coaching platform that will help us stay organized, chat with each other, and track your progress.\n\nIt takes less than a minute to join – see you there!");

    const handleInviteUser = async () => {
        // check we have at least 1 email added, and if not, do we have an input?
        if (emails.length === 0) {
            if (emailInput.length > 0) {
                setEmails([...emails, emailInput])
            }
        }
        // we have data ready to send
        else {
            try {
                let resp = await inviteNewUser(apiUrl, emails, coachId, message)
                console.log("response", resp)
                if(handleNewInvitesSent){
                    // hook from parent 
                    handleNewInvitesSent()
                    // close the modal
                    setOpenAddClientDialog(false)
                    // flush the emails incase user wants to add more 
                    setEmails([])
                }
            } catch (error) {
                console.log("error", error)
                if (error.response.data) {
                    alert(error.response.data)
                }
            }
        }
    }

    React.useEffect(() => {
        console.log(emails)
    }, [emails])

    return (
        <>
            <IconButton
                sx={{
                    background: "#EB5757",
                    fontSize: "12px",
                    color: "white",
                    borderRadius: 2,
                }}
                onClick={() => { setOpenAddClientDialog(true) }}
            >
                <Add />
                Add A Client
            </IconButton >

            {/* Dialog shows/hides based on trigger button above */}
            <Dialog
                open={openAddClientDialog}
                onClose={() => { setOpenAddClientDialog(false) }}
                PaperProps={{
                    style: {
                        width: "50vw",
                        height: "50vh",
                        overflowY: "hidden"
                    },
                }}
            >
                <DialogTitle>Add a Client</DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{ marginTop: 1, marginBottom: 1, fontWeight: "bold" }}
                    >
                        {" "}
                        Invite by email{" "}
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={emailInput}
                        onChange={(event) => setEmailInput(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === 'Tab') {
                                event.preventDefault();
                                setEmails([...emails, emailInput]);
                                setEmailInput('');
                            }
                        }}
                    />

                    {emails.map((email, index) => (
                        <Chip
                            key={index}
                            label={email}
                            onDelete={() => setEmails(emails.filter((e, i) => i !== index))}
                        />
                    ))}

                    {emails.length > 0 && (
                        <TextField
                            margin="dense"
                            id="message"
                            label="Message"
                            type="text"
                            fullWidth
                            multiline
                            rows={8}
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                    )}
                    {/* 

CG: We don't like links with us being app-only for clients.  for now drop this feature and force email usage instead

                    <Typography sx={{ marginTop: 1, fontWeight: "bold" }}>
                        {" "}
                        Invite by Link{" "}
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                        Use this link to invite your clients.{" "}
                    </Typography>
                    <TextField
                        margin="dense"
                        id="inviteLink"
                        label="Invitation Link"
                        type="text"
                        fullWidth
                        value={inviteLink}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleCopyLink}>
                                        <ContentCopy />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    /> */}


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenAddClientDialog(false) }}>Cancel</Button>
                    <Button onClick={handleInviteUser}>Invite</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddNewClientModal